import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InvestorData } from "../../templates/Investor";
import { usePut } from "../../../hooks/usePut";
import { NameTableData } from "../../templates/TrusteeBank";

interface DataForApiRequest {
  name: string;
  country: NameTableData;
  type: NameTableData;
  remarks: string;
}

const schema = yup.object({
  name: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(200, "200文字以内で入力してください"),
  country: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  type: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  countries: NameTableData[];
  types: NameTableData[];
  rowInfo: InvestorData;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function InvestorInfoDialog(props: Props) {
  const {
    open,
    setOpen,
    render,
    setRender,
    setResMsg,
    countries,
    types,
    rowInfo,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [name, setName] = useState<string | null>(null);
  const [country, setCountry] = useState<NameTableData | null>(null);
  const [type, setType] = useState<NameTableData | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);

  const resetValue = () => {
    setChecked(false);
    setName(null);
    setCountry(null);
    setType(null);
    setRemarks(null);
  };

  const handleClose = () => {
    reset();
    resetValue();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/investor",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const onSubmit: SubmitHandler<DataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      name: data.name ? data.name : rowInfo.name, // BEでは必須項目として待ち受けているので、変更がない場合は元の値を入れる
      countryId: data.country ? data.country.id : rowInfo.country.id, // BEでは必須項目として待ち受けているので、変更がない場合は元の値を入れる
      typeId: data.type ? data.type.id : rowInfo.type.id, // BEでは必須項目として待ち受けているので、変更がない場合は元の値を入れる
      remarks: data.remarks, // 空文字更新を許容
    });

    await putRequest(body);
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedName = name !== null && name !== rowInfo.name;
  const isChangedCountry = country !== null && country !== rowInfo.country;
  const isChangedType = type !== null && type !== rowInfo.type;
  const isChangedRemarks = remarks !== null && remarks !== rowInfo.remarks;

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedName || isChangedCountry || isChangedType || isChangedRemarks;

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogTitle>詳細情報</DialogTitle>
        <DialogContent>
          <Grid mx={1}>
            <DialogContentText>Investor ID : {rowInfo.id}</DialogContentText>
            <DialogContentText>
              Discretionary Code : {rowInfo.discretionaryCode}
            </DialogContentText>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item mx={1} xs={12}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Name"
                      variant="standard"
                      defaultValue={rowInfo.name}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setName(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
            <Grid
              container
              my={4}
              direction="row"
              justifyContent="space-between"
            >
              <Grid item mx={1} xs={5}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={countries}
                      getOptionLabel={(c) => c.name}
                      defaultValue={rowInfo.country}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setCountry(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Country"
                          variant="standard"
                          error={"country" in errors}
                          helperText={errors.country?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={5}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={types}
                      getOptionLabel={(t) => t.name}
                      defaultValue={rowInfo.type}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setType(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Type"
                          variant="standard"
                          error={"type" in errors}
                          helperText={errors.type?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="space-between">
              <Grid item mx={1} xs={12}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="remarks"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="Remarks"
                        multiline
                        rows={4}
                        variant="standard"
                        defaultValue={rowInfo.remarks}
                        autoComplete="off"
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setRemarks(e.target.value);
                          setChecked(false);
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                  <Table sx={{ width: "90%", mx: "auto" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedName ? (
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>{rowInfo.name}</TableCell>
                          <TableCell>{name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedCountry ? (
                        <TableRow>
                          <TableCell>Country</TableCell>
                          <TableCell>{rowInfo.country.name}</TableCell>
                          <TableCell>{country?.name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedType ? (
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>{rowInfo.type.name}</TableCell>
                          <TableCell>{type?.name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedRemarks ? (
                        <TableRow>
                          <TableCell>Remarks</TableCell>
                          <TableCell>{rowInfo.remarks}</TableCell>
                          <TableCell>{remarks}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Typography sx={{ m: 1 }} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid container justifyContent="space-around" my={1}>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" disabled={!checked || !isChanged}>
              更新
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
