import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  styled,
  Toolbar,
  Typography,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { useAuth0 } from "@auth0/auth0-react";
import LoadingPage from "./LoadingPage";
import MainRoutes from "../../Routes";

const drawerWidth = 270;
const baseColor = "black";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [open, setOpen] = useState(false);
  const [expandItemMasterList, setExpandItemMasterList] = useState(false);
  const [expandReportList, setExpandReportList] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setExpandReportList(false);
    setExpandItemMasterList(false);
    setOpen(false);
  };

  const handleClickItemMasterList = () => {
    setExpandItemMasterList(!expandItemMasterList);
  };

  const handleClickReportList = () => {
    setExpandReportList(!expandReportList);
  };

  const { isAuthenticated } = useAuth0();

  const nestedReportList = [
    { siteName: "Investor", to: "/report_investor" },
    { siteName: "Segment One", to: "/report_segment_one" },
    { siteName: "Segment All", to: "/report_segment_all" },
    { siteName: "Performance", to: "/report_performance" },
  ];

  const menuList = [
    { siteName: "Transaction", to: "/transaction" },
    { siteName: "Balance", to: "/balance" },
    { siteName: "Performance", to: "/performance" },
    { siteName: "NAV", to: "/net_asset_values" },
    { siteName: "Investor", to: "/investor" },
    { siteName: "Fund", to: "/fund" },
    {
      siteName: "投資一任契約顧客(国内)",
      to: "/domestic_discretionary_client",
    },
    {
      siteName: "投資一任契約顧客(海外)",
      to: "/foreign_discretionary_client",
    },
    { siteName: "FX Rate", to: "/rate" },
  ];

  const nestedItemMasterList = [
    { siteName: "Investor Type", to: "/investor_type" },
    { siteName: "Country", to: "/country" },
    { siteName: "Custodian", to: "/custodian" },
    { siteName: "受託信託銀行", to: "/trustee_bank" },
    { siteName: "再受託信託銀行", to: "/re_trustee_bank" },
  ];

  return (
    <div>
      {isAuthenticated ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <AppBar position="fixed" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                UMJ-CMS
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              <ListItem
                button
                component={Link}
                to="/"
                onClick={handleDrawerClose}
              >
                <ListItemText primary="Top" sx={{ color: `${baseColor}` }} />
              </ListItem>
            </List>
            <List>
              <ListItem button onClick={handleClickReportList}>
                <ListItemText primary="Report" />
                {expandReportList ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={expandReportList} timeout="auto" unmountOnExit>
                {nestedReportList.map((obj) => (
                  <ListItem
                    button
                    component={Link}
                    to={obj.to}
                    onClick={handleDrawerClose}
                    sx={{ pl: 4 }}
                    key={`report-${obj.siteName}`}
                  >
                    <ListItemText
                      primary={obj.siteName}
                      sx={{ color: `${baseColor}` }}
                    />
                  </ListItem>
                ))}
              </Collapse>
            </List>
            {menuList.map((obj) => (
              <List key={`parents-${obj.siteName}`}>
                <ListItem
                  button
                  component={Link}
                  to={obj.to}
                  onClick={handleDrawerClose}
                >
                  <ListItemText
                    primary={obj.siteName}
                    sx={{ color: `${baseColor}` }}
                  />
                </ListItem>
              </List>
            ))}
            <List>
              <ListItem button onClick={handleClickItemMasterList}>
                <ListItemText primary="項目マスタ" />
                {expandItemMasterList ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={expandItemMasterList} timeout="auto" unmountOnExit>
                {nestedItemMasterList.map((obj) => (
                  <ListItem
                    button
                    component={Link}
                    to={obj.to}
                    onClick={handleDrawerClose}
                    sx={{ pl: 4 }}
                    key={`item-masters-${obj.siteName}`}
                  >
                    <ListItemText
                      primary={obj.siteName}
                      sx={{ color: `${baseColor}` }}
                    />
                  </ListItem>
                ))}
              </Collapse>
            </List>
          </Drawer>
          <Main open={open}>
            <DrawerHeader />
            <MainRoutes />
          </Main>
        </Box>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}
