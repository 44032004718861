import { ChangeEvent, useContext, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FundSwitches from "../molecules/fund/SwitchShowFund";
import { FundContext } from "../../Context/FundContext";
import { FundBar } from "../molecules/fund/FundBar";
import { FundInfoDialog } from "../organisms/fund/FundInfoDialog";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { NameTableData } from "./TrusteeBank";

export interface FundData {
  id: number;
  code: string;
  name: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  custodian1: NameTableData;
  custodian2: NameTableData;
  custodian3: NameTableData;
  administrator: string;
  custodian1AccountNumberMemo: string;
  custodian2AccountNumberMemo: string;
  custodian3AccountNumberMemo: string;
  administratorAccountNumber: string;
  accountingYear: string;
  currency: string;
  domicile: string;
  type: string;
  trustee: string;
  auditor: string;
  strategy: string;
  investmentManager: string;
  investmentAdvisor: string;
  managers: string;
  rateCompany: string;
  remarks: string;
  empty: string;
  message: string;
}

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "No",
    numeric: false,
    disablePadding: true,
    label: "No",
  },
  {
    id: "Code",
    numeric: true,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "Name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "Administrator",
    numeric: true,
    disablePadding: false,
    label: "Administrator",
  },
  {
    id: "Custodian",
    numeric: true,
    disablePadding: false,
    label: "PB/Custodian1",
  },
  {
    id: "Closing Date",
    numeric: true,
    disablePadding: false,
    label: "Closing Date",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface Num {
  counts: number;
}

function EnhancedTableToolbar(props: Num) {
  const { counts } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Box sx={{ flex: "1 1 100%", textAlign: "left", padding: "5px" }}>
        <FundSwitches />
      </Box>
      <Typography
        sx={{
          flex: "1 1 100%",
          textAlign: "right",
          padding: "20px",
        }}
        variant="inherit"
        id="tableTitle"
        component="div"
      >
        全 {counts} 件
      </Typography>
    </Toolbar>
  );
}

export default function FundTable() {
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { showFund } = useContext(FundContext);
  const [open, setOpen] = useState(false);
  const [render, setRender] = useState(false);
  const [rowInfo, setRowInfo] = useState<FundData>({} as FundData);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // 全件取得
  const { data: allFunds } = useFetch<FundData[]>(
    `/funds`,
    render,
    setIsSuccess,
    setResMsg
  );

  // アクティブなファンドのみ取得
  const { data: activeFunds } = useFetch<FundData[]>(
    `/funds?isActiveOnly=true`,
    render,
    setIsSuccess,
    setResMsg
  );

  // カストディアン一覧を取得
  const { data: custodians } = useFetch<NameTableData[]>(
    `/custodians?doesIncludeUnselected=true`,
    null,
    setIsSuccess,
    setResMsg
  );

  const funds = showFund ? allFunds : activeFunds;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - funds.length) : 0;

  const showFundInfoDialog = (row: FundData) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <FundBar
        funds={funds}
        custodians={custodians}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        setIsSuccess={setIsSuccess}
      />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar counts={funds.length} />
        <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead />
            <TableBody>
              {funds
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: FundData, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      sx={{ width: "max" }}
                      onClick={() => showFundInfoDialog(row)}
                      key={row.id}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        align="center"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell align="center">{row.code}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.administrator}</TableCell>
                      <TableCell align="center">
                        {row.custodian1.name}
                      </TableCell>
                      <TableCell align="center">{row.closingDate}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 75, 100]}
          component="div"
          count={funds.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FundInfoDialog
        open={open}
        setOpen={setOpen}
        rowInfo={rowInfo}
        funds={funds}
        custodians={custodians}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        setIsSuccess={setIsSuccess}
      />
    </Box>
  );
}
