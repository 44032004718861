import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { usePut } from "../../../hooks/usePut";
import { NavData } from "../../templates/NetAssetValue";
import { FundData } from "../../templates/Fund";
import { NavDataForApiRequest } from "./AddNetAssetValueDialog";
import DeleteCommonModalInEdit from "../../molecules/common/DeleteCommonModalInEdit";

const schema = yup.object({
  targetDate: yup.date().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  fund: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  currency: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  nav: yup
    .string()
    .matches(
      /^[-]*[0-9]{1,12}(\.[0-9]{1,12})?$/,
      "半角数字 (整数12桁、小数12桁以内)で入力してください"
    ),
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
  rowInfo: NavData;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function EditNetAssetValueInfoDialog(props: Props) {
  const {
    open,
    setOpen,
    render,
    setRender,
    setResMsg,
    funds,
    rowInfo,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [fund, setFund] = useState<FundData | null>(null);
  const [targetDate, setTargetDate] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [nav, setNav] = useState<string | null>(null);

  const resetValues = () => {
    setChecked(false);
    setFund(null);
    setTargetDate(null);
    setCurrency(null);
    setNav(null);
  };

  const handleClose = () => {
    reset();
    resetValues();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NavDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/net_asset_value",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const onSubmit: SubmitHandler<NavDataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      fundId: data.fund?.id,
      targetDate: data.targetDate
        ? dayjs(data.targetDate).endOf("month").format("YYYY-MM-DD")
        : null,
      currency: data.currency,
      nav: data.nav,
    });

    await putRequest(body);
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedFund = fund !== null && fund.code !== rowInfo.fund.code;
  const isChangedTargetDate =
    targetDate && targetDate !== dayjs(rowInfo.targetDate).format("YYYY-MM");
  const isChangedCurrency = currency && currency !== rowInfo.currency;
  const isChangedNav = nav && Number(nav) !== Number(rowInfo.nav);

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedFund || isChangedTargetDate || isChangedCurrency || isChangedNav;

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          mb={1}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <DialogTitle>詳細情報</DialogTitle>
          <DeleteCommonModalInEdit
            endpoint="/net_asset_value"
            id={rowInfo.id}
            render={render}
            setRender={setRender}
            setOpenEditDialog={setOpen}
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
        <DialogContent>
          <Grid container my={4} justifyContent="space-around">
            <Grid item xs={5}>
              <Stack>
                <Controller
                  name="targetDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Balance Date"
                      variant="standard"
                      type="month"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.targetDate !== ""
                          ? dayjs(rowInfo.targetDate).format("YYYY-MM")
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setTargetDate(e.target.value);
                        setChecked(false);
                      }}
                      error={"targetDate" in errors}
                      helperText={errors.targetDate?.message}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={5}>
              <Controller
                name="fund"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={funds}
                    getOptionLabel={(f) => f.code}
                    defaultValue={rowInfo.fund}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setFund(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Fund Code"
                        variant="standard"
                        error={"fund" in errors}
                        helperText={errors.fund?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-around">
            <Grid item xs={4}>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={["USD", "JPY"]}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setCurrency(data);
                      setChecked(false);
                    }}
                    defaultValue={rowInfo.currency}
                    renderInput={(params) => (
                      <TextField
                        label="Currency"
                        variant="standard"
                        error={"currency" in errors}
                        helperText={errors.currency?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="nav"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="NAV"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.nav}
                    error={"nav" in errors}
                    helperText={errors.nav?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setNav(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                  <Table sx={{ width: "90%", mx: "auto" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedFund ? (
                        <TableRow>
                          <TableCell>Fund Code</TableCell>
                          <TableCell>{rowInfo.fund.code}</TableCell>
                          <TableCell>{fund?.code}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedTargetDate ? (
                        <TableRow>
                          <TableCell>Balance Date</TableCell>
                          <TableCell>
                            {dayjs(rowInfo.targetDate).format("YYYY年MM月")}
                          </TableCell>
                          <TableCell>
                            {dayjs(targetDate).format("YYYY年MM月")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedCurrency ? (
                        <TableRow>
                          <TableCell>Currency</TableCell>
                          <TableCell>{rowInfo.currency}</TableCell>
                          <TableCell>{currency}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedNav ? (
                        <TableRow>
                          <TableCell>NAV</TableCell>
                          <TableCell>{rowInfo.nav}</TableCell>
                          <TableCell>{nav}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Typography sx={{ m: 1 }} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid container justifyContent="space-around" my={1}>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" disabled={!checked || !isChanged}>
              更新
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
