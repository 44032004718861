import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorResponse } from "../components/templates/Transaction";
import { SnackBarContext } from "../Context/SnackBarContext";

export const useFetch = <T,>(
  endPoint: string,
  render: boolean | null,
  setIsSuccess: Dispatch<SetStateAction<boolean>>,
  setResMsg: Dispatch<SetStateAction<string>>
) => {
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [data, setData] = useState<T | []>([]);
  const { getAccessTokenSilently } = useAuth0();

  const url = `${process.env.REACT_APP_API_URL || ""}${endPoint}`;

  const unknownErrMsg =
    "意図しないエラーが発生しました。管理者に連絡してください。";

  useEffect(() => {
    void (async (): Promise<void> => {
      try {
        const accessToken = await getAccessTokenSilently();
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const res = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (res.ok) {
          // 上手く取得できた場合
          setData((await res.json()) as unknown as T);
          // useFetchについては、メッセージを表示しない
          setIsSuccess(true);
        } else if (res.status === 400) {
          // BEからエラーJSONが返された場合
          const errorJson = (await res.json()) as ErrorResponse;
          setResMsg(errorJson.message);
          setShowSnackBars(!showSnackBars);
          setIsSuccess(false);
        } else {
          // そもそもBEまで届いていない場合
          setResMsg(unknownErrMsg);
          setShowSnackBars(!showSnackBars);
          setIsSuccess(false);
        }
      } catch (e) {
        // その他、意図しないエラー
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        setResMsg(`${unknownErrMsg}${e}`);
        setShowSnackBars(!showSnackBars);
        setIsSuccess(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  return { data };
};
