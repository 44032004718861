import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePost } from "../../../hooks/usePost";
import { FundData } from "../../templates/Fund";

export interface NavDataForApiRequest {
  fund: FundData;
  targetDate: string;
  currency: string;
  nav: string;
}

const schema = yup.object({
  targetDate: yup.string().required("入力は必須です"),
  fund: yup
    .object()
    .typeError("入力は必須です") // バツボタン対策
    .required("入力は必須です"),
  currency: yup
    .string()
    .typeError("入力は必須です") // バツボタン対策
    .required("入力は必須です"),
  nav: yup
    .string()
    .required("入力は必須です") // NAV管理に関しては必須入力
    .matches(
      /^[-]*[0-9]{1,12}(\.[0-9]{1,12})?$/,
      "半角数字 (整数12桁、小数12桁以内)で入力してください"
    ),
});

interface Props {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function AddNetAssetValueDialog(props: Props) {
  const { render, setRender, setResMsg, funds, setIsSuccess } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<NavDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { postRequest } = usePost(
    "/net_asset_value",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<NavDataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      fundId: data.fund.id,
      targetDate: dayjs(data.targetDate).endOf("month").format("YYYY-MM-DD"),
      currency: data.currency,
      nav: data.nav,
    });
    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <Grid container my={4} justifyContent="space-around">
              <Grid item xs={5}>
                <Stack>
                  <Controller
                    name="targetDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Balance Date"
                        variant="standard"
                        type="month"
                        InputLabelProps={{ shrink: true }}
                        error={"targetDate" in errors}
                        helperText={errors.targetDate?.message}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={5}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={funds}
                      getOptionLabel={(f) => f.code}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.fund)}
                          helperText={errors.fund?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="space-around">
              <Grid item xs={4}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={["USD", "JPY"]}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Currency"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"currency" in errors}
                          helperText={errors.currency?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="nav"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="NAV"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"nav" in errors}
                      helperText={errors.nav?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
