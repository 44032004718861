import { ChangeEvent, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
} from "@mui/material";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import DeleteCommonModal from "../molecules/common/DeleteCommonModal";
import { useFetch } from "../../hooks/useFetch";
import AddCommonDialog from "../molecules/common/AddCommonDialog";
import { NameTableData } from "./TrusteeBank";

export default function ReTrusteeBankTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [render, setRender] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const { data: reTrusteeBanks } = useFetch<NameTableData[]>(
    `/re_trustee_banks?doesIncludeUnselected=false`,
    render,
    setIsSuccess,
    setResMsg
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - reTrusteeBanks.length)
      : 0;

  const resourceType = "ReTrusteeBank";

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: -1,
          mb: 5,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h5">
          再受託信託銀行
        </Typography>
        <AddCommonDialog
          resourceType={resourceType}
          render={render}
          setRender={setRender}
          setIsSuccess={setIsSuccess}
          setResMsg={setResMsg}
        />
      </Box>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Grid container direction="column" alignItems="center">
        <Paper sx={{ width: 400, mb: 2 }}>
          <Toolbar
            sx={{
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                textAlign: "right",
                padding: "20px",
              }}
              variant="inherit"
              component="div"
            >
              全 {reTrusteeBanks.length} 件
            </Typography>
          </Toolbar>
          <TableContainer>
            <Table sx={{ width: 400 }} aria-labelledby="tableTitle">
              <TableBody>
                {reTrusteeBanks
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell>
                          <DeleteCommonModal
                            id={row.id}
                            render={render}
                            setRender={setRender}
                            setResMsg={setResMsg}
                            endpoint="/re_trustee_bank"
                            setIsSuccess={setIsSuccess}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 53 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 30, 50]}
            component="div"
            count={reTrusteeBanks.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Grid>
    </Box>
  );
}
