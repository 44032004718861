import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dispatch, SetStateAction, useState } from "react";
import { usePost } from "../../../hooks/usePost";

interface Input {
  name: string;
}

export interface PropsNameTables {
  resourceType: string;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
}

export default function AddCommonDialog(props: PropsNameTables) {
  const { resourceType, render, setRender, setResMsg, setIsSuccess } = props;
  const [open, setOpen] = useState(false);

  let regex: RegExp;
  let regexMsg: string;
  let endPoint: string;
  let resourceName: string;

  // resourceTypeによって、バリデーションルールや文言、エンドポイントをスイッチ
  if (resourceType === "InvestorType") {
    regex = /^[0-9a-zA-Z/| ]+$/;
    regexMsg = "半角英数字または半角スペース、半角スラッシュで入力してください";
    endPoint = "/investor_type";
    resourceName = "Investor Type";
  } else if (resourceType === "Country") {
    regex = /^[a-zA-Z| ]+$/;
    regexMsg = "半角英字または半角スペースで入力してください";
    endPoint = "/country";
    resourceName = "Country";
  } else if (resourceType === "Custodian") {
    regex = /^[ -~]+$/;
    regexMsg = "半角英数字または半角記号で入力してください";
    endPoint = "/custodian";
    resourceName = "Custodian";
  } else if (resourceType === "TrusteeBank") {
    regex = /^[一-龥ぁ-んァ-ンa-zA-Z -/:-@[-~]+$/;
    regexMsg =
      "全角ひらがな、全角カタカナ、漢字、半角英字、半角記号で入力してください";
    endPoint = "/trustee_bank";
    resourceName = "受託信託銀行";
  } else {
    // if (resourceType === "ReTrusteeBank")
    regex = /^[一-龥ぁ-んァ-ンa-zA-Z -/:-@[-~]+$/;
    regexMsg =
      "全角ひらがな、全角カタカナ、漢字、半角英字、半角記号で入力してください";
    endPoint = "/re_trustee_bank";
    resourceName = "再受託信託銀行";
  }

  const schema = yup.object({
    name: yup
      .string()
      .required("入力は必須です")
      .matches(regex, regexMsg)
      .max(100, "100文字以内で入力してください"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const { postRequest } = usePost(
    endPoint,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<Input> = async (data) => {
    const body = JSON.stringify({ name: data.name });
    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <DialogContentText>
              追加する{resourceName}名を記入してください。
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              fullWidth
              variant="standard"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...register("name")}
              error={"name" in errors}
              helperText={errors.name?.message}
              autoComplete="off"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmit)}>追加</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
