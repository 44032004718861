import { Dispatch, SetStateAction, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorResponse } from "../components/templates/Transaction";
import { SnackBarContext } from "../Context/SnackBarContext";

export const useGet = <T,>(
  endPoint: string,
  setIsSuccess: Dispatch<SetStateAction<boolean>>,
  setResMsg: Dispatch<SetStateAction<string>>
) => {
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const { getAccessTokenSilently } = useAuth0();

  const unknownErrMsg =
    "意図しないエラーが発生しました。管理者に連絡してください。";

  const getRequest = async (condition: string): Promise<T | null> => {
    try {
      // conditionが空文字でない場合、=という記号がなければエラー
      if (condition !== "" && !condition.match(/=/)) {
        setIsSuccess(false);
        setResMsg("パラメータが不正です。システムの管理者に連絡してください。");
        setShowSnackBars(!showSnackBars);
        return null;
      }

      const accessToken = await getAccessTokenSilently();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL || ""}${endPoint}?${condition}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (res.ok) {
        setIsSuccess(true);
        return (await res.json()) as unknown as T;
      }
      if (res.status === 400) {
        // BEからエラーJSONが返された場合
        const errorJson = (await res.json()) as ErrorResponse;
        setIsSuccess(false);
        setResMsg(errorJson.message);
        setShowSnackBars(!showSnackBars);
        return null;
      }
      // そもそもBEまで届いていない場合
      setIsSuccess(false);
      setResMsg(unknownErrMsg);
      setShowSnackBars(!showSnackBars);
      return null;
    } catch (e) {
      // その他、意図しないエラー
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${unknownErrMsg}${e}`);
      setShowSnackBars(!showSnackBars);
      return null;
    }
  };

  return { getRequest };
};
