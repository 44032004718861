import { Dispatch, SetStateAction, useState } from "react";
import { Button, Grid, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDelete } from "../../../hooks/useDelete";

interface Props {
  id: number;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  endpoint: string;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function DeleteCommonModal(props: Props) {
  const { id, render, setRender, setResMsg, endpoint, setIsSuccess } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { deleteRequest } = useDelete(
    endpoint,
    id,
    setResMsg,
    setOpen,
    setRender,
    render,
    setIsSuccess
  );

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ご確認ください</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="center">
            <Button onClick={handleClose} autoFocus>
              キャンセル
            </Button>
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={deleteRequest}
            >
              削除
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
