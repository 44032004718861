import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ForeignDiscretionaryClientProps } from "../../molecules/foreignDiscretionaryClient/ForeignDiscretionaryClientBar";
import { usePost } from "../../../hooks/usePost";
import { FundData } from "../../templates/Fund";

export interface FdcDataForApiRequest {
  id: number;
  fund: FundData;
  currentAddress: string;
  historicalAddressMemo: string;
  addressee1: string;
  mail1: string;
  addressee2: string;
  mail2: string;
  addressee3: string;
  mail3: string;
  remarks: string;
}

const schema = yup.object({
  fund: yup.object().typeError("入力は必須です").required("入力は必須です"),
  currentAddress: yup.string().max(1000, "1000文字以内で入力してください"),
  historicalAddressMemo: yup.string().max(1000, "1000文字以内で入力してください"),
  addressee1: yup
      .string()
      .matches(
          /^[A-Z0-9 -/:-@[-~]*$/,
          "半角英数字または半角記号で入力してください"
      ).max(400, "400文字以内で入力してください"),
  addressee2: yup
      .string()
      .matches(
          /^[A-Z0-9 -/:-@[-~]*$/,
          "半角英数字または半角記号で入力してください"
      ).max(400, "400文字以内で入力してください"),
  addressee3: yup
      .string()
      .matches(
          /^[A-Z0-9 -/:-@[-~]*$/,
          "半角英数字または半角記号で入力してください"
      ).max(400, "400文字以内で入力してください"),
  mail1: yup.string().email("メールアドレスの形式で入力してください"),
  mail2: yup.string().email("メールアドレスの形式で入力してください"),
  mail3: yup.string().email("メールアドレスの形式で入力してください"),
});

export default function AddForeignDiscretionaryClientDialog(
  props: ForeignDiscretionaryClientProps
) {
  const { render, setRender, setResMsg, funds, setIsSuccess } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FdcDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { postRequest } = usePost(
    "/foreign_discretionary_client",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<FdcDataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      fundId: data.fund?.id,
      currentAddress: data.currentAddress || "",
      historicalAddressMemo: data.historicalAddressMemo || "",
      addressee1: data.addressee1 || "",
      mail1: data.mail1 || "",
      addressee2: data.addressee2 || "",
      mail2: data.mail2 || "",
      addressee3: data.addressee3 || "",
      mail3: data.mail3 || "",
      remarks: data.remarks || "",
    });

    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={3}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={funds}
                      getOptionLabel={(fund) => `${fund.code}`}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.fund)}
                          helperText={errors.fund?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="currentAddress"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="住所"
                        variant="standard"
                        multiline
                        rows={3}
                        error={Boolean(errors.currentAddress)}
                        helperText={errors.currentAddress?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={6}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="historicalAddressMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="住所 (履歴)"
                        variant="standard"
                        multiline
                        rows={3}
                        error={Boolean(errors.historicalAddressMemo)}
                        helperText={errors.historicalAddressMemo?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名1"
                        variant="standard"
                        error={Boolean(errors.addressee1)}
                        helperText={errors.addressee1?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス1"
                        variant="standard"
                        error={Boolean(errors.mail1)}
                        helperText={errors.mail1?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名2"
                        variant="standard"
                        error={Boolean(errors.addressee2)}
                        helperText={errors.addressee2?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス2"
                        variant="standard"
                        error={Boolean(errors.mail2)}
                        helperText={errors.mail2?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee3"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名3"
                        variant="standard"
                        error={Boolean(errors.addressee3)}
                        helperText={errors.addressee3?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail3"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス3"
                        variant="standard"
                        error={Boolean(errors.mail3)}
                        helperText={errors.mail3?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} direction="row" justifyContent="flex-start">
              <Grid item mx={1} xs={12}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="remarks"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="備考"
                        multiline
                        rows={4}
                        variant="standard"
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
