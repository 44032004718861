import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AddForeignDiscretionaryClientDialog from "../../organisms/foreignDiscretionaryClient/AddForeignDiscretionaryClientDialog";
import { FundData } from "../../templates/Fund";
import ExportForeignDiscretionaryClientsExcelButton from "./ExportForeignDiscretionaryClientsExcelButton";

export interface ForeignDiscretionaryClientProps {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function ForeignDiscretionaryClientBar(
  props: ForeignDiscretionaryClientProps
) {
  const { render, setRender, setResMsg, funds, setIsSuccess } = props;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: 1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        投資一任契約顧客(海外)
      </Typography>
      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportForeignDiscretionaryClientsExcelButton
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <AddForeignDiscretionaryClientDialog
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          funds={funds}
          setIsSuccess={setIsSuccess}
        />
      </Box>
    </Box>
  );
}
