import {
  Checkbox,
  DialogActions,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { Dispatch, SetStateAction, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { FundData } from "../../templates/Fund";
import { usePut } from "../../../hooks/usePut";
import { NameTableData } from "../../templates/TrusteeBank";

interface FundDataForApiRequest {
  id: number;
  code: string;
  name: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  custodian1: NameTableData;
  custodian2: NameTableData;
  custodian3: NameTableData;
  administrator: string;
  custodian1AccountNumberMemo: string;
  custodian2AccountNumberMemo: string;
  custodian3AccountNumberMemo: string;
  administratorAccountNumber: string;
  accountingYear: string;
  currency: string;
  domicile: string;
  type: string;
  trustee: string;
  auditor: string;
  strategy: string;
  investmentManager: string;
  investmentAdvisor: string;
  managers: string;
  rateCompany: string;
  remarks: string;
}

// バリデーションルール
const schema = yup.object({
  code: yup
    .string()
    .matches(/^[A-Z0-9]*$/, "半角英数字4文字で入力してください")
    .max(4, "半角英数字4文字で入力してください")
    .min(4, "半角英数字4文字で入力してください"),
  name: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(150, "150文字以内で入力してください"),
  custodian1: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  custodian2: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  custodian3: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  administrator: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(150, "150文字以内で入力してください"),
  custodian1AccountNumberMemo: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~\r\n]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(500, "500文字以内で入力してください"),
  custodian2AccountNumberMemo: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~\r\n]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(500, "500文字以内で入力してください"),
  custodian3AccountNumberMemo: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~\r\n]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(500, "500文字以内で入力してください"),
  administratorAccountNumber: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~\r\n]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(500, "500文字以内で入力してください"),
  accountingYear: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  currency: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  domicile: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  type: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  trustee: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  auditor: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  strategy: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  investmentManager: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  investmentAdvisor: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  managers: yup
    .string()
    .typeError(
      "この値を削除したい場合は、✕ボタンではなくバックスペースで消して更新して下さい"
    )
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(200, "200文字以内で入力してください"),
  rateCompany: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
});

interface Props {
  open: boolean;
  rowInfo: FundData;
  funds: FundData[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  custodians: NameTableData[];
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function FundInfoDialog(props: Props) {
  const {
    open,
    setOpen,
    rowInfo,
    funds,
    custodians,
    render,
    setRender,
    setResMsg,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [contractDate, setContractDate] = useState<string | null>(null);
  const [startingDate, setStartingDate] = useState<string | null>(null);
  const [closingDate, setClosingDate] = useState<string | null>(null);
  const [custodian1, setCustodian1] = useState<NameTableData | null>(null);
  const [custodian2, setCustodian2] = useState<NameTableData | null>(null);
  const [custodian3, setCustodian3] = useState<NameTableData | null>(null);
  const [administrator, setAdministrator] = useState<string | null>(null);
  const [custodian1AccountNumberMemo, setCustodian1AccountNumberMemo] =
    useState<string | null>(null);
  const [custodian2AccountNumberMemo, setCustodian2AccountNumberMemo] =
    useState<string | null>(null);
  const [custodian3AccountNumberMemo, setCustodian3AccountNumberMemo] =
    useState<string | null>(null);
  const [administratorAccountNumber, setAdministratorAccountNumber] = useState<
    string | null
  >(null);
  const [accountingYear, setAccountingYear] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [domicile, setDomicile] = useState<string | null>(null);
  const [type, setType] = useState<string | null>(null);
  const [trustee, setTrustee] = useState<string | null>(null);
  const [auditor, setAuditor] = useState<string | null>(null);
  const [strategy, setStrategy] = useState<string | null>(null);
  const [investmentManager, setInvestmentManager] = useState<string | null>(
    null
  );
  const [investmentAdvisor, setInvestmentAdvisor] = useState<string | null>(
    null
  );
  const [managers, setManagers] = useState<string | null>(null);
  const [rateCompany, setRateCompany] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);

  const resetValue = () => {
    setChecked(false);
    setCode(null);
    setName(null);
    setContractDate(null);
    setStartingDate(null);
    setClosingDate(null);
    setCustodian1(null);
    setCustodian2(null);
    setCustodian3(null);
    setAdministrator(null);
    setCustodian1AccountNumberMemo(null);
    setCustodian2AccountNumberMemo(null);
    setCustodian3AccountNumberMemo(null);
    setAdministratorAccountNumber(null);
    setAccountingYear(null);
    setCurrency(null);
    setDomicile(null);
    setType(null);
    setTrustee(null);
    setAuditor(null);
    setStrategy(null);
    setInvestmentManager(null);
    setInvestmentAdvisor(null);
    setManagers(null);
    setRateCompany(null);
    setRemarks(null);
  };

  const handleClose = () => {
    reset();
    resetValue();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FundDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/fund",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const onSubmit: SubmitHandler<FundDataForApiRequest> = async (
    data: FundDataForApiRequest
  ) => {
    // BEとの取り決めで、"0000-01-01"という文字列をJSONに渡すことでNullアップデート
    const tmpContractDate =
      data.contractDate === "" ? "0000-01-01" : data.contractDate;

    const tmpStartingDate =
      data.startingDate === "" ? "0000-01-01" : data.startingDate;

    const tmpClosingDate =
      data.closingDate === "" ? "0000-01-01" : data.closingDate;

    // 以下項目は、未選択という文字列を空文字に変換してJSONに乗せる
    const tmpAccountingYear =
      data.accountingYear === "未選択" ? "" : data.accountingYear;

    const tmpCurrency = data.currency === "未選択" ? "" : data.currency;

    const tmpRateCompany =
      data.rateCompany === "未選択" ? "" : data.rateCompany;

    const body = JSON.stringify({
      id: rowInfo.id,
      code: data.code,
      name: data.name,
      contractDate: tmpContractDate,
      startingDate: tmpStartingDate,
      closingDate: tmpClosingDate,
      administrator: data.administrator,
      administratorAccountNumber: data.administratorAccountNumber,
      accountingYear: tmpAccountingYear,
      currency: tmpCurrency,
      domicile: data.domicile,
      type: data.type,
      trustee: data.trustee,
      auditor: data.auditor,
      strategy: data.strategy,
      investmentManager: data.investmentManager,
      investmentAdvisor: data.investmentAdvisor,
      managers: data.managers,
      rateCompany: tmpRateCompany,
      custodian1Id: data.custodian1?.id, // IDをリクエストJSONに格納
      custodian2Id: data.custodian2?.id, // IDをリクエストJSONに格納
      custodian3Id: data.custodian3?.id, // IDをリクエストJSONに格納
      custodian1AccountNumberMemo: data.custodian1AccountNumberMemo,
      custodian2AccountNumberMemo: data.custodian2AccountNumberMemo,
      custodian3AccountNumberMemo: data.custodian3AccountNumberMemo,
      remarks: data.remarks,
    });

    await putRequest(body);
  };

  /*
  サジェストで表示するための配列を準備
  */
  const nameArray: string[] = [];
  const administratorArray: string[] = [];
  const domicileArray: string[] = [];
  const typeArray: string[] = [];
  const trusteeArray: string[] = [];
  const auditorArray: string[] = [];
  const strategyArray: string[] = [];
  const investManagerArray: string[] = [];
  const investAdvisorArray: string[] = [];
  const managersArray: string[] = [];

  funds.forEach((row: FundData) => {
    nameArray.push(row.name);
    domicileArray.push(row.domicile);
    administratorArray.push(row.administrator);
    typeArray.push(row.type);
    trusteeArray.push(row.trustee);
    auditorArray.push(row.auditor);
    strategyArray.push(row.strategy);
    investManagerArray.push(row.investmentManager);
    investAdvisorArray.push(row.investmentAdvisor);
    managersArray.push(row.managers);
  });

  // 重複、空文字を除外して配列を作成
  const suggestingNames = nameArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingAdministrator = administratorArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingDomicile = domicileArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingType = typeArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingTrustee = trusteeArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingAuditor = auditorArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingStrategy = strategyArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingInvestManager = investManagerArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingInvestAdvisor = investAdvisorArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const suggestingManagers = managersArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedCode = code !== null && code !== rowInfo.code;
  const isChangedName = name !== null && name !== rowInfo.name;
  const isChangedContractDate =
    contractDate !== null &&
    !(contractDate === "" && rowInfo.contractDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    contractDate !== rowInfo.contractDate;
  const isChangedStartingDate =
    startingDate !== null &&
    !(startingDate === "" && rowInfo.startingDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    startingDate !== rowInfo.startingDate;
  const isChangedClosingDate =
    closingDate !== null &&
    !(closingDate === "" && rowInfo.closingDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    closingDate !== rowInfo.closingDate;
  const isChangedCustodian1 =
    custodian1 !== null && custodian1.name !== rowInfo.custodian1.name; // custodian1とrowInfo.custodian1は厳密にはinterfaceが違うので値で比較
  const isChangedCustodian2 =
    custodian2 !== null && custodian2.name !== rowInfo.custodian2.name; // custodian2とrowInfo.custodian2は厳密にはinterfaceが違うので値で比較
  const isChangedCustodian3 =
    custodian3 !== null && custodian3.name !== rowInfo.custodian3.name; // custodian3とrowInfo.custodian3は厳密にはinterfaceが違うので値で比較
  const isChangedAdministrator =
    administrator !== null && administrator !== rowInfo.administrator;
  const isChangedCustodian1AccountNumberMemo =
    custodian1AccountNumberMemo !== null &&
    custodian1AccountNumberMemo !== rowInfo.custodian1AccountNumberMemo;
  const isChangedCustodian2AccountNumberMemo =
    custodian2AccountNumberMemo !== null &&
    custodian2AccountNumberMemo !== rowInfo.custodian2AccountNumberMemo;
  const isChangedCustodian3AccountNumberMemo =
    custodian3AccountNumberMemo !== null &&
    custodian3AccountNumberMemo !== rowInfo.custodian3AccountNumberMemo;
  const isChangedAdministratorAccountNumber =
    administratorAccountNumber !== null &&
    administratorAccountNumber !== rowInfo.administratorAccountNumber;
  const isChangedAccountingYear =
    accountingYear !== null &&
    accountingYear.replace("未選択", "") !== rowInfo.accountingYear;
  const isChangedCurrency =
    currency !== null && currency.replace("未選択", "") !== rowInfo.currency;
  const isChangedDomicile = domicile !== null && domicile !== rowInfo.domicile;
  const isChangedType = type !== null && type !== rowInfo.type;
  const isChangedTrustee = trustee !== null && trustee !== rowInfo.trustee;
  const isChangedAuditor = auditor !== null && auditor !== rowInfo.auditor;
  const isChangedStrategy = strategy !== null && strategy !== rowInfo.strategy;
  const isChangedInvestmentManager =
    investmentManager !== null &&
    investmentManager !== rowInfo.investmentManager;
  const isChangedInvestmentAdvisor =
    investmentAdvisor !== null &&
    investmentAdvisor !== rowInfo.investmentAdvisor;
  const isChangedManagers = managers !== null && managers !== rowInfo.managers;
  const isChangedRateCompany =
    rateCompany !== null &&
    rateCompany.replace("未選択", "") !== rowInfo.rateCompany;
  const isChangedRemarks = remarks !== null && remarks !== rowInfo.remarks;

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedCode ||
    isChangedName ||
    isChangedContractDate ||
    isChangedStartingDate ||
    isChangedClosingDate ||
    isChangedCustodian1 ||
    isChangedCustodian2 ||
    isChangedCustodian3 ||
    isChangedAdministrator ||
    isChangedCustodian1AccountNumberMemo ||
    isChangedCustodian2AccountNumberMemo ||
    isChangedCustodian3AccountNumberMemo ||
    isChangedAdministratorAccountNumber ||
    isChangedAccountingYear ||
    isChangedCurrency ||
    isChangedDomicile ||
    isChangedType ||
    isChangedTrustee ||
    isChangedAuditor ||
    isChangedStrategy ||
    isChangedInvestmentManager ||
    isChangedInvestmentAdvisor ||
    isChangedManagers ||
    isChangedRateCompany ||
    isChangedRemarks;

  // 固定値の選択肢を準備
  const monthNameList = [
    "未選択",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currencyNameList = ["未選択", "USD", "JPY"];

  const rateCompanyNameList = [
    "未選択",
    "UMJ",
    "MAPLES",
    "CITCO",
    "IFS",
    "APEX",
    "APEX2",
    "NENKIN",
    "ABS",
  ];

  return (
    <div>
      <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            container
            mb={1}
            justifyContent="space-between"
            alignItems="baseline"
          >
            <DialogTitle>ファンド詳細情報</DialogTitle>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <DialogContent>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      // サジェストなし自由入力(A)
                      label="Fund Code"
                      variant="standard"
                      error={Boolean(errors.code)}
                      helperText={errors.code?.message}
                      defaultValue={rowInfo.code}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setCode(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 500 }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingNames}
                      defaultValue={rowInfo.name}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setName(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Name"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.name)}
                          helperText={errors.name?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setName(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                            field.onChange(e.target.value);
                            setName(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="contractDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Contract Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={rowInfo.contractDate}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setContractDate(e.target.value);
                          setChecked(false);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="startingDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Starting Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={rowInfo.startingDate}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setStartingDate(e.target.value);
                          setChecked(false);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="closingDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Closing Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        defaultValue={rowInfo.closingDate}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setClosingDate(e.target.value);
                          setChecked(false);
                        }}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="custodian1"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={(c) => c.name}
                      defaultValue={rowInfo.custodian1}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setCustodian1(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="PB/Custodian1"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.custodian1)}
                          helperText={errors.custodian1?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="custodian2"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={(c) => c.name}
                      defaultValue={rowInfo.custodian2}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setCustodian2(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="PB/Custodian2"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.custodian2)}
                          helperText={errors.custodian2?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="custodian3"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={custodians}
                      getOptionLabel={(c) => c.name}
                      defaultValue={rowInfo.custodian3}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setCustodian3(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="PB/Custodian3"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.custodian3)}
                          helperText={errors.custodian3?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="administrator"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingAdministrator}
                      defaultValue={rowInfo.administrator}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setAdministrator(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Administrator"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.administrator)}
                          helperText={errors.administrator?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAdministrator(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                            field.onChange(e.target.value);
                            setAdministrator(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="custodian1AccountNumberMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian1 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian1AccountNumberMemo)}
                        helperText={errors.custodian1AccountNumberMemo?.message}
                        defaultValue={rowInfo.custodian1AccountNumberMemo}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setCustodian1AccountNumberMemo(e.target.value);
                          setChecked(false);
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="custodian2AccountNumberMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian2 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian2AccountNumberMemo)}
                        helperText={errors.custodian2AccountNumberMemo?.message}
                        defaultValue={rowInfo.custodian2AccountNumberMemo}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setCustodian2AccountNumberMemo(e.target.value);
                          setChecked(false);
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="custodian3AccountNumberMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="PB/Custodian3 Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.custodian3AccountNumberMemo)}
                        helperText={errors.custodian3AccountNumberMemo?.message}
                        defaultValue={rowInfo.custodian3AccountNumberMemo}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setCustodian3AccountNumberMemo(e.target.value);
                          setChecked(false);
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="administratorAccountNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        id="standard-multiline-static"
                        label="Administrator Account Number"
                        multiline
                        rows={3}
                        variant="standard"
                        error={Boolean(errors.administratorAccountNumber)}
                        helperText={errors.administratorAccountNumber?.message}
                        defaultValue={rowInfo.administratorAccountNumber}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setAdministratorAccountNumber(e.target.value);
                          setChecked(false);
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="accountingYear"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={monthNameList}
                      defaultValue={rowInfo.accountingYear || "未選択"}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setAccountingYear(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Accounting Year"
                          variant="standard"
                          error={"accountingYear" in errors}
                          helperText={errors.accountingYear?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={currencyNameList}
                      defaultValue={rowInfo.currency || "未選択"}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setCurrency(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Base Currency"
                          variant="standard"
                          error={"currency" in errors}
                          helperText={errors.currency?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="domicile"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingDomicile}
                      defaultValue={rowInfo.domicile}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setDomicile(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Domicile"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.domicile)}
                          helperText={errors.domicile?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setDomicile(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                            field.onChange(e.target.value);
                            setDomicile(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingType}
                      defaultValue={rowInfo.type}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setType(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Type"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.type)}
                          helperText={errors.type?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setType(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                            field.onChange(e.target.value);
                            setType(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="trustee"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingTrustee}
                      defaultValue={rowInfo.trustee}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setTrustee(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Trustee"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.trustee)}
                          helperText={errors.trustee?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setTrustee(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更
                            field.onChange(e.target.value);
                            setTrustee(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="auditor"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingAuditor}
                      defaultValue={rowInfo.auditor}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setAuditor(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Auditor"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.auditor)}
                          helperText={errors.auditor?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setAuditor(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更                            field.onChange(e.target.value);
                            setAuditor(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="strategy"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingStrategy}
                      defaultValue={rowInfo.strategy}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setStrategy(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Strategy"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.strategy)}
                          helperText={errors.strategy?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setStrategy(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更                            field.onChange(e.target.value);
                            setStrategy(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="investmentManager"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingInvestManager}
                      defaultValue={rowInfo.investmentManager}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setInvestmentManager(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Investment Manager"
                          variant="standard"
                          /* eslint-disable-next-line react/jsx-props-no-spreading */
                          {...params}
                          error={Boolean(errors.investmentManager)}
                          helperText={errors.investmentManager?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setInvestmentManager(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更                            field.onChange(e.target.value);
                            setInvestmentManager(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 300 }}>
                <Controller
                  name="investmentAdvisor"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingInvestAdvisor}
                      defaultValue={rowInfo.investmentAdvisor}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setInvestmentAdvisor(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Investment Advisor"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.investmentAdvisor)}
                          helperText={errors.investmentAdvisor?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setInvestmentAdvisor(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更                            field.onChange(e.target.value);
                            setInvestmentAdvisor(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 400 }}>
                <Controller
                  name="managers"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // サジェストあり自由入力(B)
                      freeSolo
                      options={suggestingManagers}
                      defaultValue={rowInfo.managers}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setManagers(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Manager"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.managers)}
                          helperText={errors.managers?.message}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setManagers(e.target.value);
                            setChecked(false);
                          }}
                          onBlur={(e) => {
                            // onChangeだけだと、AutoCompleteで補完された場合に発火してくれないためonBlurでもステートを変更                            field.onChange(e.target.value);
                            setManagers(e.target.value);
                            setChecked(false);
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="rateCompany"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={rateCompanyNameList}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setRateCompany(data);
                        setChecked(false);
                      }}
                      defaultValue={rowInfo.rateCompany || "未選択"}
                      renderInput={(params) => (
                        <TextField
                          label="Rate Company"
                          variant="standard"
                          error={"rateCompany" in errors}
                          helperText={errors.rateCompany?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={2} xs={12}>
                <Box
                  sx={{
                    "& .MuiTextField-root": { width: "75%" },
                  }}
                >
                  <Controller
                    name="remarks"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="Remarks"
                        multiline
                        rows={4}
                        variant="standard"
                        defaultValue={rowInfo.remarks}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setRemarks(e.target.value);
                          setChecked(false);
                        }}
                        autoComplete="off"
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            {isChanged ? (
              <>
                <Grid container my={2} justifyContent="center">
                  <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                    <Table sx={{ width: "90%", mx: "auto" }} size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>項目</TableCell>
                          <TableCell>変更前</TableCell>
                          <TableCell>変更後</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isChangedCode ? (
                          <TableRow>
                            <TableCell>Fund Code</TableCell>
                            <TableCell>{rowInfo.code}</TableCell>
                            <TableCell>{code}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedName ? (
                          <TableRow>
                            <TableCell>Fund Name</TableCell>
                            <TableCell>{rowInfo.name}</TableCell>
                            <TableCell>{name}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedContractDate ? (
                          <TableRow>
                            <TableCell>Contract Date</TableCell>
                            <TableCell>{rowInfo.contractDate}</TableCell>
                            <TableCell>{contractDate}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedStartingDate ? (
                          <TableRow>
                            <TableCell>Starting Date</TableCell>
                            <TableCell>{rowInfo.startingDate}</TableCell>
                            <TableCell>{startingDate}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedClosingDate ? (
                          <TableRow>
                            <TableCell>Closing Date</TableCell>
                            <TableCell>{rowInfo.closingDate}</TableCell>
                            <TableCell>{closingDate}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian1 ? (
                          <TableRow>
                            <TableCell>PB/Custodian1</TableCell>
                            <TableCell>{rowInfo.custodian1.name}</TableCell>
                            <TableCell>{custodian1?.name}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian2 ? (
                          <TableRow>
                            <TableCell>PB/Custodian2</TableCell>
                            <TableCell>{rowInfo.custodian2.name}</TableCell>
                            <TableCell>{custodian2?.name}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian3 ? (
                          <TableRow>
                            <TableCell>PB/Custodian3</TableCell>
                            <TableCell>{rowInfo.custodian3.name}</TableCell>
                            <TableCell>{custodian3?.name}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedAdministrator ? (
                          <TableRow>
                            <TableCell>administrator</TableCell>
                            <TableCell>{rowInfo.administrator}</TableCell>
                            <TableCell>{administrator}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian1AccountNumberMemo ? (
                          <TableRow>
                            <TableCell>PB/Custodian1 Account Number</TableCell>
                            <TableCell>
                              {rowInfo.custodian1AccountNumberMemo}
                            </TableCell>
                            <TableCell>{custodian1AccountNumberMemo}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian2AccountNumberMemo ? (
                          <TableRow>
                            <TableCell>PB/Custodian2 Account Number</TableCell>
                            <TableCell>
                              {rowInfo.custodian2AccountNumberMemo}
                            </TableCell>
                            <TableCell>{custodian2AccountNumberMemo}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCustodian3AccountNumberMemo ? (
                          <TableRow>
                            <TableCell>PB/Custodian3 Account Number</TableCell>
                            <TableCell>
                              {rowInfo.custodian3AccountNumberMemo}
                            </TableCell>
                            <TableCell>{custodian3AccountNumberMemo}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedAdministratorAccountNumber ? (
                          <TableRow>
                            <TableCell>Administrator Account Number</TableCell>
                            <TableCell>
                              {rowInfo.administratorAccountNumber}
                            </TableCell>
                            <TableCell>{administratorAccountNumber}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedAccountingYear ? (
                          <TableRow>
                            <TableCell>Fund Accounting Year</TableCell>
                            <TableCell>
                              {rowInfo.accountingYear || "未選択"}
                            </TableCell>
                            <TableCell>{accountingYear}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedCurrency ? (
                          <TableRow>
                            <TableCell>Fund Base Currency</TableCell>
                            <TableCell>
                              {rowInfo.currency || "未選択"}
                            </TableCell>
                            <TableCell>{currency}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedDomicile ? (
                          <TableRow>
                            <TableCell>Fund Domicile</TableCell>
                            <TableCell>{rowInfo.domicile}</TableCell>
                            <TableCell>{domicile}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedType ? (
                          <TableRow>
                            <TableCell>Fund Type</TableCell>
                            <TableCell>{rowInfo.type}</TableCell>
                            <TableCell>{type}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedTrustee ? (
                          <TableRow>
                            <TableCell>Fund Trustee</TableCell>
                            <TableCell>{rowInfo.trustee}</TableCell>
                            <TableCell>{trustee}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedAuditor ? (
                          <TableRow>
                            <TableCell>Fund Auditor</TableCell>
                            <TableCell>{rowInfo.auditor}</TableCell>
                            <TableCell>{auditor}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedStrategy ? (
                          <TableRow>
                            <TableCell>Fund Strategy</TableCell>
                            <TableCell>{rowInfo.strategy}</TableCell>
                            <TableCell>{strategy}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedInvestmentManager ? (
                          <TableRow>
                            <TableCell>Investment Manager</TableCell>
                            <TableCell>{rowInfo.investmentManager}</TableCell>
                            <TableCell>{investmentManager}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedInvestmentAdvisor ? (
                          <TableRow>
                            <TableCell>Investment Advisor</TableCell>
                            <TableCell>{rowInfo.investmentAdvisor}</TableCell>
                            <TableCell>{investmentAdvisor}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedManagers ? (
                          <TableRow>
                            <TableCell>Fund Manager</TableCell>
                            <TableCell>{rowInfo.managers}</TableCell>
                            <TableCell>{managers}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedRateCompany ? (
                          <TableRow>
                            <TableCell>Rate Company</TableCell>
                            <TableCell>
                              {rowInfo.rateCompany || "未選択"}
                            </TableCell>
                            <TableCell>{rateCompany}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                        {isChangedRemarks ? (
                          <TableRow>
                            <TableCell>Remarks</TableCell>
                            <TableCell>{rowInfo.remarks}</TableCell>
                            <TableCell>{remarks}</TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid container my={2} justifyContent="center">
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  <Typography sx={{ m: 1 }} variant="body1">
                    変更内容を確認
                  </Typography>
                </Grid>
              </>
            ) : (
              ""
            )}
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit" disabled={!checked || !isChanged}>
                更新
              </Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
