import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import Buffer from "exceljs/index";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DialogActions, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ImportedPerformanceHistoryData } from "../../templates/Performance";
import { useGet } from "../../../hooks/useGet";
import { SnackBarContext } from "../../../Context/SnackBarContext";

export interface PerformancesData {
  id: number;
  fundCode: string;
  fundName: string;
  ymd: string;
  classSeries: string;
  currency: number;
  gav: string;
  nav: string;
  monthlyRtnGav: string;
  monthlyRtnNav: string;
  message: string;
}

interface Input {
  fundCode: string;
  ym: string;
}

// nullだった場合にバリデーションエラー
const yupNotNull = yup.string().typeError("入力は必須です");
const schema = yup.object({
  fundCode: yupNotNull,
  ym: yupNotNull,
});

const columnsMapKeys = [
  { header: "Fund Code", key: "fundCode" },
  { header: "Balance Date", key: "ymd" },
  { header: "Class/Series", key: "classSeries" },
  { header: "Currency", key: "currency" },
  { header: "GAV", key: "gav" },
  { header: "NAV", key: "nav" },
  { header: "Monthly Rtn GAV", key: "monthlyRtnGav" },
  { header: "Monthly Rtn NAV", key: "monthlyRtnNav" },
];

interface Props {
  rows: ImportedPerformanceHistoryData[];
  exportType: string;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ExportPerformancesExcelAndCsvButton(props: Props) {
  const { rows, exportType, setResMsg, setIsSuccess } = props;
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [open, setOpen] = useState(false);

  if (exportType !== "csv" && exportType !== "xlsx") {
    throw new Error("exportType is incorrect!");
  }

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  const fundCodeArray: string[] = [];
  const ymdArray: string[] = [];

  rows.forEach((row: ImportedPerformanceHistoryData) => {
    fundCodeArray.push(row.fundCode);
    ymdArray.push(row.ymd);
  });

  // 重複、空文字を除外して配列を作成
  const uniqueFundCodeArray = fundCodeArray.filter(
    (x: string, i: number, self: string[]) =>
      x !== "" ? self.indexOf(x) === i : ""
  );

  const uniqueYmd = ymdArray.filter((x: string, i: number, self: string[]) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const noCondition = "全件";

  // 先頭に「全件」という選択肢を追加
  uniqueFundCodeArray.unshift(noCondition);
  uniqueYmd.unshift(noCondition);

  const { getRequest } = useGet<PerformancesData[]>(
    `/performances/export`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("PerformancesList", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      // 全件取得の場合、クエリパラメータすら作りたくない
      let param = "";
      if (condition.fundCode && condition.fundCode !== noCondition) {
        param += `fundCode=${condition.fundCode}`;
      }
      if (condition.ym && condition.ym !== noCondition) {
        // 単月分取得のため、fromYmdに前月のパラメータを代入する
        param += `&fromYmd=${dayjs(condition.ym)
          .startOf("month")
          .format("YYYY-MM-DD")}`;
        param += `&toYmd=${dayjs(condition.ym)
          .endOf("month")
          .format("YYYY-MM-DD")}`;
      }

      const performancesData = await getRequest(param);
      if (!performancesData) {
        return;
      }

      performancesData.forEach((row: PerformancesData) => {
        worksheet.addRow({
          fundCode: row.fundCode,
          fundName: row.fundName,
          ymd: dayjs(row.ymd).format("YYYY/MM/DD"), // Goのtime.Time型が返されるのでフォーマット
          classSeries: row.classSeries,
          currency: row.currency,
          gav: row.gav,
          nav: row.nav,
          monthlyRtnGav: row.monthlyRtnGav,
          monthlyRtnNav: row.monthlyRtnNav,
        });
      });

      // CSVファイルまたはExcelファイルを生成
      let uint8Array: Buffer;

      if (exportType === "csv") {
        uint8Array = await workbook.csv.writeBuffer();
      } else {
        // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
        // セルを青色に変更
        const fillStylesBlue: ExcelJS.Fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };

        worksheet.getCell("A1").fill = fillStylesBlue;
        worksheet.getCell("B1").fill = fillStylesBlue;
        worksheet.getCell("C1").fill = fillStylesBlue;
        worksheet.getCell("D1").fill = fillStylesBlue;
        worksheet.getCell("E1").fill = fillStylesBlue;
        worksheet.getCell("F1").fill = fillStylesBlue;
        worksheet.getCell("G1").fill = fillStylesBlue;
        worksheet.getCell("H1").fill = fillStylesBlue;

        // フォントを白色に変更
        worksheet.getRow(1).font = {
          color: { argb: "FFFFFF" },
        };

        // 幅調整
        worksheet.getColumn(1).width = 15;
        worksheet.getColumn(2).width = 15;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 15;
        worksheet.getColumn(5).width = 30;
        worksheet.getColumn(6).width = 30;
        worksheet.getColumn(7).width = 30;
        worksheet.getColumn(8).width = 30;

        uint8Array = await workbook.xlsx.writeBuffer();
      }

      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `PerformancesList_${now}.${exportType}`;
      link.click();

      setOpen(false);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<DownloadIcon />}
        sx={{ mr: 5 }}
        onClick={handleClickOpen}
      >
        {exportType === "csv" ? "CSV" : "Excel"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>絞り込み</DialogTitle>
          <DialogContent>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="fundCode"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueFundCodeArray}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={noCondition}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"fundCode" in errors}
                          helperText={errors.fundCode?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="ym"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueYmd}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={noCondition}
                      renderInput={(params) => (
                        <TextField
                          label="Balance Date"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"ym" in errors}
                          helperText={errors.ym?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmit)}>エクスポート</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
