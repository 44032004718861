import * as React from "react";
import { Dispatch, SetStateAction } from "react";
import { Button, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDelete } from "../../../hooks/useDelete";

interface Props {
  endpoint: string;
  id: number;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setOpenEditDialog: Dispatch<SetStateAction<boolean>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function DeleteCommonModalInEdit(props: Props) {
  const {
    id,
    render,
    setRender,
    setResMsg,
    endpoint,
    setOpenEditDialog,
    setIsSuccess,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { deleteRequest } = useDelete(
    endpoint,
    id,
    setResMsg,
    setOpen,
    setRender,
    render,
    setIsSuccess,
    setOpenEditDialog
  );

  return (
    <div>
      <IconButton onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>ご確認ください</DialogTitle>
        <DialogContent>
          <DialogContentText>
            本当に削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            キャンセル
          </Button>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button onClick={deleteRequest}>削除</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
