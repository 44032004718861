import { Dispatch, SetStateAction, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ErrorResponse } from "../components/templates/Transaction";
import { SnackBarContext } from "../Context/SnackBarContext";

export const useDelete = (
  endPoint: string,
  id: number,
  setResMsg: Dispatch<SetStateAction<string>>,
  setOpen: Dispatch<SetStateAction<boolean>>,
  setRender: Dispatch<SetStateAction<boolean>>,
  render: boolean,
  setIsSuccess: Dispatch<SetStateAction<boolean>>,
  setOpenEditDialog?: Dispatch<SetStateAction<boolean>>
) => {
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const { getAccessTokenSilently } = useAuth0();

  const unknownErrMsg =
    "意図しないエラーが発生しました。管理者に連絡してください。";

  const handleClose = () => {
    setOpen(false);
  };

  const deleteRequest = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL || ""}${endPoint}/${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (res.ok) {
        setIsSuccess(true);
        setResMsg("処理に成功しました。");
        setShowSnackBars(!showSnackBars);
        handleClose();
        setRender(!render);

        if (setOpenEditDialog) {
          setOpenEditDialog(false);
        }
      } else if (res.status === 400) {
        // BEからエラーJSONが返された場合
        const errorJson = (await res.json()) as ErrorResponse;
        setIsSuccess(false);
        setResMsg(errorJson.message);
        setShowSnackBars(!showSnackBars);
        handleClose();
      } else {
        // そもそもBEまで届いていない場合
        setIsSuccess(false);
        setResMsg(unknownErrMsg);
        setShowSnackBars(!showSnackBars);
        handleClose();
      }
    } catch (e) {
      // その他、意図しないエラー
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${unknownErrMsg}${e}`);
      setShowSnackBars(!showSnackBars);
      handleClose();
    }
  };

  return { deleteRequest };
};
