import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import ForeignDiscretionaryClientInfoDialog from "../organisms/foreignDiscretionaryClient/ForeignDiscretionaryClientInfoDialog";
import { ForeignDiscretionaryClientBar } from "../molecules/foreignDiscretionaryClient/ForeignDiscretionaryClientBar";
import { FundData } from "./Fund";

export interface ForeignDiscretionaryClientData {
  id: number;
  fund: FundData;
  currentAddress: string;
  historicalAddressMemo: string;
  addressee1: string;
  mail1: string;
  addressee2: string;
  mail2: string;
  addressee3: string;
  mail3: string;
  remarks: string;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "顧客名",
  },
  {
    id: "closingDate",
    label: "契約終了日",
  },
];

export default function ForeignDiscretionaryClientTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState<ForeignDiscretionaryClientData[]>([]);
  const [render, setRender] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // 選択肢に使用するものを取得
  const { data: funds } = useFetch<FundData[]>(
    `/funds`,
    null,
    setIsSuccess,
    setResMsg
  );

  const emptyRow: ForeignDiscretionaryClientData = {
    id: 0,
    fund: {} as FundData,
    currentAddress: "",
    historicalAddressMemo: "",
    addressee1: "",
    mail1: "",
    addressee2: "",
    mail2: "",
    addressee3: "",
    mail3: "",
    remarks: "",
  };

  const [rowInfo, setRowInfo] =
    useState<ForeignDiscretionaryClientData>(emptyRow);

  // 投一(海外)一覧を取得
  const { data: fdcList } = useFetch<ForeignDiscretionaryClientData[]>(
    `/foreign_discretionary_clients`,
    render,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    setRows(fdcList);
  }, [fdcList]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const showForeignDiscretionaryClientInfoDialog = (
    row: ForeignDiscretionaryClientData
  ) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <ForeignDiscretionaryClientBar
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        funds={funds}
        setIsSuccess={setIsSuccess}
      />
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={headCell.id}
                    align="center"
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={() =>
                      showForeignDiscretionaryClientInfoDialog(row)
                    }
                    key={row.id}
                  >
                    <TableCell align="center">{row.fund.code}</TableCell>
                    <TableCell align="center">{row.fund.name}</TableCell>
                    <TableCell align="center">
                      {row.fund.closingDate
                        ? dayjs(row.fund.closingDate).format("YYYY/MM/DD")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <ForeignDiscretionaryClientInfoDialog
          open={open}
          setOpen={setOpen}
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          rowInfo={rowInfo}
          funds={funds}
          setIsSuccess={setIsSuccess}
        />
      </Paper>
    </Box>
  );
}
