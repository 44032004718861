import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import ExportDomesticDiscretionaryClientsExcelButton from "./ExportDomesticDiscretionaryClientsExcelButton";
import AddDomesticDiscretionaryClientDialog from "../../organisms/domesticDiscretionaryClient/AddDomesticDiscretionaryClientDialog";
import { InvestorData } from "../../templates/Investor";
import { NameTableData } from "../../templates/TrusteeBank";

export interface DomesticDiscretionaryClientProps {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  investors: InvestorData[];
  trusteeBanks: NameTableData[];
  reTrusteeBanks: NameTableData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function DomesticDiscretionaryClientBar(
  props: DomesticDiscretionaryClientProps
) {
  const {
    render,
    setRender,
    setResMsg,
    investors,
    setIsSuccess,
    trusteeBanks,
    reTrusteeBanks,
  } = props;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: 1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        投資一任契約顧客(国内)
      </Typography>
      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportDomesticDiscretionaryClientsExcelButton
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <AddDomesticDiscretionaryClientDialog
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          investors={investors}
          trusteeBanks={trusteeBanks}
          reTrusteeBanks={reTrusteeBanks}
          setIsSuccess={setIsSuccess}
        />
      </Box>
    </Box>
  );
}
