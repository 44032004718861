import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DialogActions, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Buffer from "exceljs/index";
import { ImportedBalanceHistoryData } from "../../templates/Balance";
import { useGet } from "../../../hooks/useGet";
import { SnackBarContext } from "../../../Context/SnackBarContext";

interface BalancesData {
  id: number;
  fundCode: string;
  fundName: string;
  ymd: string;
  classSeries: string;
  accountNumber: string;
  currency: string;
  quantity: string;
  nav: string;
  navShare: string;
  rate: string;
  convertedNavInJpy: string;
  convertedNavInUsd: string;
  investorId: number;
  investorName: string;
  investorTypeName: string;
  investorCountryName: string;
  message: string;
}

interface Input {
  fundCode: string;
  ym: string;
}

// nullだった場合にバリデーションエラー
const yupNotNull = yup.string().typeError("入力は必須です");
const schema = yup.object({
  fundCode: yupNotNull,
  ym: yupNotNull,
});

const columnsMapKeys = [
  { header: "Investor ID", key: "investorId" },
  { header: "Balance Date", key: "ymd" },
  { header: "Fund Code", key: "fundCode" },
  { header: "Account ID", key: "accountNumber" },
  { header: "Class/Series", key: "classSeries" },
  { header: "Currency", key: "currency" },
  { header: "Quantity", key: "quantity" },
  { header: "NAV/Share", key: "navShare" },
  { header: "NAV", key: "nav" },
  { header: "FX", key: "rate" },
  { header: "USD", key: "convertedNavInUsd" },
  { header: "JPY", key: "convertedNavInJpy" },
  { header: "Type", key: "investorTypeName" },
  { header: "Country", key: "investorCountryName" },
  { header: "Investor Name", key: "investorName" },
];

interface Props {
  rows: ImportedBalanceHistoryData[];
  exportType: string;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
}

export default function ExportBalancesExcelAndCsvButton(props: Props) {
  const { rows, exportType, setIsSuccess, setResMsg } = props;
  const [open, setOpen] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  if (exportType !== "csv" && exportType !== "excel") {
    throw new Error("exportType is incorrect!");
  }

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  const fundCodeArray: string[] = [];
  const ymdArray: string[] = [];

  rows.forEach((row: ImportedBalanceHistoryData) => {
    fundCodeArray.push(row.fundCode);
    ymdArray.push(row.ymd);
  });

  // 重複、空文字を除外して配列を作成
  const uniqueFundCodeArray = fundCodeArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const uniqueYmd = ymdArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  const noCondition = "全件";

  // 先頭に「全件」という選択肢を追加
  uniqueFundCodeArray.unshift(noCondition);
  uniqueYmd.unshift(noCondition);

  const { getRequest } = useGet<BalancesData[]>(
    `/balances/export`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("BalancesList", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      // 全件取得の場合、クエリパラメータすら作りたくない
      let param = "";
      if (condition.fundCode && condition.fundCode !== noCondition) {
        param += `fundCode=${condition.fundCode}`;
      }
      if (condition.ym && condition.ym !== noCondition) {
        param += `&ym=${dayjs(condition.ym).format("YYYY-MM")}`;
      }

      const balancesData = await getRequest(param);
      if (!balancesData) {
        return;
      }

      balancesData.forEach((row: BalancesData) => {
        worksheet.addRow({
          fundCode: row.fundCode,
          fundName: row.fundName,
          ymd: row.ymd.replaceAll("-", "/"), // 日付をスラッシュ区切りにリプレイス
          accountNumber: row.accountNumber,
          classSeries: row.classSeries,
          currency: row.currency,
          quantity: row.quantity,
          nav: row.nav,
          navShare: row.navShare,
          rate: row.rate,
          convertedNavInJpy: row.convertedNavInJpy,
          convertedNavInUsd: row.convertedNavInUsd,
          investorId: row.investorId,
          investorName: row.investorName,
          investorTypeName: row.investorTypeName,
          investorCountryName: row.investorCountryName,
        });
      });

      // CSVファイルまたはExcelファイルを生成
      let uint8Array: Buffer;

      if (exportType === "csv") {
        uint8Array = await workbook.csv.writeBuffer();
      } else {
        // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
        // セルを青色に変更
        worksheet.getRow(1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };

        // フォントを白色に変更
        worksheet.getRow(1).font = {
          color: { argb: "FFFFFF" },
        };

        uint8Array = await workbook.xlsx.writeBuffer();
      }

      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      if (exportType === "csv") {
        link.download = `BalancesList_${now}.csv`;
      } else {
        link.download = `BalancesList_${now}.xlsx`;
      }

      link.click();

      setOpen(false);
    } catch (e) {
      // 意図しないエラーが発生した場合
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<DownloadIcon />}
        sx={{ mr: 5 }}
        onClick={handleClickOpen}
      >
        {exportType === "csv" ? "CSV" : "Excel"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>絞り込み条件</DialogTitle>
          <DialogContent>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid item mx={2} sx={{ width: 200 }}>
                <Controller
                  name="fundCode"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueFundCodeArray}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={noCondition}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"fundCode" in errors}
                          helperText={errors.fundCode?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={2} sx={{ width: 200 }}>
                <Controller
                  name="ym"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueYmd}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={noCondition}
                      renderInput={(params) => (
                        <TextField
                          label="Balance Date"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"ym" in errors}
                          helperText={errors.ym?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button onClick={handleSubmit(onSubmit)}>エクスポート</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
