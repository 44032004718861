import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AddInvestorDialog from "../../organisms/investor/AddInvestorDialog";
import ExportInvestorsExcelButton from "./ExportInvestorsExcelButton";
import { NameTableData } from "../../templates/TrusteeBank";

export interface InvestorProps {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  countries: NameTableData[];
  types: NameTableData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function InvestorBar(props: InvestorProps) {
  const { render, setRender, setResMsg, countries, types, setIsSuccess } =
    props;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: 1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        Investor
      </Typography>
      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportInvestorsExcelButton
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <AddInvestorDialog
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          countries={countries}
          types={types}
          setIsSuccess={setIsSuccess}
        />
      </Box>
    </Box>
  );
}
