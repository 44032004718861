import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { TransactionData } from "../../templates/Transaction";
import { InvestorData } from "../../templates/Investor";
import { FundData } from "../../templates/Fund";
import { usePut } from "../../../hooks/usePut";
import DeleteCommonModalInEdit from "../common/DeleteCommonModalInEdit";
import { usePost } from "../../../hooks/usePost";

export interface TransactionDataForApiRequest {
  id: number;
  investor: InvestorData;
  fund: FundData;
  tradeDate: string;
  accountNumber: number;
  classSeries: string;
  currency: string;
  quantity: string;
  nav: string;
  navShare: string;
  action: string;
  discretionalContractDate: string;
  fundRedSubApplicationDate: string;
  discretionaryCode: string;
  discretionaryContract: number;
}

const schema = yup.object({
  investor: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  fund: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  accountNumber: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  classSeries: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
  currency: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  quantity: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
  navShare: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
  nav: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
  action: yup.string().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  rowInfo: TransactionData;
  investors: InvestorData[];
  funds: FundData[];
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function EditTransactionDialog(props: Props) {
  const {
    open,
    setOpen,
    render,
    rowInfo,
    investors,
    funds,
    setResMsg,
    setRender,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [investor, setInvestor] = useState<InvestorData | null>(null);
  const [tradeDate, setTradeDate] = useState<string | null>(null);
  const [fund, setFund] = useState<FundData | null>(null);
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [classSeries, setClassSeries] = useState<string | null>(null);
  const [currency, setCurrency] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<string | null>(null);
  const [navShare, setNavShare] = useState<string | null>(null);
  const [nav, setNav] = useState<string | null>(null);
  const [action, setAction] = useState<string | null>(null);
  const [discretionalContractDate, setDiscretionalContractDate] = useState<
    string | null
  >(null);
  const [fundRedSubApplicationDate, setFundRedSubApplicationDate] = useState<
    string | null
  >(null);

  const resetValues = () => {
    setChecked(false);
    setInvestor(null);
    setTradeDate(null);
    setFund(null);
    setAccountNumber(null);
    setClassSeries(null);
    setCurrency(null);
    setQuantity(null);
    setNavShare(null);
    setNav(null);
    setAction(null);
    setDiscretionalContractDate(null);
    setFundRedSubApplicationDate(null);
  };

  const handleClose = () => {
    reset();
    resetValues();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TransactionDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/transaction",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const { postRequest } = usePost(
    "/transaction",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<TransactionDataForApiRequest> = async (
    data
  ) => {
    // BEとの取り決めで、"0000-01-01"という文字列をJSONに渡すことでNullアップデート
    const tmpTradeDate = data.tradeDate === "" ? "0000-01-01" : data.tradeDate;

    const tmpDiscretionalContractDate =
      data.discretionalContractDate === ""
        ? "0000-01-01"
        : data.discretionalContractDate;

    const tmpFundRedSubApplicationDate =
      data.fundRedSubApplicationDate === ""
        ? "0000-01-01"
        : data.fundRedSubApplicationDate;

    const body = JSON.stringify({
      investorId: data.investor?.id,
      fundId: data.fund?.id,
      tradeDate: tmpTradeDate,
      accountNumber: data.accountNumber,
      classSeries: data.classSeries,
      currency: data.currency === "未選択" ? "" : data.currency,
      quantity: data.quantity, // バックエンドとの取り決めで、NULL UPDATEしたい場合は空文字を渡す
      navShare: data.navShare, // バックエンドとの取り決めで、NULL UPDATEしたい場合は空文字を渡す
      nav: data.nav, // バックエンドとの取り決めで、NULL UPDATEしたい場合は空文字を渡す
      action: data.action === "未選択" ? "" : data.action,
      discretionalContractDate: tmpDiscretionalContractDate,
      fundRedSubApplicationDate: tmpFundRedSubApplicationDate,
    });

    await putRequest(body);
  };

  const cloneRequest = async () => {
    // 新規登録する場合は、defaultValueから変更していない場合undefinedになってしまうので、defaultValueをJSONに渡す
    const body = JSON.stringify({
      investorId: rowInfo.investor?.id,
      tradeDate: rowInfo.tradeDate,
      fundId: rowInfo.fund?.id,
      accountNumber: rowInfo.accountNumber,
      classSeries: rowInfo.classSeries,
      currency: rowInfo.currency,
      quantity: rowInfo.quantity,
      navShare: rowInfo.navShare,
      nav: rowInfo.nav,
      action: rowInfo.action,
      discretionalContractDate: rowInfo.discretionalContractDate,
      fundRedSubApplicationDate: rowInfo.fundRedSubApplicationDate,
    });

    await postRequest(body);
    handleClose();
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedInvestor =
    investor !== null && investor.id !== rowInfo.investor.id;

  const isChangedTradeDate =
    tradeDate !== null &&
    !(tradeDate === "" && rowInfo.tradeDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    tradeDate !== dayjs(rowInfo.tradeDate).format("YYYY-MM-DD");

  const isChangedFund = fund !== null && fund.id !== rowInfo.fund.id;

  const isChangedAccountNumber =
    accountNumber !== null && accountNumber !== rowInfo.accountNumber;

  const isChangedClassSeries =
    classSeries !== null && classSeries !== rowInfo.classSeries;

  const isChangedCurrency =
    currency !== null && currency.replace("未選択", "") !== rowInfo.currency;

  const isChangedQuantity =
    quantity !== null && Number(quantity) !== Number(rowInfo.quantity);

  const isChangedNavShare =
    navShare !== null && Number(navShare) !== Number(rowInfo.navShare);

  const isChangedNav = nav !== null && Number(nav) !== Number(rowInfo.nav);

  const isChangedAction =
    action !== null && action.replace("未選択", "") !== rowInfo.action;

  const isChangedDiscretionalContractDate =
    discretionalContractDate !== null &&
    !(
      discretionalContractDate === "" &&
      rowInfo.discretionalContractDate === null
    ) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    discretionalContractDate !==
      dayjs(rowInfo.discretionalContractDate).format("YYYY-MM-DD");

  const isChangedFundRedSubApplicationDate =
    fundRedSubApplicationDate !== null &&
    !(
      fundRedSubApplicationDate === "" &&
      rowInfo.fundRedSubApplicationDate === null
    ) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    fundRedSubApplicationDate !==
      dayjs(rowInfo.fundRedSubApplicationDate).format("YYYY-MM-DD");

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedInvestor ||
    isChangedTradeDate ||
    isChangedFund ||
    isChangedAccountNumber ||
    isChangedClassSeries ||
    isChangedCurrency ||
    isChangedQuantity ||
    isChangedNavShare ||
    isChangedNav ||
    isChangedAction ||
    isChangedDiscretionalContractDate ||
    isChangedFundRedSubApplicationDate;

  const currencyNameList = ["未選択", "USD", "JPY"];
  const actionNameList = ["未選択", "B", "S", "T+", "T-", "D"];

  return (
    <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          mb={1}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <DialogTitle>詳細編集</DialogTitle>
          <DeleteCommonModalInEdit
            endpoint="/transaction"
            id={rowInfo.id}
            render={render}
            setRender={setRender}
            setOpenEditDialog={setOpen}
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
        <DialogContent>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={3}>
              <Controller
                name="investor"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={investors}
                    getOptionLabel={(ivs: InvestorData) =>
                      `id: ${ivs.id}, name: ${ivs.name}, code: ${ivs.discretionaryCode}`
                    }
                    defaultValue={rowInfo.investor}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setInvestor(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Investor ID, Name, Discretionary Code"
                        variant="standard"
                        error={"investor" in errors}
                        helperText={errors.investor?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item mx={1} sx={{ width: 300 }}>
              <Stack>
                <Controller
                  name="tradeDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Trade Date"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.tradeDate
                          ? dayjs(rowInfo.tradeDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setTradeDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="fund"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={funds}
                    getOptionLabel={(f: FundData) => f.code}
                    defaultValue={
                      rowInfo.fund.id === 0
                        ? ({ code: "未選択" } as FundData)
                        : rowInfo.fund
                    }
                    onChange={(_, data) => {
                      field.onChange(data);
                      setFund(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Fund Code"
                        variant="standard"
                        error={"fund" in errors}
                        helperText={errors.fund?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="accountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="Account ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.accountNumber}
                    error={"accountNumber" in errors}
                    helperText={errors.accountNumber?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setAccountNumber(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={3}>
              <Controller
                name="classSeries"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="Class/Series"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.classSeries}
                    error={"classSeries" in errors}
                    helperText={errors.classSeries?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setClassSeries(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="currency"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={currencyNameList}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setCurrency(data);
                      setChecked(false);
                    }}
                    defaultValue={rowInfo.currency || "未選択"}
                    renderInput={(params) => (
                      <TextField
                        label="Currency"
                        variant="standard"
                        error={"currency" in errors}
                        helperText={errors.currency?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="quantity"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="Quantity"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.quantity}
                    error={"quantity" in errors}
                    helperText={errors.quantity?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setQuantity(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="navShare"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="NAV/Share"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.navShare}
                    error={"navShare" in errors}
                    helperText={errors.navShare?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setNavShare(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item xs={2}>
              <Controller
                name="nav"
                control={control}
                render={({ field }) => (
                  <TextField
                    sx={{ mb: 1 }}
                    label="NAV"
                    type="text"
                    fullWidth
                    variant="standard"
                    defaultValue={rowInfo.nav}
                    error={"nav" in errors}
                    helperText={errors.nav?.message}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      setNav(e.target.value);
                      setChecked(false);
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Controller
                name="action"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={actionNameList}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setAction(data);
                      setChecked(false);
                    }}
                    defaultValue={rowInfo.action || "未選択"}
                    renderInput={(params) => (
                      <TextField
                        label="Action"
                        variant="standard"
                        error={"action" in errors}
                        helperText={errors.action?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={2}>
              <Stack>
                <Controller
                  name="discretionalContractDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Discretionary Contract Date"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.discretionalContractDate
                          ? dayjs(rowInfo.discretionalContractDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setDiscretionalContractDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack>
                <Controller
                  name="fundRedSubApplicationDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Fund Red/Sub Application Date"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.fundRedSubApplicationDate
                          ? dayjs(rowInfo.fundRedSubApplicationDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      error={Boolean(errors.fundRedSubApplicationDate)}
                      helperText={errors.fundRedSubApplicationDate?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setFundRedSubApplicationDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                  <Table sx={{ width: "90%", mx: "auto" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedInvestor ? (
                        <TableRow>
                          <TableCell>Investor ID</TableCell>
                          <TableCell>{rowInfo.investor.id}</TableCell>
                          <TableCell>{investor?.id}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedInvestor ? (
                        <TableRow>
                          <TableCell>Discretionary Code</TableCell>
                          <TableCell>
                            {rowInfo.investor.discretionaryCode}
                          </TableCell>
                          <TableCell>{investor?.discretionaryCode}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedTradeDate ? (
                        <TableRow>
                          <TableCell>Trade Date</TableCell>
                          <TableCell>{rowInfo.tradeDate}</TableCell>
                          <TableCell>
                            {tradeDate?.replaceAll("-", "/")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedFund ? (
                        <TableRow>
                          <TableCell>Fund Code</TableCell>
                          <TableCell>{rowInfo.fund.code}</TableCell>
                          <TableCell>{fund?.code}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAccountNumber ? (
                        <TableRow>
                          <TableCell>Account ID</TableCell>
                          <TableCell>{rowInfo.accountNumber}</TableCell>
                          <TableCell>{accountNumber}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedClassSeries ? (
                        <TableRow>
                          <TableCell>Class/Series</TableCell>
                          <TableCell>{rowInfo.classSeries}</TableCell>
                          <TableCell>{classSeries}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedCurrency ? (
                        <TableRow>
                          <TableCell>Currency</TableCell>
                          <TableCell>{rowInfo.currency}</TableCell>
                          <TableCell>{currency}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedQuantity ? (
                        <TableRow>
                          <TableCell>Quantity</TableCell>
                          <TableCell>{rowInfo.quantity}</TableCell>
                          <TableCell>{quantity}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedNavShare ? (
                        <TableRow>
                          <TableCell>NAV/Share</TableCell>
                          <TableCell>{rowInfo.navShare}</TableCell>
                          <TableCell>{navShare}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedNav ? (
                        <TableRow>
                          <TableCell>NAV</TableCell>
                          <TableCell>{rowInfo.nav}</TableCell>
                          <TableCell>{nav}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAction ? (
                        <TableRow>
                          <TableCell>Action</TableCell>
                          <TableCell>{rowInfo.action}</TableCell>
                          <TableCell>{action}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedDiscretionalContractDate ? (
                        <TableRow>
                          <TableCell>Discretional Contract Date</TableCell>
                          <TableCell>
                            {rowInfo.discretionalContractDate}
                          </TableCell>
                          <TableCell>
                            {discretionalContractDate?.replaceAll("-", "/")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedFundRedSubApplicationDate ? (
                        <TableRow>
                          <TableCell>Fund Red/Sub Application Date</TableCell>
                          <TableCell>
                            {rowInfo.fundRedSubApplicationDate}
                          </TableCell>
                          <TableCell>
                            {fundRedSubApplicationDate?.replaceAll("-", "/")}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Typography sx={{ m: 1 }} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid container justifyContent="center" my={1}>
          <DialogActions>
            <Grid item>
              <Button onClick={handleClose}>キャンセル</Button>
              {isChanged ? (
                <Button
                  type="submit"
                  // 変更があって、かつチェックボックスにチェックした場合のみ更新ボタンが活性化
                  disabled={!checked}
                >
                  更新
                </Button>
              ) : (
                <Button
                  variant="text"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={cloneRequest}
                >
                  複製
                </Button>
              )}
            </Grid>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
