import { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { useGet } from "../../hooks/useGet";
import { SnackBarContext } from "../../Context/SnackBarContext";

const columnsMapTypeRawData = [
  { header: "Type", key: "name" },
  { header: "USD", key: "usd" },
  { header: "Ratio", key: "ratio" },
];

const columnsMapCountryRawData = [
  { header: "Country", key: "name" },
  { header: "USD", key: "usd" },
  { header: "Ratio", key: "ratio" },
];

const columnsMapFundRawData = [
  { header: "Fund", key: "name" },
  { header: "USD", key: "usd" },
  { header: "JPY", key: "jpy" },
];

// バリデーションルール
const schema = yup.object({
  fundCode: yup.string().typeError("入力は必須です").required("入力は必須です"),
  ym: yup.string().typeError("入力は必須です").required("入力は必須です"),
});

interface OptionLabels {
  fundCode: string[];
  ym: string[];
}

interface Input {
  fundCode: string;
  ym: string;
}

interface BalancesSegmentData {
  name: string;
  id: number;
  usd: string;
  ratio: string;
  message: string;
}

interface YmAndFundCodeData {
  fundCode: string;
  ymd: string;
  message: string;
}

interface BalancesSegmentFundOutputData {
  code: string;
  name: string;
  usd: string;
  jpy: string;
}

interface BalancesSegmentOneOutputTotalData {
  perType: BalancesSegmentData[];
  perCountry: BalancesSegmentData[];
  fund: BalancesSegmentFundOutputData;
}

export default function ReportSegmentOneTemplate() {
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [segmentOneReportData, setSegmentOneReportData] =
    useState<BalancesSegmentOneOutputTotalData>();

  const [options, setOptions] = useState<OptionLabels>({} as OptionLabels);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  // 選択肢に出すための値を取得
  const { data: ymAndFundCodeData } = useFetch<YmAndFundCodeData[]>(
    `/balances/export`,
    null,
    setIsSuccess,
    setResMsg
  );

  // 選択肢を生成
  useEffect(() => {
    const fundCode: string[] = [];
    const ymArray: string[] = [];

    ymAndFundCodeData.forEach((row: YmAndFundCodeData) => {
      fundCode.push(`${row.fundCode}`);
      ymArray.push(dayjs(row.ymd).format("YYYY/MM"));
    });

    // 重複、空文字を除外して配列を作成
    const suggestingFundCode: string[] = fundCode.filter((x, i, self) =>
      x !== "" ? self.indexOf(x) === i : ""
    );

    const suggestingYm: string[] = ymArray.filter((x, i, self) =>
      x !== "" ? self.indexOf(x) === i : ""
    );

    const output: OptionLabels = {
      fundCode: suggestingFundCode,
      ym: suggestingYm,
    };
    setOptions(output);
  }, [ymAndFundCodeData]);

  const { getRequest } = useGet<BalancesSegmentOneOutputTotalData>(
    `/balances/segment_one`,
    setIsSuccess,
    setResMsg
  );

  const getParam = (fundCode: string, ymStr: string): string[] => {
    const ym = dayjs(ymStr).format("YYYY-MM");

    // 全件取得の場合、クエリパラメータすら作りたくない
    let param = "";
    if (fundCode) {
      param += `fundCode=${fundCode}`;
    }
    if (ymStr) {
      param += `&ym=${ym}`;
    }

    return [param, fundCode, ym];
  };

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      const [param] = getParam(condition.fundCode, condition.ym);

      const SegmentOneReportData = await getRequest(param);
      if (!SegmentOneReportData) {
        return;
      }

      setSegmentOneReportData(SegmentOneReportData);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const exportExcel: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // データ取得
      const [param, fundCode, ym] = getParam(condition.fundCode, condition.ym);

      const SegmentOneReportData = await getRequest(param);
      if (!SegmentOneReportData) {
        return;
      }

      //
      // Typeのワークシートを作成する
      //

      const worksheetOfType = workbook.addWorksheet("TypeData", {});

      // カラムとJSONキーのマッピング
      worksheetOfType.columns = columnsMapTypeRawData;

      SegmentOneReportData.perType.forEach((row: BalancesSegmentData) => {
        worksheetOfType.addRow(row);
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() => worksheetOfType.insertRow(1, []));

      // 基本情報を入力
      worksheetOfType.getCell("A2").value = "Balance Date";
      worksheetOfType.getCell("A3").value = "Fund";

      worksheetOfType.getCell("B2").value = ym;
      worksheetOfType.getCell("B3").value = fundCode;

      // セルを青色に変更
      const fillStylesBlue: ExcelJS.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "223a70" },
      };

      worksheetOfType.getCell("A6").fill = fillStylesBlue;
      worksheetOfType.getCell("B6").fill = fillStylesBlue;
      worksheetOfType.getCell("C6").fill = fillStylesBlue;

      // 罫線
      const borderStyles: Partial<ExcelJS.Borders> = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheetOfType.getCell("A2").border = borderStyles;
      worksheetOfType.getCell("A3").border = borderStyles;
      worksheetOfType.getCell("B2").border = borderStyles;
      worksheetOfType.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfType.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfType.getColumn(1).width = 26;
      worksheetOfType.getColumn(2).width = 26;
      worksheetOfType.getColumn(3).width = 26;

      //
      // Countryのワークシートを作成する
      //

      const worksheetOfCountry = workbook.addWorksheet("CountryData", {});

      worksheetOfCountry.columns = columnsMapCountryRawData;

      SegmentOneReportData.perCountry.forEach((row: BalancesSegmentData) => {
        worksheetOfCountry.addRow(row);
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() =>
        worksheetOfCountry.insertRow(1, [])
      );

      // 基本情報を入力
      worksheetOfCountry.getCell("A2").value = "Balance Date";
      worksheetOfCountry.getCell("A3").value = "Fund";

      worksheetOfCountry.getCell("B2").value = ym;
      worksheetOfCountry.getCell("B3").value = fundCode;

      worksheetOfCountry.getCell("A6").fill = fillStylesBlue;
      worksheetOfCountry.getCell("B6").fill = fillStylesBlue;
      worksheetOfCountry.getCell("C6").fill = fillStylesBlue;

      worksheetOfCountry.getCell("A2").border = borderStyles;
      worksheetOfCountry.getCell("A3").border = borderStyles;
      worksheetOfCountry.getCell("B2").border = borderStyles;
      worksheetOfCountry.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfCountry.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfCountry.getColumn(1).width = 26;
      worksheetOfCountry.getColumn(2).width = 26;
      worksheetOfCountry.getColumn(3).width = 26;

      //
      // Fundのワークシートを作成する
      //

      const worksheetOfFund = workbook.addWorksheet("FundData", {});

      worksheetOfFund.columns = columnsMapFundRawData;

      worksheetOfFund.addRow(SegmentOneReportData.fund);

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() => worksheetOfFund.insertRow(1, []));

      // 基本情報を入力
      worksheetOfFund.getCell("A2").value = "Balance Date";
      worksheetOfFund.getCell("A3").value = "Fund";

      worksheetOfFund.getCell("B2").value = ym;
      worksheetOfFund.getCell("B3").value = fundCode;

      worksheetOfFund.getCell("A6").fill = fillStylesBlue;
      worksheetOfFund.getCell("B6").fill = fillStylesBlue;
      worksheetOfFund.getCell("C6").fill = fillStylesBlue;

      worksheetOfFund.getCell("A2").border = borderStyles;
      worksheetOfFund.getCell("A3").border = borderStyles;
      worksheetOfFund.getCell("B2").border = borderStyles;
      worksheetOfFund.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfFund.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfFund.getColumn(1).width = 42;
      worksheetOfFund.getColumn(2).width = 26;
      worksheetOfFund.getColumn(3).width = 26;

      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const conditionYm = dayjs(condition.ym).format("YYYYMM");

      link.download = `ReportSegment_${fundCode}_${conditionYm}.xlsx`;

      link.click();

      setSegmentOneReportData(SegmentOneReportData);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const handleClickCopyType = async (
    row: BalancesSegmentData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.ratio}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Type: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyCountry = async (
    row: BalancesSegmentData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.ratio}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Country: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyFund = async (
    row: BalancesSegmentFundOutputData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.jpy}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Fund: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  return (
    <Box style={{ height: 400, width: "90vw" }} sx={{ mx: "auto" }}>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Typography sx={{ m: 1 }} variant="h5">
        Report Segment One
      </Typography>
      <form>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item m={2} xs={2}>
            <Controller
              name="fundCode"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={options.fundCode || []}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Fund Code"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={Boolean(errors.fundCode)}
                      helperText={errors.fundCode?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item m={2} xs={2}>
            <Controller
              name="ym"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={options.ym || []}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Balance Date"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={Boolean(errors.ym)}
                      helperText={errors.ym?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(onSubmit)}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(exportExcel)}
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {segmentOneReportData ? (
        <Grid container my={2} spacing={4}>
          <Grid item xs={4}>
            <Paper>
              <Table sx={{ width: "95%", m: "auto" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      USD
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Ratio
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {segmentOneReportData.perType.map((row) => (
                    <TableRow
                      hover
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => handleClickCopyType(row)}
                      key={row.name}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Number(row.usd))}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          style: "percent",
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(Number(row.ratio))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Table sx={{ width: "95%", m: "auto" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Country
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      USD
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      Ratio
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {segmentOneReportData.perCountry.map((row) => (
                    <TableRow
                      hover
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => handleClickCopyCountry(row)}
                      key={row.name}
                    >
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Number(row.usd))}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          style: "percent",
                          minimumFractionDigits: 1,
                          maximumFractionDigits: 1,
                        }).format(Number(row.ratio))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper>
              <Table sx={{ width: "95%", m: "auto" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>Fund</TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      USD
                    </TableCell>
                    <TableCell align="right" style={{ fontWeight: "bold" }}>
                      JPY
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    hover
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() =>
                      handleClickCopyFund(segmentOneReportData.fund)
                    }
                  >
                    <TableCell>{segmentOneReportData.fund.name}</TableCell>
                    <TableCell align="center">
                      {Intl.NumberFormat("ja", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(Number(segmentOneReportData.fund.usd))}
                    </TableCell>
                    <TableCell align="center">
                      {Intl.NumberFormat("ja", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      }).format(Number(segmentOneReportData.fund.jpy))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </Box>
  );
}
