import * as React from "react";
import { useContext } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { SnackBarContext } from "../../../Context/SnackBarContext";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  (
    props,
    ref
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
);

interface Snackbars {
  resMsg: string;
  isSuccess: boolean;
}

export default function AlertSnackbars(props: Snackbars) {
  const { resMsg, isSuccess } = props;

  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackBars(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        open={showSnackBars}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={isSuccess ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {resMsg}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
