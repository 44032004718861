import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Typography } from "@mui/material";

export default function LoadingPage() {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "100vh" }}
    >
      <CircularProgress />
      <Typography sx={{ m: 1 }} variant="h6">
        Loading...
      </Typography>
    </Grid>
  );
}
