import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext } from "react";
import { SnackBarContext } from "../../../Context/SnackBarContext";
import { DomesticDiscretionaryClientData } from "../../templates/DomesticDiscretionaryClient";
import { useGet } from "../../../hooks/useGet";

const columnsMapKeys = [
  { header: "アクティブフラグ", key: "isActive" },
  { header: "コード", key: "code" },
  { header: "顧客名", key: "name" },
  { header: "読み方", key: "kana" },
  { header: "契約開始日", key: "contractDate" },
  { header: "当初資産組入日", key: "startingDate" },
  { header: "契約終了日", key: "closingDate" },
  { header: "郵便番号", key: "zipCode" },
  { header: "住所", key: "currentAddress" },
  { header: "住所 (履歴)", key: "historicalAddressMemo" },
  { header: "電話番号", key: "phoneNumber" },
  { header: "宛名1", key: "addressee1" },
  { header: "メールアドレス1", key: "mail1" },
  { header: "宛名2", key: "addressee2" },
  { header: "メールアドレス2", key: "mail2" },
  { header: "宛名3", key: "addressee3" },
  { header: "メールアドレス3", key: "mail3" },
  { header: "宛名4", key: "addressee4" },
  { header: "メールアドレス4", key: "mail4" },
  { header: "Subscription/Redemption メモ", key: "subscriptionRedemptionMemo" },
  { header: "受託信託銀行", key: "trusteeBank" },
  { header: "再受託信託銀行", key: "reTrusteeBank" },
  { header: "口座番号", key: "accountNumber" },
  { header: "備考", key: "remarks" },
];

interface Props {
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ExportDomesticDiscretionaryClientsExcelButton(
  props: Props
) {
  const { setResMsg, setIsSuccess } = props;
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  const { getRequest } = useGet<DomesticDiscretionaryClientData[]>(
    `/domestic_discretionary_clients/export`,
    setIsSuccess,
    setResMsg
  );

  const clickButtonAsync = async () => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet(
        "DomesticDiscretionaryClientList",
        {}
      );

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      const rows = await getRequest("");

      if (!rows) {
        throw new Error("データが見つかりませんでした");
      }

      // データ挿入
      rows.forEach((row: DomesticDiscretionaryClientData) =>
        worksheet.addRow(row)
      );

      // ヘッダを青色に変更
      Array.from({ length: 24 }).forEach((_, i) => {
        worksheet.getCell(1, i + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };
      });

      // ヘッダのフォントを白色に変更
      worksheet.getRow(1).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheet.getColumn(1).width = 15;
      worksheet.getColumn(2).width = 15;
      worksheet.getColumn(3).width = 15;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 15;
      worksheet.getColumn(6).width = 15;
      worksheet.getColumn(7).width = 15;
      worksheet.getColumn(8).width = 15;
      worksheet.getColumn(9).width = 15;
      worksheet.getColumn(10).width = 15;
      worksheet.getColumn(11).width = 15;
      worksheet.getColumn(12).width = 15;
      worksheet.getColumn(13).width = 15;
      worksheet.getColumn(14).width = 15;
      worksheet.getColumn(15).width = 15;
      worksheet.getColumn(16).width = 15;
      worksheet.getColumn(17).width = 15;
      worksheet.getColumn(18).width = 15;
      worksheet.getColumn(19).width = 15;
      worksheet.getColumn(20).width = 15;
      worksheet.getColumn(21).width = 15;
      worksheet.getColumn(22).width = 15;
      worksheet.getColumn(23).width = 15;
      worksheet.getColumn(24).width = 15;

      // エクセルファイルを生成
      const uint8Array = await workbook.xlsx.writeBuffer(); // xlsxの場合
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `DomesticDiscretionaryClientsList_${now}.xlsx`;
      link.click();
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<DownloadIcon />}
      sx={{ mr: 5 }}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={() => clickButtonAsync()}
    >
      Excel
    </Button>
  );
}
