import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ResponsiveDrawer from "./components/templates/Dashboard";

function App() {
  // Auth0
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();
  // isAuthenticatedがfalseの場合、Auth0ログイン画面へリダイレクト。無限レンダリング防止のため条件にisLoadingを追加。
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  return <ResponsiveDrawer />;
}

export default App;
