import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { InvestorData } from "../../templates/Investor";
import {
  DomesticDiscretionaryClientData,
  Label,
} from "../../templates/DomesticDiscretionaryClient";
import { DdcDataForApiRequest } from "./AddDomesticDiscretionaryClientDialog";
import { usePut } from "../../../hooks/usePut";
import DeleteCommonModalInEdit from "../../molecules/common/DeleteCommonModalInEdit";
import { NameTableData } from "../../templates/TrusteeBank";

const schema = yup.object({
  investor: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  mainFund: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  name: yup.string().max(200, "200文字以内で入力してください"),
  kana: yup
    .string()
    .matches(
      /^([\u3040-\u309F0-9]+|)$/, // ひらがなと数字のみ
      "ひらがなまたは半角数字で入力してください"
    )
    .max(400, "400文字以内で入力してください"),
  zipCode: yup
    .string()
    .matches(/^(\d{3}-\d{4}|)$/, "000-0000の形式で入力してください"), // 空文字も許容
  phoneNumber: yup.string().matches(
    /^(\d{2,4}-\d{2,4}-\d{4}|)$/, // ハイフン必須
    "000-0000-0000の形式で入力してください"
  ),
  currentAddress: yup.string().max(1000, "1000文字以内で入力してください"),
  historicalAddressMemo: yup
    .string()
    .max(1000, "1000文字以内で入力してください"),
  addressee1: yup.string().max(400, "400文字以内で入力してください"),
  addressee2: yup.string().max(400, "400文字以内で入力してください"),
  addressee3: yup.string().max(400, "400文字以内で入力してください"),
  addressee4: yup.string().max(400, "400文字以内で入力してください"),
  mail1: yup.string().email("メールアドレスの形式で入力してください"),
  mail2: yup.string().email("メールアドレスの形式で入力してください"),
  mail3: yup.string().email("メールアドレスの形式で入力してください"),
  mail4: yup.string().email("メールアドレスの形式で入力してください"),
  trusteeBank: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  reTrusteeBank: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  accountNumber: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(100, "100文字以内で入力してください"),
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  rowInfo: DomesticDiscretionaryClientData;
  investors: InvestorData[];
  trusteeBanks: NameTableData[];
  reTrusteeBanks: NameTableData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export const MainFunds: Label[] = [
  { id: "K", label: "K:KOTO", message: "" },
  { id: "G", label: "G: GALA", message: "" },
  { id: "V", label: "V: VPLT", message: "" },
  { id: "W", label: "W: KOWA", message: "" },
  {
    id: "M",
    label: "M: IKE1, IKE2, IKE3, IKE4",
    message: "",
  },
  { id: "N", label: "N: NARA", message: "" },
  { id: "H", label: "H: HRLS, HRLO", message: "" },
  { id: "O", label: "O: OUKA", message: "" },
  { id: "S", label: "S: MCSI", message: "" },
];

export default function DomesticDiscretionaryClientInfoDialog(props: Props) {
  const {
    open,
    setOpen,
    render,
    setRender,
    setResMsg,
    rowInfo,
    investors,
    trusteeBanks,
    reTrusteeBanks,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [investor, setInvestor] = useState<InvestorData | null>(null);
  const [mainFund, setMainFund] = useState<Label | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [kana, setKana] = useState<string | null>(null);
  const [contractDate, setContractDate] = useState<string | null>(null);
  const [startingDate, setStartingDate] = useState<string | null>(null);
  const [closingDate, setClosingDate] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [zipCode, setZipCode] = useState<string | null>(null);
  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  const [historicalAddressMemo, setHistoricalAddressMemo] = useState<
    string | null
  >(null);
  const [addressee1, setAddressee1] = useState<string | null>(null);
  const [mail1, setMail1] = useState<string | null>(null);
  const [addressee2, setAddressee2] = useState<string | null>(null);
  const [mail2, setMail2] = useState<string | null>(null);
  const [addressee3, setAddressee3] = useState<string | null>(null);
  const [mail3, setMail3] = useState<string | null>(null);
  const [addressee4, setAddressee4] = useState<string | null>(null);
  const [mail4, setMail4] = useState<string | null>(null);
  const [subscriptionRedemptionMemo, setSubscriptionRedemptionMemo] = useState<
    string | null
  >(null);
  const [trusteeBank, setTrusteeBank] = useState<NameTableData | null>(null);
  const [reTrusteeBank, setReTrusteeBank] = useState<NameTableData | null>(
    null
  );
  const [accountNumber, setAccountNumber] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);

  const resetValues = () => {
    setChecked(false);
    setInvestor(null);
    setMainFund(null);
    setName(null);
    setKana(null);
    setContractDate(null);
    setStartingDate(null);
    setClosingDate(null);
    setIsActive(null);
    setZipCode(null);
    setCurrentAddress(null);
    setPhoneNumber(null);
    setHistoricalAddressMemo(null);
    setAddressee1(null);
    setMail1(null);
    setAddressee2(null);
    setMail2(null);
    setAddressee3(null);
    setMail3(null);
    setAddressee4(null);
    setMail4(null);
    setSubscriptionRedemptionMemo(null);
    setTrusteeBank(null);
    setAccountNumber(null);
    setRemarks(null);
  };

  const handleClose = () => {
    reset();
    resetValues();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DdcDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/domestic_discretionary_client",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const onSubmit: SubmitHandler<DdcDataForApiRequest> = async (
    data: DdcDataForApiRequest
  ) => {
    // BEとの取り決めで、"0000-01-01"という文字列をJSONに渡すことでNullアップデート

    const tmpContractDate =
      data.contractDate === "" ? "0000-01-01" : data.contractDate;

    const tmpStartingDate =
      data.startingDate === "" ? "0000-01-01" : data.startingDate;

    const tmpClosingDate =
      data.closingDate === "" ? "0000-01-01" : data.closingDate;

    const body = JSON.stringify({
      mainFund: data.mainFund?.id || rowInfo.mainFund.id,
      investorId: data.investor?.id || rowInfo.investor.id,
      name: data.name || rowInfo.name,
      kana: data.kana || rowInfo.kana,
      contractDate: tmpContractDate,
      startingDate: tmpStartingDate,
      closingDate: tmpClosingDate,
      zipCode: data.zipCode,
      currentAddress: data.currentAddress,
      historicalAddressMemo: data.historicalAddressMemo,
      phoneNumber: data.phoneNumber,
      addressee1: data.addressee1,
      mail1: data.mail1,
      addressee2: data.addressee2,
      mail2: data.mail2,
      addressee3: data.addressee3,
      mail3: data.mail3,
      addressee4: data.addressee4,
      mail4: data.mail4,
      subscriptionRedemptionMemo: data.subscriptionRedemptionMemo,
      trusteeBankId: data.trusteeBank?.id,
      reTrusteeBankId: data.reTrusteeBank?.id,
      accountNumber: data.accountNumber,
      remarks: data.remarks,
      isActive: data.isActive, // trueまたはfalseの場合はそのbool値に更新し、nilの場合は更新されない
    });

    await putRequest(body);
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedInvestor =
    investor !== null && investor.id !== rowInfo.investor.id;

  const isChangedMainFund = mainFund !== null && mainFund !== rowInfo.mainFund;

  const isChangedName = name !== null && name !== rowInfo.name;

  const isChangedKana = kana !== null && kana !== rowInfo.kana;

  const isChangedContractDate =
    contractDate !== null &&
    !(contractDate === "" && rowInfo.contractDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    contractDate !== dayjs(rowInfo.contractDate).format("YYYY-MM-DD");

  const isChangedStartingDate =
    startingDate !== null &&
    !(startingDate === "" && rowInfo.startingDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    startingDate !== dayjs(rowInfo.startingDate).format("YYYY-MM-DD");

  const isChangedClosingDate =
    closingDate !== null &&
    !(closingDate === "" && rowInfo.closingDate === null) && // 1度何かを入力して取り消した場合に、nullではなく空文字になってしまう
    closingDate !== dayjs(rowInfo.closingDate).format("YYYY-MM-DD");

  const isChangedIsActive = isActive !== null && isActive !== rowInfo.isActive;
  const isChangedZipCode = zipCode !== null && zipCode !== rowInfo.zipCode;
  const isChangedCurrentAddress =
    currentAddress !== null && currentAddress !== rowInfo.currentAddress;
  const isChangedPhoneNumber =
    phoneNumber !== null && phoneNumber !== rowInfo.phoneNumber;
  const isChangedHistoricalAddressMemo =
    historicalAddressMemo !== null &&
    historicalAddressMemo !== rowInfo.historicalAddressMemo;
  const isChangedAddressee1 =
    addressee1 !== null && addressee1 !== rowInfo.addressee1;
  const isChangedMail1 = mail1 !== null && mail1 !== rowInfo.mail1;

  const isChangedAddressee2 =
    addressee2 !== null && addressee2 !== rowInfo.addressee2;
  const isChangedMail2 = mail2 !== null && mail2 !== rowInfo.mail2;

  const isChangedAddressee3 =
    addressee3 !== null && addressee3 !== rowInfo.addressee3;
  const isChangedMail3 = mail3 !== null && mail3 !== rowInfo.mail3;

  const isChangedAddressee4 =
    addressee4 !== null && addressee4 !== rowInfo.addressee4;
  const isChangedMail4 = mail4 !== null && mail4 !== rowInfo.mail4;
  const isChangedSubscriptionRedemptionMemo =
    subscriptionRedemptionMemo !== null &&
    subscriptionRedemptionMemo !== rowInfo.subscriptionRedemptionMemo;
  const isChangedTrusteeBank =
    trusteeBank !== null && trusteeBank.id !== rowInfo.trusteeBank.id;

  const isChangedReTrusteeBank =
    reTrusteeBank !== null && reTrusteeBank.id !== rowInfo.reTrusteeBank.id;

  const isChangedAccountNumber =
    accountNumber !== null && accountNumber !== rowInfo.accountNumber;
  const isChangedRemarks = remarks !== null && remarks !== rowInfo.remarks;

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedInvestor ||
    isChangedMainFund ||
    isChangedName ||
    isChangedKana ||
    isChangedContractDate ||
    isChangedStartingDate ||
    isChangedClosingDate ||
    isChangedIsActive ||
    isChangedZipCode ||
    isChangedCurrentAddress ||
    isChangedPhoneNumber ||
    isChangedHistoricalAddressMemo ||
    isChangedAddressee1 ||
    isChangedMail1 ||
    isChangedAddressee2 ||
    isChangedMail2 ||
    isChangedAddressee3 ||
    isChangedMail3 ||
    isChangedAddressee4 ||
    isChangedMail4 ||
    isChangedSubscriptionRedemptionMemo ||
    isChangedTrusteeBank ||
    isChangedReTrusteeBank ||
    isChangedAccountNumber ||
    isChangedRemarks;

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          mb={1}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <DialogTitle>詳細情報</DialogTitle>
          <DeleteCommonModalInEdit
            endpoint="/domestic_discretionary_client"
            id={rowInfo.id}
            render={render}
            setRender={setRender}
            setOpenEditDialog={setOpen}
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
        <DialogContent>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={3}>
              <Controller
                name="investor"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={investors}
                    getOptionLabel={(ivs) => `${ivs.id}: ${ivs.name}`}
                    defaultValue={rowInfo.investor}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setInvestor(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Investor ID: Investor Name"
                        variant="standard"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={Boolean(errors.investor)}
                        helperText={errors.investor?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item mx={1} xs={2}>
              <Controller
                name="mainFund"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    // 選択肢入力(D)
                    options={MainFunds}
                    defaultValue={rowInfo.mainFund}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setMainFund(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="Main Fund"
                        variant="standard"
                        error={"mainFund" in errors}
                        helperText={errors.mainFund?.message}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="顧客名"
                      variant="standard"
                      defaultValue={rowInfo.name}
                      error={Boolean(errors.name)}
                      helperText={errors.name?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setName(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="kana"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="読み方"
                      variant="standard"
                      defaultValue={rowInfo.kana}
                      error={Boolean(errors.kana)}
                      helperText={errors.kana?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setKana(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="contractDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="契約開始日"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.contractDate
                          ? dayjs(rowInfo.contractDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setContractDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="startingDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="当初資産組入日"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.startingDate
                          ? dayjs(rowInfo.startingDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setStartingDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="closingDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="契約終了日"
                      variant="standard"
                      type="date"
                      InputLabelProps={{ shrink: true }}
                      defaultValue={
                        rowInfo.closingDate
                          ? dayjs(rowInfo.closingDate).format("YYYY-MM-DD")
                          : ""
                      }
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setClosingDate(e.target.value);
                        setChecked(false);
                      }}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={2}>
              <Controller
                name="isActive"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    label="アクティブ"
                    control={
                      <Checkbox
                        defaultChecked={rowInfo.isActive}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                          setIsActive(e.target.checked);
                          setChecked(false);
                        }}
                      />
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="郵便番号"
                      variant="standard"
                      defaultValue={rowInfo.zipCode}
                      error={Boolean(errors.zipCode)}
                      helperText={errors.zipCode?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setZipCode(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="currentAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="住所"
                      variant="standard"
                      defaultValue={rowInfo.currentAddress}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setCurrentAddress(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.currentAddress)}
                      helperText={errors.currentAddress?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="電話番号"
                      variant="standard"
                      defaultValue={rowInfo.phoneNumber}
                      error={Boolean(errors.phoneNumber)}
                      helperText={errors.phoneNumber?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setPhoneNumber(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="space-between">
            <Grid item mx={1} xs={8}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="historicalAddressMemo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="standard-multiline-static"
                      label="住所 (履歴)"
                      multiline
                      rows={3}
                      variant="standard"
                      defaultValue={rowInfo.historicalAddressMemo}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setHistoricalAddressMemo(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.historicalAddressMemo)}
                      helperText={errors.historicalAddressMemo?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名1"
                      variant="standard"
                      defaultValue={rowInfo.addressee1}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee1(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.addressee1)}
                      helperText={errors.addressee1?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス1"
                      variant="standard"
                      defaultValue={rowInfo.mail1}
                      error={Boolean(errors.mail1)}
                      helperText={errors.mail1?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail1(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名2"
                      variant="standard"
                      defaultValue={rowInfo.addressee2}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee2(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.addressee2)}
                      helperText={errors.addressee2?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス2"
                      variant="standard"
                      defaultValue={rowInfo.mail2}
                      error={Boolean(errors.mail2)}
                      helperText={errors.mail2?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail2(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee3"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名3"
                      variant="standard"
                      defaultValue={rowInfo.addressee3}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee3(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.addressee3)}
                      helperText={errors.addressee3?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail3"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス3"
                      variant="standard"
                      defaultValue={rowInfo.mail3}
                      error={Boolean(errors.mail3)}
                      helperText={errors.mail3?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail3(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee4"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名4"
                      variant="standard"
                      defaultValue={rowInfo.addressee4}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee4(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.addressee4)}
                      helperText={errors.addressee4?.message}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail4"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス4"
                      variant="standard"
                      defaultValue={rowInfo.mail4}
                      error={Boolean(errors.mail4)}
                      helperText={errors.mail4?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail4(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={12}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="subscriptionRedemptionMemo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="standard-multiline-static"
                      label="Subscription/Redemptionメモ"
                      multiline
                      rows={4}
                      variant="standard"
                      defaultValue={rowInfo.subscriptionRedemptionMemo}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setSubscriptionRedemptionMemo(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={3}>
              <Controller
                name="trusteeBank"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={trusteeBanks}
                    getOptionLabel={(tb) => tb.name}
                    defaultValue={rowInfo.trusteeBank}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setTrusteeBank(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="受託信託銀行"
                        variant="standard"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={Boolean(errors.trusteeBank)}
                        helperText={errors.trusteeBank?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item mx={1} xs={3}>
              <Controller
                name="reTrusteeBank"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    options={reTrusteeBanks}
                    getOptionLabel={(rtb: NameTableData) => rtb.name}
                    defaultValue={rowInfo.reTrusteeBank}
                    onChange={(_, data) => {
                      field.onChange(data);
                      setReTrusteeBank(data);
                      setChecked(false);
                    }}
                    renderInput={(params) => (
                      <TextField
                        label="再受託信託銀行"
                        variant="standard"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={Boolean(errors.reTrusteeBank)}
                        helperText={errors.reTrusteeBank?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item mx={1} xs={3}>
              <Stack>
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="口座番号"
                      variant="standard"
                      defaultValue={rowInfo.accountNumber}
                      error={Boolean(errors.accountNumber)}
                      helperText={errors.accountNumber?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAccountNumber(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} direction="row" justifyContent="flex-start">
            <Grid item mx={1} xs={12}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="standard-multiline-static"
                      label="備考"
                      multiline
                      rows={4}
                      variant="standard"
                      defaultValue={rowInfo.remarks}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setRemarks(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                  <Table sx={{ width: "90%", mx: "auto" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedInvestor ? (
                        <TableRow>
                          <TableCell>Investor ID</TableCell>
                          <TableCell>{rowInfo.investor.id}</TableCell>
                          <TableCell>{investor?.id}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMainFund ? (
                        <TableRow>
                          <TableCell>Main Fund</TableCell>
                          <TableCell>{rowInfo.mainFund}</TableCell>
                          <TableCell>{mainFund?.id}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedName ? (
                        <TableRow>
                          <TableCell>顧客名</TableCell>
                          <TableCell>{rowInfo.name}</TableCell>
                          <TableCell>{name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedKana ? (
                        <TableRow>
                          <TableCell>読み方</TableCell>
                          <TableCell>{rowInfo.kana}</TableCell>
                          <TableCell>{kana}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedContractDate ? (
                        <TableRow>
                          <TableCell>契約開始日</TableCell>
                          <TableCell>
                            {rowInfo.contractDate
                              ? dayjs(rowInfo.contractDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {contractDate
                              ? dayjs(contractDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedStartingDate ? (
                        <TableRow>
                          <TableCell>当初資産組入日</TableCell>
                          <TableCell>
                            {rowInfo.startingDate
                              ? dayjs(rowInfo.startingDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {startingDate
                              ? dayjs(startingDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedClosingDate ? (
                        <TableRow>
                          <TableCell>契約終了日</TableCell>
                          <TableCell>
                            {rowInfo.closingDate
                              ? dayjs(rowInfo.closingDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                          <TableCell>
                            {closingDate
                              ? dayjs(closingDate).format("YYYY/MM/DD")
                              : ""}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedIsActive ? (
                        <TableRow>
                          <TableCell>アクティブ</TableCell>
                          <TableCell>
                            {rowInfo.isActive ? "アクティブ" : "非アクティブ"}
                          </TableCell>
                          <TableCell>
                            {isActive ? "アクティブ" : "非アクティブ"}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedZipCode ? (
                        <TableRow>
                          <TableCell>郵便番号</TableCell>
                          <TableCell>{rowInfo.zipCode}</TableCell>
                          <TableCell>{zipCode}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedCurrentAddress ? (
                        <TableRow>
                          <TableCell>住所</TableCell>
                          <TableCell>{rowInfo.currentAddress}</TableCell>
                          <TableCell>{currentAddress}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedPhoneNumber ? (
                        <TableRow>
                          <TableCell>電話番号</TableCell>
                          <TableCell>{rowInfo.phoneNumber}</TableCell>
                          <TableCell>{phoneNumber}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedHistoricalAddressMemo ? (
                        <TableRow>
                          <TableCell>住所 (履歴)</TableCell>
                          <TableCell>{rowInfo.historicalAddressMemo}</TableCell>
                          <TableCell>{historicalAddressMemo}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee1 ? (
                        <TableRow>
                          <TableCell>宛名1</TableCell>
                          <TableCell>{rowInfo.addressee1}</TableCell>
                          <TableCell>{addressee1}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail1 ? (
                        <TableRow>
                          <TableCell>メールアドレス1</TableCell>
                          <TableCell>{rowInfo.mail1}</TableCell>
                          <TableCell>{mail1}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee2 ? (
                        <TableRow>
                          <TableCell>宛名2</TableCell>
                          <TableCell>{rowInfo.addressee2}</TableCell>
                          <TableCell>{addressee2}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail2 ? (
                        <TableRow>
                          <TableCell>メールアドレス2</TableCell>
                          <TableCell>{rowInfo.mail2}</TableCell>
                          <TableCell>{mail2}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee3 ? (
                        <TableRow>
                          <TableCell>宛名3</TableCell>
                          <TableCell>{rowInfo.addressee3}</TableCell>
                          <TableCell>{addressee3}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail3 ? (
                        <TableRow>
                          <TableCell>メールアドレス3</TableCell>
                          <TableCell>{rowInfo.mail3}</TableCell>
                          <TableCell>{mail3}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee4 ? (
                        <TableRow>
                          <TableCell>宛名4</TableCell>
                          <TableCell>{rowInfo.addressee4}</TableCell>
                          <TableCell>{addressee4}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail4 ? (
                        <TableRow>
                          <TableCell>メールアドレス4</TableCell>
                          <TableCell>{rowInfo.mail4}</TableCell>
                          <TableCell>{mail4}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedSubscriptionRedemptionMemo ? (
                        <TableRow>
                          <TableCell>Subscription/Redemptionメモ</TableCell>
                          <TableCell>
                            {rowInfo.subscriptionRedemptionMemo}
                          </TableCell>
                          <TableCell>{subscriptionRedemptionMemo}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedTrusteeBank ? (
                        <TableRow>
                          <TableCell>受託信託銀行</TableCell>
                          <TableCell>{rowInfo.trusteeBank.name}</TableCell>
                          <TableCell>{trusteeBank?.name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedReTrusteeBank ? (
                        <TableRow>
                          <TableCell>再受託信託銀行</TableCell>
                          <TableCell>{rowInfo.reTrusteeBank.name}</TableCell>
                          <TableCell>{reTrusteeBank?.name}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAccountNumber ? (
                        <TableRow>
                          <TableCell>口座番号</TableCell>
                          <TableCell>{rowInfo.accountNumber}</TableCell>
                          <TableCell>{accountNumber}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedRemarks ? (
                        <TableRow>
                          <TableCell>備考</TableCell>
                          <TableCell>{rowInfo.remarks}</TableCell>
                          <TableCell>{remarks}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Typography sx={{ m: 1 }} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid container justifyContent="space-around" my={1}>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" disabled={!checked || !isChanged}>
              更新
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
