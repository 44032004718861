import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import EditTransactionDialog from "../molecules/transaction/EditTransactionDialog";
import { InvestorData } from "./Investor";
import { FundData } from "./Fund";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import SearchTransactionRows from "../molecules/transaction/SearchTransactionRows";
import { useFetch } from "../../hooks/useFetch";
import AddTransactionDialog from "../organisms/transaction/AddTransactionDialog";
import ExportTransactionExcelAndCsvButton from "../organisms/transaction/ExportTransactionExcelAndCsvButton";

interface Country {
  id: number;
  name: string;
}

interface Type {
  id: number;
  name: string;
}

export interface TransactionData {
  id: number;
  investor: InvestorData;
  fund: FundData;
  tradeDate: string;
  accountNumber: string;
  classSeries: string;
  currency: string;
  quantity: string;
  nav: string;
  navShare: string;
  action: string;
  type: Type;
  country: Country;
  investorName: string;
  discretionalContractDate: string;
  fundRedSubApplicationDate: string;
  discretionaryContract: number;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  { id: "investorId", label: "InvestorID" },
  { id: "tradeDate", label: "TradeDate" },
  { id: "fundCode", label: "FundCode" },
  { id: "accountNumber", label: "AccountID" },
  { id: "classSeries", label: "Class/Series" },
  { id: "currency", label: "Currency" },
  { id: "quantity", label: "Quantity" },
  { id: "navShare", label: "NAV/Share" },
  { id: "nav", label: "NAV" },
  { id: "action", label: "Action" },
  { id: "type", label: "Type" },
  { id: "country", label: "Country" },
  { id: "investorName", label: "InvestorName" },
  { id: "discretionaryCode", label: "DiscretionaryCode" },
  { id: "discretionalContractDate", label: "DiscretionaryContractDate" },
  { id: "fundRedSubApplicationDate", label: "FundRed/SubApplicationDate" },
];

export interface ErrorResponse {
  code: number;
  message: string;
  detail: string;
}

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align="center"
            sx={{ width: "max-content" }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function TransactionTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [transactions, setTransactions] = useState<TransactionData[]>([]);
  const [funds, setFunds] = useState<FundData[]>([]);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState<TransactionData>(
    {} as TransactionData
  );
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // トランザクション一覧を取得
  const { data: allTransactions } = useFetch<TransactionData[]>(
    `/transactions`,
    render,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    setTransactions(allTransactions);
  }, [allTransactions]);

  // インベスター一覧を取得
  const { data: investors } = useFetch<InvestorData[]>(
    `/investors`,
    null,
    setIsSuccess,
    setResMsg
  );

  // ファンド一覧を取得
  const { data: fundsRaw } = useFetch<FundData[]>(
    `/funds`,
    null,
    setIsSuccess,
    setResMsg
  );

  // ファンド一覧を取得したら未選択項目を追加
  useEffect(() => {
    const addedFunds = [{ id: 0, code: "未選択" } as FundData, ...fundsRaw];
    setFunds(addedFunds);
  }, [fundsRaw]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - transactions.length) : 0;

  const showEditTransactionDialog = (row: TransactionData) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  const formatStr = (t: string) => {
    if (t.length > 10) {
      return `${t.substr(0, 10)}...`;
    }
    return t;
  };

  return (
    <Box style={{ height: 400, width: "90vw" }} sx={{ mx: "auto" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: 1,
          mb: 5,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h5">
          Transaction
        </Typography>
        <Box
          sx={{
            alignItems: "right",
            display: "flex",
          }}
        >
          <ExportTransactionExcelAndCsvButton
            transactions={transactions}
            exportType="csv"
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
            funds={funds}
            investors={investors}
          />
          <ExportTransactionExcelAndCsvButton
            transactions={transactions}
            exportType="xlsx"
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
            funds={funds}
            investors={investors}
          />
          <AddTransactionDialog
            render={render}
            setRender={setRender}
            setResMsg={setResMsg}
            investors={investors}
            funds={funds}
            setIsSuccess={setIsSuccess}
          />
        </Box>
      </Box>
      <Grid mb={4} mx={2}>
        <SearchTransactionRows
          setRows={setTransactions}
          setResMsg={setResMsg}
          funds={funds}
          investors={investors}
          setIsSuccess={setIsSuccess}
        />
      </Grid>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ mb: 2 }}>
        <TableContainer>
          <Table sx={{ width: "auto" }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {transactions
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={() => showEditTransactionDialog(row)}
                    key={row.id}
                  >
                    <TableCell align="center">{row.investor.id} </TableCell>
                    <TableCell align="center">{row.tradeDate || "-"}</TableCell>
                    <TableCell align="center">{row.fund.code} </TableCell>
                    <TableCell align="center">{row.accountNumber} </TableCell>
                    <TableCell align="center">
                      {formatStr(row.classSeries)}{" "}
                    </TableCell>
                    <TableCell align="center">{row.currency} </TableCell>
                    <TableCell align="center">
                      {row.quantity === ""
                        ? "-"
                        : Number(row.quantity).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell>
                    <TableCell align="center">
                      {row.navShare === ""
                        ? "-"
                        : Number(row.navShare).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell>
                    <TableCell align="center">
                      {row.nav === ""
                        ? "-"
                        : Number(row.nav).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell>
                    <TableCell align="center">{row.action} </TableCell>
                    <TableCell align="center">{row.type.name} </TableCell>
                    <TableCell align="center">{row.country.name} </TableCell>
                    <TableCell align="center">
                      {formatStr(row.investor.name)}
                    </TableCell>
                    <TableCell align="center">
                      {row.investor.discretionaryCode}
                    </TableCell>
                    <TableCell align="center">
                      {row.discretionalContractDate || "-"}
                    </TableCell>
                    <TableCell align="center">
                      {row.fundRedSubApplicationDate || "-"}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={transactions.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditTransactionDialog
        open={open}
        setOpen={setOpen}
        rowInfo={rowInfo}
        render={render}
        setRender={setRender}
        investors={investors}
        funds={funds}
        setResMsg={setResMsg}
        setIsSuccess={setIsSuccess}
      />
    </Box>
  );
}
