import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import SearchInvestorRows from "../molecules/investor/SearchInvestorRows";
import { InvestorBar } from "../molecules/investor/InvestorBar";
import InvestorInfoDialog from "../organisms/investor/InvestorInfoDialog";
import { useFetch } from "../../hooks/useFetch";
import { NameTableData } from "./TrusteeBank";

export interface InvestorData {
  id: number;
  name: string;
  discretionaryCode: string;
  country: NameTableData;
  type: NameTableData;
  remarks: string;
  message: string;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "countryName",
    label: "Country",
  },
  {
    id: "typeName",
    label: "Type",
  },
];

export default function InvestorTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rows, setRows] = useState<InvestorData[]>([]);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // rowsは動的検索で更新されるが、対してinitRowsは検索では更新されないため、検索バーが空になったときに使用される
  const [initRows, setInitRows] = useState<InvestorData[]>([]);
  const [searched, setSearched] = useState("");

  const [rowInfo, setRowInfo] = useState<InvestorData>({} as InvestorData);

  // インベスター一覧を取得
  const { data: investors } = useFetch<InvestorData[]>(
    `/investors`,
    render,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    setRows(investors);
    setInitRows(investors);
  }, [investors]);

  // カントリー一覧を取得
  const { data: countries } = useFetch<NameTableData[]>(
    `/countries`,
    null,
    setIsSuccess,
    setResMsg
  );

  // インベスター種別一覧を取得
  const { data: types } = useFetch<NameTableData[]>(
    `/investor_types`,
    null,
    setIsSuccess,
    setResMsg
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const showInvestorInfoDialog = (row: InvestorData) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <InvestorBar
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        countries={countries}
        types={types}
        setIsSuccess={setIsSuccess}
      />
      <Grid mb={4} mx={2}>
        <SearchInvestorRows
          initRows={initRows}
          searched={searched}
          setRows={setRows}
          setSearched={setSearched}
        />
      </Grid>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={headCell.id}
                    align="center"
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={() => showInvestorInfoDialog(row)}
                    key={row.id}
                  >
                    <TableCell align="center">{row.id} </TableCell>
                    <TableCell align="center">{row.name} </TableCell>
                    <TableCell align="center">{row.country.name} </TableCell>
                    <TableCell align="center">{row.type.name} </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <InvestorInfoDialog
        open={open}
        setOpen={setOpen}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        countries={countries}
        types={types}
        rowInfo={rowInfo}
        setIsSuccess={setIsSuccess}
      />
    </Box>
  );
}
