import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { InvestorProps } from "../../molecules/investor/InvestorBar";
import { usePost } from "../../../hooks/usePost";
import { NameTableData } from "../../templates/TrusteeBank";

interface DataForApiRequest {
  name: string;
  country: NameTableData;
  type: NameTableData;
  remarks: string;
}

const schema = yup.object({
  name: yup
    .string()
    .required("入力は必須です")
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(200, "200文字以内で入力してください"),
  country: yup.object().typeError("入力は必須です").required("入力は必須です"),
  type: yup.object().typeError("入力は必須です").required("入力は必須です"),
});

export default function AddInvestorDialog(props: InvestorProps) {
  const { render, setRender, setResMsg, countries, types, setIsSuccess } =
    props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { postRequest } = usePost(
    "/investor",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<DataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      name: data.name,
      countryId: data.country.id,
      typeId: data.type.id,
      remarks: data.remarks || "",
    });

    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <Grid container my={4} justifyContent="space-between">
              <Grid item mx={1} xs={12}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Name"
                        variant="standard"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        autoComplete="off"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                container
                my={4}
                direction="row"
                justifyContent="space-between"
              >
                <Grid item mx={1} xs={5}>
                  <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        // 選択肢入力(C)
                        options={countries}
                        getOptionLabel={(country) => country.name}
                        onChange={(_, data) => field.onChange(data)}
                        renderInput={(params) => (
                          <TextField
                            label="Country"
                            variant="standard"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            error={Boolean(errors.country)}
                            helperText={errors.country?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item mx={1} xs={5}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        // 選択肢入力(C)
                        options={types}
                        getOptionLabel={(type) => type.name}
                        onChange={(_, data) => field.onChange(data)}
                        renderInput={(params) => (
                          <TextField
                            label="Type"
                            variant="standard"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            error={Boolean(errors.type)}
                            helperText={errors.type?.message}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container my={4} justifyContent="space-between">
                <Grid item mx={1} xs={12}>
                  <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                    <Controller
                      name="remarks"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          id="standard-multiline-static"
                          label="Remarks"
                          multiline
                          rows={4}
                          variant="standard"
                          autoComplete="off"
                          onChange={(e) => field.onChange(e.target.value)}
                        />
                      )}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
