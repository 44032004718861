import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Stack, TextField } from "@mui/material";
import { Dispatch, SetStateAction, useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { SnackBarContext } from "../../../Context/SnackBarContext";
import { FundData } from "../../templates/Fund";
import { useGet } from "../../../hooks/useGet";
import { NavData } from "../../templates/NetAssetValue";

interface Investor {
  id: number;
  code: string;
}

interface Fund {
  id: number;
  code: string;
}

interface DataForApiRequest {
  investor: Investor;
  fund: Fund;
  fromYmd: string;
  toYmd: string;
}

interface Props {
  setRows: Dispatch<SetStateAction<NavData[]>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function SearchNetAssetValueRows(props: Props) {
  const { setRows, setResMsg, funds, setIsSuccess } = props;
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const { handleSubmit, control } = useForm<DataForApiRequest>();

  const { getRequest } = useGet<NavData[]>(
    `/net_asset_values`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<DataForApiRequest> = async (data) => {
    try {
      // 全件取得の場合、クエリパラメータすら作りたくない
      let param = "";

      if (data.fund) {
        param += `fundId=${data.fund.id}`;
      }
      if (data.fromYmd) {
        param += `&fromYmd=${dayjs(data.fromYmd)
          .endOf("month")
          .format("YYYY-MM-DD")}`;
      }
      if (data.toYmd) {
        param += `&toYmd=${dayjs(data.toYmd)
          .endOf("month")
          .format("YYYY-MM-DD")}`;
      }

      const navDataList = await getRequest(param);
      if (!navDataList) {
        return;
      }

      setRows(navDataList);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <div>
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item m={1} xs={1.5}>
            <Controller
              name="fund"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={funds}
                  getOptionLabel={(fund) => fund.code}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Fund Code"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item m={1} xs={1.5}>
            <Stack>
              <Controller
                name="fromYmd"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="From"
                    variant="standard"
                    type="month"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid item m={1} xs={1.5}>
            <Stack>
              <Controller
                name="toYmd"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="To"
                    variant="standard"
                    type="month"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                )}
              />
            </Stack>
          </Grid>
          <Grid m={1}>
            <IconButton type="submit" color="primary" size="large">
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
