import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { usePost } from "../../../hooks/usePost";

interface BalanceApiRequestData {
  investorId: number;
  fundCode: string;
  ymd: string;
  classSeries: string;
  accountNumber: string;
  currency: string;
  quantity: number;
  nav: number;
  navShare: number;
}

interface CsvData {
  "Investor ID": number;
  "Fund Code": string;
  "Balance Date": string;
  "Account ID": string;
  "Class/Series": string;
  Currency: string;
  Quantity: number;
  "NAV/Share": number;
  NAV: number;
}

interface Props {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
}

export default function ImportBalanceDialog(props: Props) {
  const { render, setRender, setResMsg, setIsSuccess } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [fundCode, setFundCode] = useState("");
  const [ymd, setYmd] = useState("");
  const [requestJson, setRequestJson] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

  const handleTriggerReadFile = () => {
    if (fileInput.current) {
      fileInput.current.click();
      setOpen(false);
    }
  };

  const handleReadFile = (fileObj: File) => {
    if (fileObj) {
      setFileName(fileObj.name);
      void fileObj.arrayBuffer().then((buffer) => {
        const workbook = XLSX.read(buffer, {
          type: "buffer",
          bookVBA: true,
          cellDates: true, // ymdがシリアル値になってしまうのを防ぐ
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const csvData: CsvData[] = XLSX.utils.sheet_to_json(worksheet);

        //
        // 以下、CSVファイルのチェック
        //
        const errors = [];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        const accountIdHeader = worksheet.D1.w;

        if (csvData.length === 0) {
          errors.push("データがありません。");
        } else if (accountIdHeader !== "Account ID") {
          errors.push("D1のカラムがAccount IDではありません。");
        } else {
          const fundCodeList: string[] = [];
          const ymdList: string[] = [];
          csvData.forEach((v: CsvData, i: number) => {
            // classSeriesは半角英数字または半角記号であることをチェック
            const regexClassSeries = /^[a-zA-Z0-9 -/:-@[-~]*$/;
            if (!regexClassSeries.test(v["Class/Series"])) {
              errors.push(
                `Class/Seriesの${i + 1}行目: ${
                  v["Class/Series"]
                }が半角英数字または半角記号ではありません`
              );
            }

            // accountIDは半角英数字もしくは半角記号であることをチェック
            const regexAccountNumber = /^[a-zA-Z0-9 -/:-@[-~]*$/;
            if (!regexAccountNumber.test(v["Account ID"])) {
              errors.push(
                `Account IDの${i + 1}行目: ${
                  v["Account ID"]
                }が半角英数字または半角記号ではありません`
              );
            }

            // currencyはUSDかJPYであることをチェック
            if (v.Currency !== "USD" && v.Currency !== "JPY") {
              errors.push(
                `Currencyの${i + 1}行目: ${
                  v.Currency
                }がUSDまたはJPYではありません`
              );
            }

            // 浮動小数点の正規表現を宣言 (100, -100, 100.0, 4.2e5, 2.01e-3 等を許容)
            const regexFloatingDecimal =
              /^[+-]?[0-9]+[.]*[0-9]*([eE][+-]?[0-9]+)?$/;

            // 以下、quantityからNavまでの3項目は、それぞれ浮動小数であることをチェック
            if (!regexFloatingDecimal.test(`${v.NAV}`)) {
              errors.push(
                `NAVの${i + 1}行目: ${v.NAV}が適切な数値ではありません`
              );
            }

            // Nav/ShareとQuantityはundefinedも許容
            if (
              v["NAV/Share"] !== undefined &&
              !regexFloatingDecimal.test(`${v["NAV/Share"]}`)
            ) {
              errors.push(
                `NAV/Shareの${i + 1}行目: ${
                  v["NAV/Share"]
                }が適切な数値ではありません`
              );
            }

            if (
              v.Quantity !== undefined &&
              !regexFloatingDecimal.test(`${v.Quantity}`)
            ) {
              errors.push(
                `Quantity${i + 1}行目: ${v.Quantity}が適切な数値ではありません`
              );
            }

            // fundCodeとymdはこの後それぞれ1種類であることをチェックするため、一度配列に格納
            fundCodeList.push(v["Fund Code"]);
            ymdList.push(dayjs(v["Balance Date"]).format("YYYY-MM-DD"));
          });

          // 上で作成した配列を重複除外処理 & 空文字除外処理
          const fundCodeListUnique = fundCodeList.filter(
            (x: string, i: number, self: string[]) =>
              x !== "" ? self.indexOf(x) === i : ""
          );

          const ymdListUnique = ymdList.filter(
            (x: string, i: number, self: string[]) =>
              x !== "" ? self.indexOf(x) === i : ""
          );

          if (fundCodeListUnique.length > 1) {
            // fundCodeが1種類であるかチェック
            errors.push("Fund Code が1種類ではありません");
            // }
          } else if (fundCodeListUnique.length === 0) {
            // 1種類であっても空ならだめ
            errors.push("Fund Code が空です");
          }

          if (ymdListUnique.length > 1) {
            // ymdが1種類であるかチェック
            errors.push("Balance Date が1種類ではありません");
          } else if (ymdListUnique.length === 0) {
            // 1種類であっても空ならだめ
            errors.push("Balance Date が空です");
          }

          // balanceDateに入った値を、年月日のみの文字列にフォーマットしてymdキーに格納
          const balanceApiRequestData: BalanceApiRequestData[] = [];
          csvData.forEach((v: CsvData, i: number) => {
            // Account IDがD1にあることを確認しているので、cは3で固定
            const address = XLSX.utils.encode_cell({ r: i + 1, c: 3 });

            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
            const accountIdType: string = worksheet[address]?.t;

            // accountIdは数字でなく文字列として取得したいため、sheet_to_jsonとは別で取得
            let accountId: string;
            if (accountIdType === "n") {
              // typeがnの場合、頭の0を除外してほしくないのでwで値を受け取る
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
              accountId = worksheet[address]?.w;
            } else if (accountIdType === "s") {
              // typeがsの場合(値にハイフンがある場合等)、ライブラリの仕様でwが存在しないのでvで値を受け取る
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
              accountId = worksheet[address]?.v;
            } else {
              accountId = "";
            }

            balanceApiRequestData.push({
              investorId: v["Investor ID"],
              fundCode: v["Fund Code"] || "",
              ymd: v["Balance Date"]
                ? dayjs(v["Balance Date"]).format("YYYY-MM-DD")
                : "", // v["Balance Date"]が空の場合、dayjsの仕様で今日の日付が入ってしまうので空文字にして格納
              classSeries: v["Class/Series"],
              accountNumber: accountId,
              currency: v.Currency,
              quantity: v.Quantity,
              nav: v.NAV,
              navShare: v["NAV/Share"],
            });
          });

          setFundCode(balanceApiRequestData[0].fundCode);
          setYmd(balanceApiRequestData[0].ymd);
          setRequestJson(JSON.stringify(balanceApiRequestData));
        }

        setErrorMessageList(errors);
      });

      reset();
      setOpen(true);
    }
  };

  const handleClose = () => {
    reset();
    setOpen(false);

    //  同じファイルを選んだ場合onChangeが反応しないので、一旦リロード
    window.location.reload();
  };

  const { reset } = useForm<BalanceApiRequestData>();

  const { postRequest } = usePost(
    "/balances",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit = async () => {
    await postRequest(requestJson);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={() => handleTriggerReadFile()}
        startIcon={<FileUploadIcon />}
      >
        csv
      </Button>
      <form style={{ display: "none" }}>
        <input
          type="file"
          accept="text/csv"
          ref={fileInput}
          onChange={(e) => {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore データがnullだった場合も、この後バリデーションチェックしているので問題ないかと考えられる
            handleReadFile(e.currentTarget.files[0]);
          }}
        />
      </form>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>CSVファイルチェック</DialogTitle>
          <DialogContent>
            <Typography sx={{ m: 1 }}>File Name: {fileName}</Typography>
            <Typography sx={{ m: 1 }}>
              Fund Code: {errorMessageList.length === 0 ? fundCode : "-"}
            </Typography>
            <Typography sx={{ m: 1 }}>
              Balance Date: {errorMessageList.length === 0 ? ymd : "-"}
            </Typography>
            <Typography
              sx={{ m: 1 }}
              color={
                errorMessageList.length === 0 ? "success.main" : "error.main"
              }
            >
              {errorMessageList.length === 0
                ? "ファイルチェックを通過しました"
                : `エラー: ${errorMessageList.length}件`}
              <ol>
                {errorMessageList.map((reptile) => (
                  <li>{reptile}</li>
                ))}
              </ol>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button disabled={errorMessageList.length !== 0} onClick={onSubmit}>
              インポート
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
