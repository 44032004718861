import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DialogActions, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Buffer from "exceljs/index";
import { useGet } from "../../../hooks/useGet";
import { SnackBarContext } from "../../../Context/SnackBarContext";
import { NavData } from "../../templates/NetAssetValue";
import { FundData } from "../../templates/Fund";

interface Input {
  fund: FundData;
  targetDate: string;
}

// nullだった場合にバリデーションエラー
const schema = yup.object({
  fund: yup.object().typeError("入力は必須です"), // バツボタン対策
  targetDate: yup.string().typeError("入力は必須です"), // バツボタン対策
});

const columnsMapKeys = [
  { header: "Balance Date", key: "targetDate" },
  { header: "Fund Code", key: "fundCode" },
  { header: "Currency", key: "currency" },
  { header: "NAV", key: "nav" },
];

interface Props {
  rows: NavData[];
  exportType: string;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
}

export default function ExportNetAssetValueExcelAndCsvButton(props: Props) {
  const { rows, exportType, setIsSuccess, setResMsg, funds } = props;
  const [open, setOpen] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  if (exportType !== "csv" && exportType !== "xlsx") {
    throw new Error("exportType is incorrect!");
  }

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  const targetDateArray: string[] = [];

  rows.forEach((row: NavData) =>
    targetDateArray.push(
      row.targetDate ? dayjs(row.targetDate).format("YYYY/MM") : ""
    )
  );

  const noCondition = "全件";

  const uniqueTargetDateArray = targetDateArray.filter((x, i, self) =>
    x !== "" ? self.indexOf(x) === i : ""
  );

  // 先頭に「全件」という選択肢を追加
  const uniqueFundArray = [{ id: 0, code: noCondition } as FundData, ...funds];
  uniqueTargetDateArray.unshift(noCondition);

  const { getRequest } = useGet<NavData[]>(
    `/net_asset_values`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("NetAssetValueList", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      let param = "";
      if (condition.fund && condition.fund.id !== 0) {
        param += `fundId=${condition.fund.id}`;
      }

      if (condition.targetDate && condition.targetDate !== noCondition) {
        const fromDate = dayjs(condition.targetDate).format("YYYY-MM-DD");
        param += `&fromYmd=${fromDate}`;
        const toDate = dayjs(condition.targetDate)
          .endOf("month")
          .format("YYYY-MM-DD");
        param += `&toYmd=${toDate}`;
      }

      const navDataList = await getRequest(param);

      if (!navDataList) {
        return;
      }

      navDataList.forEach((navData: NavData) => {
        worksheet.addRow({
          targetDate: navData.targetDate,
          fundCode: navData.fund.code || "",
          currency: navData.currency,
          nav: navData.nav,
        });
      });

      // CSVファイルまたはExcelファイルを生成
      let uint8Array: Buffer;

      if (exportType === "csv") {
        uint8Array = await workbook.csv.writeBuffer();
      } else {
        // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
        // セルを青色に変更
        const fillStylesBlue: ExcelJS.Fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };

        worksheet.getCell("A1").fill = fillStylesBlue;
        worksheet.getCell("B1").fill = fillStylesBlue;
        worksheet.getCell("C1").fill = fillStylesBlue;
        worksheet.getCell("D1").fill = fillStylesBlue;

        // フォントを白色に変更
        worksheet.getRow(1).font = {
          color: { argb: "FFFFFF" },
        };

        // 幅調整
        worksheet.getColumn(1).width = 15;
        worksheet.getColumn(2).width = 15;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 30;

        uint8Array = await workbook.xlsx.writeBuffer();
      }

      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `NetAssetValueList_${now}.${exportType}`;
      link.click();

      setOpen(false);
    } catch (e) {
      // 意図しないエラーが発生した場合
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<DownloadIcon />}
        sx={{ mr: 5 }}
        onClick={handleClickOpen}
      >
        {exportType === "csv" ? "CSV" : "Excel"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>絞り込み条件</DialogTitle>
          <DialogContent>
            <Grid container mb={4} justifyContent="flex-start">
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueFundArray}
                      getOptionLabel={(f) => f.code}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={uniqueFundArray[0]}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"fund" in errors}
                          helperText={errors.fund?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid mx={2} sx={{ width: 200 }}>
                <Controller
                  name="targetDate"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={uniqueTargetDateArray}
                      onChange={(_, data) => field.onChange(data)}
                      defaultValue={noCondition}
                      renderInput={(params) => (
                        <TextField
                          label="Balance Date"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={"targetDate" in errors}
                          helperText={errors.targetDate?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit">エクスポート</Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
