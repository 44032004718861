import { createContext, useState } from "react";

type Props = {
  children: React.ReactNode;
};

export const SnackBarContext = createContext(
  {} as {
    showSnackBars: boolean;
    setShowSnackBars: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

// eslint-disable-next-line react/function-component-definition,react/prop-types
export const SnackBarProvider: React.FC<Props> = ({ children }) => {
  const [showSnackBars, setShowSnackBars] = useState(false);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SnackBarContext.Provider value={{ showSnackBars, setShowSnackBars }}>
      {children}
    </SnackBarContext.Provider>
  );
};
