import { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { DomesticDiscretionaryClientProps } from "../../molecules/domesticDiscretionaryClient/DomesticDiscretionaryClientBar";
import { usePost } from "../../../hooks/usePost";
import { Label } from "../../templates/DomesticDiscretionaryClient";
import { InvestorData } from "../../templates/Investor";
import { MainFunds } from "./DomesticDiscretionaryClientInfoDialog";
import { NameTableData } from "../../templates/TrusteeBank";

export interface DdcDataForApiRequest {
  id: number;
  mainFund: Label;
  investor: InvestorData;
  name: string;
  kana: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  zipCode: string;
  currentAddress: string;
  historicalAddressMemo: string;
  phoneNumber: string;
  addressee1: string;
  mail1: string;
  addressee2: string;
  mail2: string;
  addressee3: string;
  mail3: string;
  addressee4: string;
  mail4: string;
  subscriptionRedemptionMemo: string;
  trusteeBank: NameTableData;
  reTrusteeBank: NameTableData;
  accountNumber: string;
  remarks: string;
  isActive: boolean;
}

const schema = yup.object({
  investor: yup.object().typeError("入力は必須です").required("入力は必須です"),
  mainFund: yup.object().typeError("入力は必須です").required("入力は必須です"),
  name: yup
    .string()
    .required("入力は必須です")
    .max(200, "200文字以内で入力してください"),
  kana: yup
    .string()
    .required("入力は必須です")
    .matches(
      /^[\u3040-\u309F0-9]+$/, // ひらがなと数字のみ
      "ひらがなまたは半角数字で入力してください"
    )
    .max(400, "400文字以内で入力してください"),
  zipCode: yup
    .string()
    .matches(/^(\d{3}-\d{4}|)$/, "000-0000の形式で入力してください"), // 空文字も許容
  phoneNumber: yup.string().matches(
    /^(\d{2,4}-\d{2,4}-\d{4}|)$/, // ハイフン必須
    "000-0000-0000の形式で入力してください"
  ),
  currentAddress: yup.string().max(1000, "1000文字以内で入力してください"),
  historicalAddressMemo: yup.string().max(1000, "1000文字以内で入力してください"),
  addressee1: yup.string().max(400, "400文字以内で入力してください"),
  addressee2: yup.string().max(400, "400文字以内で入力してください"),
  addressee3: yup.string().max(400, "400文字以内で入力してください"),
  addressee4: yup.string().max(400, "400文字以内で入力してください"),
  mail1: yup.string().email("メールアドレスの形式で入力してください"),
  mail2: yup.string().email("メールアドレスの形式で入力してください"),
  mail3: yup.string().email("メールアドレスの形式で入力してください"),
  mail4: yup.string().email("メールアドレスの形式で入力してください"),
  accountNumber: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    ).max(100, "100文字以内で入力してください"),
  trusteeBank: yup
    .object()
    .typeError("入力は必須です")
    .required("入力は必須です"),
  reTrusteeBank: yup
    .object()
    .typeError("入力は必須です")
    .required("入力は必須です"),
});

export default function AddDomesticDiscretionaryClientDialog(
  props: DomesticDiscretionaryClientProps
) {
  const {
    render,
    setRender,
    setResMsg,
    investors,
    setIsSuccess,
    trusteeBanks,
    reTrusteeBanks,
  } = props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<DdcDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { postRequest } = usePost(
    "/domestic_discretionary_client",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<DdcDataForApiRequest> = async (data) => {
    const body = JSON.stringify({
      mainFund: data.mainFund.id,
      investorId: data.investor.id,
      name: data.name,
      kana: data.kana,
      contractDate: data.contractDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      startingDate: data.startingDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      closingDate: data.closingDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      zipCode: data.zipCode || "",
      currentAddress: data.currentAddress || "",
      historicalAddressMemo: data.historicalAddressMemo || "",
      phoneNumber: data.phoneNumber || "",
      addressee1: data.addressee1 || "",
      mail1: data.mail1 || "",
      addressee2: data.addressee2 || "",
      mail2: data.mail2 || "",
      addressee3: data.addressee3 || "",
      mail3: data.mail3 || "",
      addressee4: data.addressee4 || "",
      mail4: data.mail4 || "",
      subscriptionRedemptionMemo: data.subscriptionRedemptionMemo || "",
      trusteeBankId: data.trusteeBank.id,
      reTrusteeBankId: data.reTrusteeBank.id,
      accountNumber: data.accountNumber || "",
      remarks: data.remarks || "",
      isActive: data.isActive !== false, // ほとんどの新規登録がtrueになるため、明示的にfalseで新規登録される場合以外はtrueで登録
    });

    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={3}>
                <Controller
                  name="investor"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={investors}
                      getOptionLabel={(investor) =>
                        `${investor.id}: ${investor.name}`
                      }
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Investor ID: Investor Name"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.investor)}
                          helperText={errors.investor?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={2}>
                <Controller
                  name="mainFund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={MainFunds}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Main Fund"
                          variant="standard"
                          error={Boolean(errors.mainFund)}
                          helperText={errors.mainFund?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="顧客名"
                        variant="standard"
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="kana"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="読み方"
                        variant="standard"
                        error={Boolean(errors.kana)}
                        helperText={errors.kana?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="contractDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="契約開始日"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="startingDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="当初資産組入日"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="closingDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="契約終了日"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={2}>
                <Controller
                  name="isActive"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      label="アクティブ"
                      control={
                        <Checkbox
                          defaultChecked
                          onChange={(e) => field.onChange(e.target.checked)}
                          checked={field.value}
                        />
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="zipCode"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="郵便番号"
                        variant="standard"
                        error={Boolean(errors.zipCode)}
                        helperText={errors.zipCode?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="currentAddress"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="住所"
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={Boolean(errors.currentAddress)}
                        helperText={errors.currentAddress?.message}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="電話番号"
                        variant="standard"
                        error={Boolean(errors.phoneNumber)}
                        helperText={errors.phoneNumber?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="space-between">
              <Grid item mx={1} xs={8}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="historicalAddressMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="住所 (履歴)"
                        multiline
                        rows={3}
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={Boolean(errors.historicalAddressMemo)}
                        helperText={errors.historicalAddressMemo?.message}
                        autoComplete="off"
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名1"
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={Boolean(errors.addressee1)}
                        helperText={errors.addressee1?.message}
                        autoComplete="off"
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail1"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス1"
                        variant="standard"
                        error={Boolean(errors.mail1)}
                        helperText={errors.mail1?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名2"
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={Boolean(errors.addressee2)}
                        helperText={errors.addressee2?.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail2"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス2"
                        variant="standard"
                        error={Boolean(errors.mail2)}
                        helperText={errors.mail2?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee3"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名3"
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                        error={Boolean(errors.addressee3)}
                        helperText={errors.addressee3?.message}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail3"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス3"
                        variant="standard"
                        error={Boolean(errors.mail3)}
                        helperText={errors.mail3?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={2}>
                <Stack>
                  <Controller
                    name="addressee4"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="宛名4"
                        variant="standard"
                        error={Boolean(errors.addressee4)}
                        helperText={errors.addressee4?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <Controller
                    name="mail4"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="メールアドレス4"
                        variant="standard"
                        error={Boolean(errors.mail4)}
                        helperText={errors.mail4?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={12}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="subscriptionRedemptionMemo"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="Subscription/Redemptionメモ"
                        multiline
                        rows={4}
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="flex-start">
              <Grid item mx={1} xs={3}>
                <Controller
                  name="trusteeBank"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={trusteeBanks}
                      getOptionLabel={(tb: NameTableData) => tb.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="受託信託銀行"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.trusteeBank)}
                          helperText={errors.trusteeBank?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={3}>
                <Controller
                  name="reTrusteeBank"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      options={reTrusteeBanks}
                      getOptionLabel={(rtb) => rtb.name}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="再受託信託銀行"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={Boolean(errors.reTrusteeBank)}
                          helperText={errors.reTrusteeBank?.message}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={3}>
                <Stack>
                  <Controller
                    name="accountNumber"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="口座番号"
                        variant="standard"
                        error={Boolean(errors.accountNumber)}
                        helperText={errors.accountNumber?.message}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container my={4} direction="row" justifyContent="flex-start">
              <Grid item mx={1} xs={12}>
                <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                  <Controller
                    name="remarks"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        id="standard-multiline-static"
                        label="備考"
                        multiline
                        rows={4}
                        variant="standard"
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
