import { ChangeEvent, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import DownloadIcon from "@mui/icons-material/Download";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { useGet } from "../../hooks/useGet";
import { SnackBarContext } from "../../Context/SnackBarContext";

interface BalancesDataForInvestorReport {
  no: number;
  investorId: number;
  ymd: string;
  fundCode: string;
  classSeries: string;
  accountNumber: string;
  currency: number;
  quantity: number;
  navShare: string;
  nav: string;
}

interface Investor {
  id: number;
  label: string;
}

interface YmdAndInvestorsData {
  ymd: string[];
  investors: Investor[];
}

interface Label {
  id: number;
  label: string;
}

interface Input {
  investorId: Label;
  ym: string;
}

const columnsMapKeys = [
  { header: "No", key: "no" },
  { header: "Investor ID", key: "investorId" },
  { header: "Balance Date", key: "ymd" },
  { header: "Fund Code", key: "fundCode" },
  { header: "Account ID", key: "accountNumber" },
  { header: "Class/Series", key: "classSeries" },
  { header: "Currency", key: "currency" },
  { header: "Quantity", key: "quantity" },
  { header: "NAV/Share", key: "navShare" },
  { header: "NAV", key: "nav" },
];

export default function ReportInvestorTemplate() {
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [balancesData, setBalancesData] =
    useState<BalancesDataForInvestorReport[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const { handleSubmit, control } = useForm<Input>();
  const noCondition = "全件";

  // 選択肢取得
  const { data: rows } = useFetch<YmdAndInvestorsData>(
    `/balances/ymd_and_investors`,
    null,
    setIsSuccess,
    setResMsg
  );

  const { getRequest } = useGet<BalancesDataForInvestorReport[]>(
    `/balances/report_investor`,
    setIsSuccess,
    setResMsg
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      // 全件取得の場合、クエリパラメータすら作りたくない
      let param = "";
      if (condition.investorId && condition.investorId.id !== 0) {
        param += `investorId=${condition.investorId.id}`;
      }
      if (condition.ym && condition.ym !== noCondition) {
        param += `&ym=${condition.ym.replace("/", "-")}`;
      }

      const got = await getRequest(param);
      if (!got) {
        return;
      }

      setBalancesData(got);
      setPage(0);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const exportExcel: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("ReportInvestor", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      // 全件取得の場合、クエリパラメータすら作りたくない
      let param = "";
      if (condition.investorId && condition.investorId.id !== 0) {
        param += `investorId=${condition.investorId.id}`;
      }
      if (condition.ym && condition.ym !== noCondition) {
        param += `&ym=${condition.ym.replace("/", "-")}`;
      }

      const got = await getRequest(param);
      if (!got) {
        return;
      }

      setBalancesData(got);
      setPage(0);

      got.forEach((row: BalancesDataForInvestorReport, index) => {
        worksheet.addRow({
          no: index + 1,
          investorId: row.investorId,
          ymd: dayjs(row.ymd).format("YYYY/MM/DD"),
          fundCode: row.fundCode,
          classSeries: row.classSeries,
          accountNumber: row.accountNumber,
          currency: row.currency,
          quantity: row.quantity,
          navShare: row.navShare,
          nav: row.nav,
        });
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() => worksheet.insertRow(1, []));

      // 基本情報を入力
      worksheet.getCell("A1").value = "Investor 月末別残高データ";
      worksheet.getCell("A2").value = "Investor ID";
      worksheet.getCell("A3").value = "Balance Date";

      worksheet.getCell("C2").value =
        !condition.investorId || condition.investorId.id === 0
          ? noCondition
          : condition.investorId.id;

      worksheet.getCell("C3").value = !condition.ym
        ? noCondition
        : condition.ym;

      /*
       * スタイル調整
       */

      // ヘッダ部分9カラムの編集 (exceljsではセルの範囲指定での編集ができない)
      // セルを青色に変更
      const fillStylesBlue: ExcelJS.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "223a70" },
      };

      worksheet.getCell("A6").fill = fillStylesBlue;
      worksheet.getCell("B6").fill = fillStylesBlue;
      worksheet.getCell("C6").fill = fillStylesBlue;
      worksheet.getCell("D6").fill = fillStylesBlue;
      worksheet.getCell("E6").fill = fillStylesBlue;
      worksheet.getCell("F6").fill = fillStylesBlue;
      worksheet.getCell("G6").fill = fillStylesBlue;
      worksheet.getCell("H6").fill = fillStylesBlue;
      worksheet.getCell("I6").fill = fillStylesBlue;
      worksheet.getCell("J6").fill = fillStylesBlue;

      // セルをマージ
      worksheet.mergeCells("A1:C1");
      worksheet.mergeCells("A2:B2");
      worksheet.mergeCells("A3:B3");

      // セルをオレンジ色に変更
      const fillStylesOrange: ExcelJS.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "ffe9d8" },
      };

      worksheet.getCell("C2").fill = fillStylesOrange;
      worksheet.getCell("C3").fill = fillStylesOrange;

      // 罫線
      const borderStyles: Partial<ExcelJS.Borders> = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheet.getCell("C2").border = borderStyles;
      worksheet.getCell("C3").border = borderStyles;

      // フォントを白色に変更
      worksheet.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 太字
      worksheet.getCell("A1").style = { font: { bold: true, size: 15 } };

      // 幅調整
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 10;
      worksheet.getColumn(3).width = 15;
      worksheet.getColumn(4).width = 15;
      worksheet.getColumn(5).width = 10;
      worksheet.getColumn(6).width = 10;
      worksheet.getColumn(7).width = 10;
      worksheet.getColumn(8).width = 15;
      worksheet.getColumn(9).width = 15;
      worksheet.getColumn(10).width = 15;

      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `ReportInvestor_${now}.xlsx`;

      link.click();
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };
  const handleClickCopy = async (
    row: BalancesDataForInvestorReport,
    index: number
  ): Promise<void> => {
    const no = index + 1;
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${no}\t${row.investorId}\t${
      row.ymd ? dayjs(row.ymd).format("YYYY/MM/DD") : ""
    }\t${row.fundCode}\t${row.accountNumber}\t${row.classSeries}\t${
      row.currency
    }\t${row.quantity}\t${row.navShare}\t${row.nav}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`No:${no}の行をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  return (
    <Box style={{ height: 400, width: "90vw" }} sx={{ mx: "auto" }}>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Typography sx={{ m: 1 }} variant="h5">
        Report Investor
      </Typography>
      <form>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item m={2} xs={3}>
            <Controller
              name="investorId"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={"investors" in rows ? rows.investors : []}
                  onChange={(_, data) => field.onChange(data)}
                  defaultValue={{ id: 0, label: noCondition }}
                  renderInput={(params) => (
                    <TextField
                      label="Investor Name, Investor ID, Discretionary Code"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item m={2} xs={2}>
            <Controller
              name="ym"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={"ymd" in rows ? rows.ymd : []}
                  onChange={(_, data) => field.onChange(data)}
                  defaultValue={noCondition}
                  renderInput={(params) => (
                    <TextField
                      label="Balance Date"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(onSubmit)}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(exportExcel)}
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {balancesData ? (
        <Paper sx={{ mb: 2 }}>
          <TablePagination
            rowsPerPageOptions={[50, 100, 1000]}
            component="div"
            count={balancesData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <Table sx={{ width: "95%", m: "auto" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  No
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  InvestorID
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  BalanceDate
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  FundCode
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  AccountID
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Class/Series
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Currency
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  Quantity
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  NAV/Share
                </TableCell>
                <TableCell align="left" style={{ fontWeight: "bold" }}>
                  NAV
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {balancesData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    hover
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() => handleClickCopy(row, index)}
                    key={
                      String(index) +
                      String(row.investorId) +
                      row.ymd +
                      row.fundCode
                    }
                  >
                    <TableCell align="left">{index + 1}</TableCell>
                    <TableCell align="right">{row.investorId}</TableCell>
                    <TableCell align="right">{row.ymd}</TableCell>
                    <TableCell align="right">{row.fundCode}</TableCell>
                    <TableCell align="right">{row.accountNumber}</TableCell>
                    <TableCell align="right">{row.classSeries}</TableCell>
                    <TableCell align="right">{row.currency}</TableCell>
                    <TableCell align="right">{row.quantity}</TableCell>
                    <TableCell align="right">
                      {Intl.NumberFormat("ja", {
                        minimumFractionDigits: 6,
                        maximumFractionDigits: 6,
                      }).format(Number(row.navShare))}
                    </TableCell>
                    <TableCell align="right">
                      {Intl.NumberFormat("ja", {
                        minimumFractionDigits: 6,
                        maximumFractionDigits: 6,
                      }).format(Number(row.nav))}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      ) : (
        ""
      )}
    </Box>
  );
}
