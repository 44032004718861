import BuildIcon from "@mui/icons-material/Build";
import { Grid, Typography } from "@mui/material";

export default function TopTemplate() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Typography sx={{ m: 1 }} variant="h6">
        サイドメニューより機能を選択してください
      </Typography>
    </Grid>
  );
}
