import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useForm } from "react-hook-form";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Dispatch, SetStateAction, useRef, useState } from "react";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { usePost } from "../../../hooks/usePost";

interface PerformanceApiRequestData {
  fundCode: string;
  ymd: string;
  classSeries: string;
  currency: string;
  gav: number;
  nav: number;
  monthlyRtnGav: number;
  monthlyRtnNav: number;
}

interface CsvData {
  "Fund Code": string;
  "Balance Date": string;
  "Class/Series": string;
  Currency: string;
  GAV: number;
  NAV: number;
  "Monthly Rtn GAV": number;
  "Monthly Rtn NAV": number;
}

interface Props {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ImportPerformanceDialog(props: Props) {
  const { render, setRender, setResMsg, setIsSuccess } = props;
  const fileInput = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState("");
  const [fundCode, setFundCode] = useState("");
  const [ymd, setYmd] = useState("");
  const [requestJson, setRequestJson] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessageList, setErrorMessageList] = useState<string[]>([]);

  const handleTriggerReadFile = () => {
    if (fileInput.current) {
      fileInput.current.click();
      setOpen(false);
    }
  };

  const handleReadFile = (fileObj: File) => {
    if (fileObj) {
      setFileName(fileObj.name);
      void fileObj.arrayBuffer().then((buffer) => {
        const workbook = XLSX.read(buffer, {
          type: "buffer",
          bookVBA: true,
          cellDates: true, // ymdがシリアル値になってしまうのを防ぐ
        });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const csvData: CsvData[] = XLSX.utils.sheet_to_json(worksheet);

        // balanceDateに入った値を、年月日のみの文字列にフォーマットしてymdキーに格納
        const performanceApiRequestData: PerformanceApiRequestData[] = [];
        csvData.forEach((v: CsvData) => {
          performanceApiRequestData.push({
            fundCode: v["Fund Code"],
            ymd: dayjs(v["Balance Date"]).format("YYYY-MM-DD"),
            classSeries: v["Class/Series"],
            currency: v.Currency,
            gav: v.GAV,
            nav: v.NAV,
            monthlyRtnGav: v["Monthly Rtn GAV"],
            monthlyRtnNav: v["Monthly Rtn NAV"],
          });
        });

        //
        // 以下、CSVファイルのチェック
        //

        const errors = [];

        if (performanceApiRequestData.length === 0) {
          errors.push("データがありません。");
        } else {
          const fundCodeList: string[] = [];
          const ymdList: string[] = [];
          performanceApiRequestData.forEach(
            (v: PerformanceApiRequestData, i: number) => {
              // classSeriesが半角英数字であることをチェック
              const regexClassSeries = /^[a-zA-Z0-9 -/]*$/;
              if (!regexClassSeries.test(v.classSeries)) {
                errors.push(
                  `Class/Seriesの${i + 1}行目: ${
                    v.classSeries
                  } が半角英数字ではありません`
                );
              }

              // currencyはUSDかJPYであることをチェック
              if (v.currency !== "USD" && v.currency !== "JPY") {
                errors.push(
                  `Currencyの${i + 1}行目: ${
                    v.currency
                  } がUSDまたはJPYではありません`
                );
              }

              // 浮動小数点の正規表現を宣言 (100, -100, 100.0, 4.2e5, 2.01e-3 等を許容)
              const regexFloatingDecimal =
                /^[+-]?[0-9]+[.]*[0-9]*([eE][+-]?[0-9]+)?$/;

              // 以下、gavからmonthlyRtnNavまでの4項目は、それぞれ浮動小数であることをチェック
              if (
                v.gav !== undefined &&
                !regexFloatingDecimal.test(`${v.gav}`)
              ) {
                errors.push(
                  `GAVの${i + 1}行目: ${v.gav} が適切な数値ではありません`
                );
              }

              if (
                v.nav !== undefined &&
                !regexFloatingDecimal.test(`${v.nav}`)
              ) {
                errors.push(
                  `NAVの${i + 1}行目: ${v.nav} が適切な数値ではありません`
                );
              }

              if (
                v.monthlyRtnGav !== undefined &&
                !regexFloatingDecimal.test(`${v.monthlyRtnGav}`)
              ) {
                errors.push(
                  `Monthly Rtn GAVの${i + 1}行目: ${
                    v.monthlyRtnGav
                  } が適切な数値ではありません`
                );
              }

              if (
                v.monthlyRtnNav !== undefined &&
                !regexFloatingDecimal.test(`${v.monthlyRtnNav}`)
              ) {
                errors.push(
                  `Monthly Rtn NAVの${i + 1}行目: ${
                    v.monthlyRtnNav
                  } が適切な数値ではありません`
                );
              }

              // fundCodeとymdはこの後それぞれ1種類であることをチェックするため、一度配列に格納
              fundCodeList.push(v.fundCode);
              ymdList.push(v.ymd);
            }
          );

          // 上で作成した配列を重複除外処理 & 空文字除外処理
          const fundCodeListUnique = fundCodeList.filter((x, i, self) =>
            x !== "" ? self.indexOf(x) === i : ""
          );

          const ymdListUnique = ymdList.filter((x, i, self) =>
            x !== "" ? self.indexOf(x) === i : ""
          );

          // fundCodeとymd列が1種類であるかチェック
          if (fundCodeListUnique.length > 1) {
            errors.push("Fund Code が1種類ではありません");
          }

          if (ymdListUnique.length > 1) {
            errors.push("Balance Date が1種類ではありません");
          }

          setFundCode(performanceApiRequestData[0].fundCode);
          setRequestJson(JSON.stringify(performanceApiRequestData));

          // 表示用にフォーマットしてset
          setYmd(dayjs(performanceApiRequestData[0].ymd).format("YYYY/MM"));
        }

        setErrorMessageList(errors);
      });

      reset();
      setOpen(true);
    }
  };

  const handleClose = () => {
    reset();
    setOpen(false);

    //  同じファイルを選んだ場合onChangeが反応しないので、一旦リロード
    window.location.reload();
  };

  const { reset } = useForm<PerformanceApiRequestData>();

  const { postRequest } = usePost(
    "/performances",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit = async () => {
    await postRequest(requestJson);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={() => handleTriggerReadFile()}
        startIcon={<FileUploadIcon />}
      >
        CSV
      </Button>
      <form style={{ display: "none" }}>
        <input
          type="file"
          accept="text/csv"
          ref={fileInput}
          onChange={(e) => {
            e.preventDefault();
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore データがnullだった場合も、この後バリデーションチェックしているので問題ないかと考えられる
            handleReadFile(e.currentTarget.files[0]);
          }}
        />
      </form>
      <Dialog open={open} onClose={handleClose}>
        <form>
          <DialogTitle>CSVファイルチェック</DialogTitle>
          <DialogContent>
            <Typography sx={{ m: 1 }}>File Name: {fileName}</Typography>
            <Typography sx={{ m: 1 }}>
              Fund Code: {errorMessageList.length === 0 ? fundCode : "-"}
            </Typography>
            <Typography sx={{ m: 1 }}>
              Balance Date: {errorMessageList.length === 0 ? ymd : "-"}
            </Typography>
            <Typography
              sx={{ m: 1 }}
              color={
                errorMessageList.length === 0 ? "success.main" : "error.main"
              }
            >
              {errorMessageList.length === 0
                ? "ファイルチェックを通過しました"
                : `エラー: ${errorMessageList.length}件`}
              <ol>
                {errorMessageList.map((reptile) => (
                  <li>{reptile}</li>
                ))}
              </ol>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <Button disabled={errorMessageList.length !== 0} onClick={onSubmit}>
              インポート
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
