import Switch from "@mui/material/Switch";
import { useContext } from "react";
import { Box } from "@mui/material";
import { FundContext } from "../../../Context/FundContext";

export default function FundSwitches() {
  const { showFund, setShowFund } = useContext(FundContext);
  const handleChange = () => setShowFund((prevState) => !prevState);

  return (
    <Box sx={{ m: 1, display: "inline-flex", width: "max" }}>
      <Switch
        checked={showFund}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
        sx={{ m: 1 }}
      />
      <p>終了したファンドも表示</p>
    </Box>
  );
}
