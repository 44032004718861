import { useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { useGet } from "../../hooks/useGet";
import { SnackBarContext } from "../../Context/SnackBarContext";

const columnsMapTypeRawData = [
  { header: "Type", key: "name" },
  { header: "USD", key: "usd" },
  { header: "Ratio", key: "ratio" },
];

const columnsMapCountryRawData = [
  { header: "Country", key: "name" },
  { header: "USD", key: "usd" },
  { header: "Ratio", key: "ratio" },
];

const columnsMapFundRawData = [
  { header: "Fund", key: "name" },
  { header: "USD", key: "usd" },
  { header: "JPY", key: "jpy" },
];

// バリデーションルール
const schema = yup.object({
  ym: yup.string().typeError("入力は必須です").required("入力は必須です"),
});

interface OptionLabels {
  ym: string[];
}

interface Input {
  ym: string;
}

interface BalancesSegmentData {
  name: string;
  id: number;
  usd: string;
  ratio: string;
  message: string;
}

interface YmData {
  ymd: string;
  message: string;
}

interface BalancesSegmentFundOutputData {
  code: string;
  name: string;
  usd: string;
  jpy: string;
}

interface BalancesSegmentAllOutputTotalData {
  perType: BalancesSegmentData[];
  perCountry: BalancesSegmentData[];
  perFund: BalancesSegmentFundOutputData[];
  perFundExcludedIncubation: BalancesSegmentFundOutputData[];
}
export default function ReportSegmentAllTemplate() {
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [reportData, setReportData] =
    useState<BalancesSegmentAllOutputTotalData>();

  const [options, setOptions] = useState<OptionLabels>({} as OptionLabels);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  // 選択肢に出すための値を取得
  const { data: ymdArray } = useFetch<YmData[]>(
    `/balances/export`,
    null,
    setIsSuccess,
    setResMsg
  );

  // 選択肢を生成
  useEffect(() => {
    const ymArray: string[] = [];
    ymdArray.forEach((row: YmData) => {
      ymArray.push(dayjs(row.ymd).format("YYYY/MM"));
    });

    // 重複、空文字を除外して配列を作成

    const suggestingYm: string[] = ymArray.filter((x, i, self) =>
      x !== "" ? self.indexOf(x) === i : ""
    );

    const output: OptionLabels = {
      ym: suggestingYm,
    };
    setOptions(output);
  }, [ymdArray]);

  const { getRequest } = useGet<BalancesSegmentAllOutputTotalData>(
    `/balances/segment_all`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      const param =
        condition.ym === ""
          ? ""
          : `ym=${dayjs(condition.ym).format("YYYY-MM")}`;

      const tmpReportData = await getRequest(param);
      if (!tmpReportData) {
        return;
      }

      setReportData(tmpReportData);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const exportExcel: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      const ym = dayjs(condition.ym).format("YYYY-MM");
      const param = condition.ym === "" ? "" : `ym=${ym}`;

      const tmpReportData = await getRequest(param);
      if (!tmpReportData) {
        return;
      }

      //
      // Typeのワークシートを作成する
      //

      const worksheetOfType = workbook.addWorksheet("TypeData", {});

      // カラムとJSONキーのマッピング
      worksheetOfType.columns = columnsMapTypeRawData;

      tmpReportData.perType.forEach((row: BalancesSegmentData) => {
        worksheetOfType.addRow(row);
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() => worksheetOfType.insertRow(1, []));

      // 基本情報を入力
      worksheetOfType.getCell("A2").value = "Balance Date";
      worksheetOfType.getCell("A3").value = "Fund";

      worksheetOfType.getCell("B2").value = ym;
      worksheetOfType.getCell("B3").value = "All";

      // セルを青色に変更
      const fillStylesBlue: ExcelJS.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "223a70" },
      };

      worksheetOfType.getCell("A6").fill = fillStylesBlue;
      worksheetOfType.getCell("B6").fill = fillStylesBlue;
      worksheetOfType.getCell("C6").fill = fillStylesBlue;

      // 罫線
      const borderStyles: Partial<ExcelJS.Borders> = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheetOfType.getCell("A2").border = borderStyles;
      worksheetOfType.getCell("A3").border = borderStyles;
      worksheetOfType.getCell("B2").border = borderStyles;
      worksheetOfType.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfType.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfType.getColumn(1).width = 26;
      worksheetOfType.getColumn(2).width = 26;
      worksheetOfType.getColumn(3).width = 26;

      //
      // Countryのワークシートを作成する
      //

      const worksheetOfCountry = workbook.addWorksheet("CountryData", {});

      worksheetOfCountry.columns = columnsMapCountryRawData;

      tmpReportData.perCountry.forEach((row: BalancesSegmentData) => {
        worksheetOfCountry.addRow(row);
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() =>
        worksheetOfCountry.insertRow(1, [])
      );

      // 基本情報を入力
      worksheetOfCountry.getCell("A2").value = "Balance Date";
      worksheetOfCountry.getCell("A3").value = "Fund";

      worksheetOfCountry.getCell("B2").value = ym;
      worksheetOfCountry.getCell("B3").value = "All";

      worksheetOfCountry.getCell("A6").fill = fillStylesBlue;
      worksheetOfCountry.getCell("B6").fill = fillStylesBlue;
      worksheetOfCountry.getCell("C6").fill = fillStylesBlue;

      worksheetOfCountry.getCell("A2").border = borderStyles;
      worksheetOfCountry.getCell("A3").border = borderStyles;
      worksheetOfCountry.getCell("B2").border = borderStyles;
      worksheetOfCountry.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfCountry.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfCountry.getColumn(1).width = 26;
      worksheetOfCountry.getColumn(2).width = 26;
      worksheetOfCountry.getColumn(3).width = 26;

      //
      // perFundのワークシートを作成する
      //

      const worksheetOfFund = workbook.addWorksheet("FundData", {});

      worksheetOfFund.columns = columnsMapFundRawData;
      tmpReportData.perFund.forEach((row: BalancesSegmentFundOutputData) => {
        worksheetOfFund.addRow(row);
      });

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() => worksheetOfFund.insertRow(1, []));

      // 基本情報を入力
      worksheetOfFund.getCell("A2").value = "Balance Date";
      worksheetOfFund.getCell("A3").value = "Fund";

      worksheetOfFund.getCell("B2").value = ym;
      worksheetOfFund.getCell("B3").value = "All";

      worksheetOfFund.getCell("A6").fill = fillStylesBlue;
      worksheetOfFund.getCell("B6").fill = fillStylesBlue;
      worksheetOfFund.getCell("C6").fill = fillStylesBlue;

      worksheetOfFund.getCell("A2").border = borderStyles;
      worksheetOfFund.getCell("A3").border = borderStyles;
      worksheetOfFund.getCell("B2").border = borderStyles;
      worksheetOfFund.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfFund.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfFund.getColumn(1).width = 42;
      worksheetOfFund.getColumn(2).width = 26;
      worksheetOfFund.getColumn(3).width = 26;

      //
      // perFundExcludedIncubationのワークシートを作成する
      //

      const worksheetOfFundExcludedIncubation = workbook.addWorksheet(
        "FundExcludedIncubationData",
        {}
      );

      worksheetOfFundExcludedIncubation.columns = columnsMapFundRawData;
      tmpReportData.perFundExcludedIncubation.forEach(
        (row: BalancesSegmentFundOutputData) => {
          worksheetOfFundExcludedIncubation.addRow(row);
        }
      );

      // 表の上に5レコード追加
      Array.from({ length: 5 }).forEach(() =>
        worksheetOfFundExcludedIncubation.insertRow(1, [])
      );

      // 基本情報を入力
      worksheetOfFundExcludedIncubation.getCell("A2").value = "Balance Date";
      worksheetOfFundExcludedIncubation.getCell("A3").value = "Fund";

      worksheetOfFundExcludedIncubation.getCell("B2").value = ym;
      worksheetOfFundExcludedIncubation.getCell("B3").value = "All";

      worksheetOfFundExcludedIncubation.getCell("A6").fill = fillStylesBlue;
      worksheetOfFundExcludedIncubation.getCell("B6").fill = fillStylesBlue;
      worksheetOfFundExcludedIncubation.getCell("C6").fill = fillStylesBlue;

      worksheetOfFundExcludedIncubation.getCell("A2").border = borderStyles;
      worksheetOfFundExcludedIncubation.getCell("A3").border = borderStyles;
      worksheetOfFundExcludedIncubation.getCell("B2").border = borderStyles;
      worksheetOfFundExcludedIncubation.getCell("B3").border = borderStyles;

      // フォントを白色に変更
      worksheetOfFundExcludedIncubation.getRow(6).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetOfFundExcludedIncubation.getColumn(1).width = 42;
      worksheetOfFundExcludedIncubation.getColumn(2).width = 26;
      worksheetOfFundExcludedIncubation.getColumn(3).width = 26;

      //
      // Excelファイルのダウンロード
      //
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const conditionYm = dayjs(condition.ym).format("YYYYMM");

      link.download = `ReportSegment_All_${conditionYm}.xlsx`;

      link.click();

      setReportData(tmpReportData);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const handleClickCopyType = async (
    row: BalancesSegmentData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.ratio}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Type: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyCountry = async (
    row: BalancesSegmentData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.ratio}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Country: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyFund = async (
    row: BalancesSegmentFundOutputData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.jpy}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Fund: ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyFundExcludedIncubation = async (
    row: BalancesSegmentFundOutputData
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${row.name}\t${row.usd}\t${row.jpy}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Fund(ExcludedIncubation): ${row.name} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  return (
    <Box style={{ height: 400, width: "90vw" }} sx={{ mx: "auto" }}>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Typography sx={{ m: 1 }} variant="h5">
        Report Segment All
      </Typography>
      <form>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item m={2} xs={2}>
            <Controller
              name="ym"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={options.ym || []}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Balance Date"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={Boolean(errors.ym)}
                      helperText={errors.ym?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(onSubmit)}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(exportExcel)}
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {reportData ? (
        <>
          <Grid container my={2} spacing={4} justifyContent="space-around">
            <Grid item xs={4}>
              <Paper>
                <Table sx={{ width: "95%", m: "auto" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Type</TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        USD
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        Ratio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.perType.map((row) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() => handleClickCopyType(row)}
                        key={row.name}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.usd))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            style: "percent",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }).format(Number(row.ratio))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                <Table sx={{ width: "95%", m: "auto" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Country
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        USD
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        Ratio
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.perCountry.map((row) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() => handleClickCopyCountry(row)}
                        key={row.name}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.usd))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            style: "percent",
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                          }).format(Number(row.ratio))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <Grid container my={2} spacing={4} justifyContent="space-around">
            <Grid item xs={6}>
              <Paper>
                <Table sx={{ width: "95%", m: "auto" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>Fund</TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        USD
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        JPY
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.perFund.map((row) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() => handleClickCopyFund(row)}
                        key={row.name}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.usd))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(Number(row.jpy))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper>
                <Table sx={{ width: "95%", m: "auto" }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: "bold" }}>
                        Fund (Excluded Incubation)
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        USD
                      </TableCell>
                      <TableCell align="right" style={{ fontWeight: "bold" }}>
                        JPY
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reportData.perFundExcludedIncubation.map((row) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() =>
                          handleClickCopyFundExcludedIncubation(row)
                        }
                        key={row.name}
                      >
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.usd))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }).format(Number(row.jpy))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Box>
  );
}
