import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext } from "react";
import { InvestorData } from "../../templates/Investor";
import { SnackBarContext } from "../../../Context/SnackBarContext";
import { useGet } from "../../../hooks/useGet";

const columnsMapKeys = [
  { header: "Investor ID", key: "id" },
  { header: "Discretionary Code", key: "discretionaryCode" },
  { header: "Investor Name", key: "name" },
  { header: "Country", key: "countryName" },
  { header: "Type", key: "typeName" },
  { header: "Remarks", key: "remarks" },
];

interface Props {
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ExportInvestorsExcelButton(props: Props) {
  const { setResMsg, setIsSuccess } = props;
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  const { getRequest } = useGet<InvestorData[]>(
    `/investors/export`,
    setIsSuccess,
    setResMsg
  );

  const clickButtonAsync = async () => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("InvestorList", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      // データ挿入
      const rows = await getRequest("");

      if (!rows) {
        throw new Error("データが見つかりませんでした");
      }

      rows.forEach((row: InvestorData) => worksheet.addRow(row));

      // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
      // セルを青色に変更
      const fillStylesBlue: ExcelJS.Fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "223a70" },
      };

      worksheet.getCell("A1").fill = fillStylesBlue;
      worksheet.getCell("B1").fill = fillStylesBlue;
      worksheet.getCell("C1").fill = fillStylesBlue;
      worksheet.getCell("D1").fill = fillStylesBlue;
      worksheet.getCell("E1").fill = fillStylesBlue;
      worksheet.getCell("F1").fill = fillStylesBlue;

      // フォントを白色に変更
      worksheet.getRow(1).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheet.getColumn(1).width = 10;
      worksheet.getColumn(2).width = 20;
      worksheet.getColumn(3).width = 20;
      worksheet.getColumn(4).width = 10;
      worksheet.getColumn(5).width = 10;
      worksheet.getColumn(6).width = 30;

      // エクセルファイルを生成
      const uint8Array = await workbook.xlsx.writeBuffer(); // xlsxの場合
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `InvestorList_${now}.xlsx`;
      link.click();
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<DownloadIcon />}
      sx={{ mr: 5 }}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={() => clickButtonAsync()}
    >
      Excel
    </Button>
  );
}
