import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction } from "react";
import { useGet } from "../../../hooks/useGet";

interface FundsExportData {
  id: number;
  code: string;
  name: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  custodian1Name: string;
  custodian2Name: string;
  custodian3Name: string;
  administrator: string;
  custodian1AccountNumberMemo: string;
  custodian2AccountNumberMemo: string;
  custodian3AccountNumberMemo: string;
  administratorAccountNumber: string;
  accountingYear: string;
  currency: string;
  domicile: string;
  type: string;
  trustee: string;
  auditor: string;
  strategy: string;
  investmentManager: string;
  investmentAdvisor: string;
  managers: string;
  rateCompany: string;
  remarks: string;
  empty: string;
  message: string;
}

const dateFmt = "yyyy/mm/dd";

const columnsMapKeys = [
  { header: "Fund Code", key: "code" },
  { header: "Fund Name", key: "name" },
  { header: "Contract Date", key: "contractDate", style: { numFmt: dateFmt } },
  { header: "Starting Date", key: "startingDate", style: { numFmt: dateFmt } },
  { header: "Closing Date", key: "closingDate", style: { numFmt: dateFmt } },
  { header: "PB/Custodian1", key: "custodian1Name" },
  { header: "PB/Custodian2", key: "custodian2Name" },
  { header: "PB/Custodian3", key: "custodian3Name" },
  { header: "Administrator", key: "administrator" },
  {
    header: "PB/Custodian1 Account Number",
    key: "custodian1AccountNumberMemo",
  },
  {
    header: "PB/Custodian2 Account Number",
    key: "custodian2AccountNumberMemo",
  },
  {
    header: "PB/Custodian3 Account Number",
    key: "custodian3AccountNumberMemo",
  },
  { header: "Administrator Account Number", key: "administratorAccountNumber" },
  { header: "Fund Accounting Year", key: "accountingYear" },
  { header: "Fund Base Currency", key: "currency" },
  { header: "Fund Domicile", key: "domicile" },
  { header: "Fund Type", key: "type" },
  { header: "Fund Trustee", key: "trustee" },
  { header: "Fund Auditor", key: "auditor" },
  { header: "Fund Strategy", key: "strategy" },
  { header: "Investment Manager", key: "investmentManager" },
  { header: "Investment Advisor", key: "investmentAdvisor" },
  { header: "Fund Managers", key: "managers" },
  { header: "FX", key: "rateCompany" },
  { header: "Remarks", key: "remarks" },
];

interface Errs {
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ExportFundsExcelButton(props: Errs) {
  const { setResMsg, setIsSuccess } = props;

  const { getRequest } = useGet<FundsExportData[]>(
    `/funds/export`,
    setIsSuccess,
    setResMsg
  );

  const clickButtonAsync = async () => {
    // ワークブックを作成する
    const workbook = new ExcelJS.Workbook();

    // ワークシートを作成する
    const worksheet = workbook.addWorksheet("FundList", {});

    // カラムとJSONキーのマッピング
    worksheet.columns = columnsMapKeys;

    const rows = await getRequest("");
    if (!rows) {
      throw new Error("データが見つかりませんでした");
    }

    // データ挿入
    rows.forEach((row: FundsExportData) => worksheet.addRow(row));

    // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
    // セルを青色に変更
    worksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "223a70" },
    };

    // フォントを白色に変更
    worksheet.getRow(1).font = {
      color: { argb: "FFFFFF" },
    };

    // エクセルファイルを生成
    const uint8Array = await workbook.xlsx.writeBuffer(); // xlsxの場合
    const blob = new Blob([uint8Array], { type: "application/octet-binary" });

    // ダウンロード
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    const now = dayjs().format("YYYYMMDD");

    link.download = `FundList_${now}.xlsx`;
    link.click();
  };

  return (
    <Button
      variant="text"
      color="primary"
      startIcon={<DownloadIcon />}
      sx={{ mr: 5 }}
      /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
      onClick={() => clickButtonAsync()}
    >
      Excel
    </Button>
  );
}
