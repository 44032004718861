import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import ExportFundsExcelButton from "./ExportFundsExcelButton";
import { AddFundDialog } from "../../organisms/fund/AddFundDialog";
import { FundData } from "../../templates/Fund";
import { NameTableData } from "../../templates/TrusteeBank";

export interface FundProps {
  funds: FundData[];
  custodians: NameTableData[];
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function FundBar(props: FundProps) {
  const { funds, custodians, render, setRender, setResMsg, setIsSuccess } =
    props;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: -1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        Fund
      </Typography>

      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportFundsExcelButton
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <AddFundDialog
          funds={funds}
          custodians={custodians}
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
      </Box>
    </Box>
  );
}
