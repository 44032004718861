import { Box, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import ImportBalanceDialog from "./ImportBalanceDialog";
import ExportBalancesExcelAndCsvButton from "./ExportBalancesExcelAndCsvButton";
import { ImportedBalanceHistoryData } from "../../templates/Balance";

interface Props {
  balanceHistories: ImportedBalanceHistoryData[];
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function BalanceBar(props: Props) {
  const { balanceHistories, render, setRender, setResMsg, setIsSuccess } =
    props;
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: 1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        Balance
      </Typography>
      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportBalancesExcelAndCsvButton
          rows={balanceHistories}
          exportType="csv"
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <ExportBalancesExcelAndCsvButton
          rows={balanceHistories}
          exportType="excel"
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <ImportBalanceDialog
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
      </Box>
    </Box>
  );
}
