import { Dispatch, SetStateAction, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { FundData } from "../../templates/Fund";
import { ForeignDiscretionaryClientData } from "../../templates/ForeignDiscretionaryClient";
import { FdcDataForApiRequest } from "./AddForeignDiscretionaryClientDialog";
import { usePut } from "../../../hooks/usePut";
import DeleteCommonModalInEdit from "../../molecules/common/DeleteCommonModalInEdit";

const theme = createTheme({
  palette: {
    text: {
      disabled: "#616161",
    },
  },
});

const schema = yup.object({
  fund: yup.object().typeError("入力は必須です"), // ✕ボタンを押すとnullになってタイプエラーが出ることを利用して✕ボタン対策
  currentAddress: yup.string().max(1000, "1000文字以内で入力してください"),
  historicalAddressMemo: yup
    .string()
    .max(1000, "1000文字以内で入力してください"),
  addressee1: yup
    .string()
    .matches(
      /^[A-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(400, "400文字以内で入力してください"),
  addressee2: yup
    .string()
    .matches(
      /^[A-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(400, "400文字以内で入力してください"),
  addressee3: yup
    .string()
    .matches(
      /^[A-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    )
    .max(400, "400文字以内で入力してください"),
  mail1: yup.string().email("メールアドレスの形式で入力してください"),
  mail2: yup.string().email("メールアドレスの形式で入力してください"),
  mail3: yup.string().email("メールアドレスの形式で入力してください"),
});

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  rowInfo: ForeignDiscretionaryClientData;
  funds: FundData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function ForeignDiscretionaryClientInfoDialog(props: Props) {
  const {
    open,
    setOpen,
    render,
    setRender,
    setResMsg,
    rowInfo,
    funds,
    setIsSuccess,
  } = props;

  const [checked, setChecked] = useState(false);
  const [fund, setFund] = useState<FundData | null>(null);
  const [currentAddress, setCurrentAddress] = useState<string | null>(null);
  const [historicalAddressMemo, setHistoricalAddress] = useState<string | null>(
    null
  );
  const [addressee1, setAddressee1] = useState<string | null>(null);
  const [mail1, setMail1] = useState<string | null>(null);
  const [addressee2, setAddressee2] = useState<string | null>(null);
  const [mail2, setMail2] = useState<string | null>(null);
  const [addressee3, setAddressee3] = useState<string | null>(null);
  const [mail3, setMail3] = useState<string | null>(null);
  const [remarks, setRemarks] = useState<string | null>(null);

  const resetValues = () => {
    setFund(null);
    setCurrentAddress(null);
    setHistoricalAddress(null);
    setAddressee1(null);
    setMail1(null);
    setAddressee2(null);
    setMail2(null);
    setAddressee3(null);
    setMail3(null);
    setRemarks(null);
  };

  const handleClose = () => {
    reset();
    resetValues();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FdcDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { putRequest } = usePut(
    "/foreign_discretionary_client",
    rowInfo.id,
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    handleClose
  );

  const onSubmit: SubmitHandler<FdcDataForApiRequest> = async (
    data: FdcDataForApiRequest
  ) => {
    const body = JSON.stringify({
      fundId: data.fund?.id || rowInfo.fund.id,
      currentAddress: data.currentAddress,
      historicalAddressMemo: data.historicalAddressMemo,
      addressee1: data.addressee1,
      mail1: data.mail1,
      addressee2: data.addressee2,
      mail2: data.mail2,
      addressee3: data.addressee3,
      mail3: data.mail3,
      remarks: data.remarks,
    });
    await putRequest(body);
  };

  // 各項目に変更があるか否かを示すフラグを定義
  const isChangedFund = fund !== null && fund.id !== rowInfo.fund.id;

  const isChangedCurrentAddress =
    currentAddress !== null && currentAddress !== rowInfo.currentAddress;

  const isChangedHistoricalAddress =
    historicalAddressMemo !== null &&
    historicalAddressMemo !== rowInfo.historicalAddressMemo;

  const isChangedAddressee1 =
    addressee1 !== null && addressee1 !== rowInfo.addressee1;

  const isChangedMail1 = mail1 !== null && mail1 !== rowInfo.mail1;

  const isChangedAddressee2 =
    addressee2 !== null && addressee2 !== rowInfo.addressee2;
  const isChangedMail2 = mail2 !== null && mail2 !== rowInfo.mail2;

  const isChangedAddressee3 =
    addressee3 !== null && addressee3 !== rowInfo.addressee3;
  const isChangedMail3 = mail3 !== null && mail3 !== rowInfo.mail3;

  const isChangedRemarks = remarks !== null && remarks !== rowInfo.remarks;

  // 全項目通して変更があるか否かのフラグを定義
  const isChanged =
    isChangedFund ||
    isChangedCurrentAddress ||
    isChangedHistoricalAddress ||
    isChangedAddressee1 ||
    isChangedMail1 ||
    isChangedAddressee2 ||
    isChangedMail2 ||
    isChangedAddressee3 ||
    isChangedMail3 ||
    isChangedRemarks;

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={handleClose}>
      <Stack
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid
          container
          mb={1}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <DialogTitle>詳細情報</DialogTitle>
          <DeleteCommonModalInEdit
            endpoint="/foreign_discretionary_client"
            id={rowInfo.id}
            render={render}
            setRender={setRender}
            setOpenEditDialog={setOpen}
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
          />
        </Grid>
        <DialogContent>
          <Grid container mb={4} justifyContent="flex-start">
            <ThemeProvider theme={theme}>
              <Grid item mx={1} xs={2}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={funds}
                      getOptionLabel={(f) => `${f.code}`}
                      defaultValue={rowInfo.fund}
                      onChange={(_, data) => {
                        field.onChange(data);
                        setFund(data);
                        setChecked(false);
                      }}
                      renderInput={(params) => (
                        <TextField
                          label="Code"
                          variant="standard"
                          error={Boolean(errors.fund)}
                          helperText={errors.fund?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} xs={6}>
                <Stack>
                  <TextField
                    // 編集不可
                    disabled
                    label="顧客名"
                    variant="standard"
                    defaultValue={rowInfo.fund.name}
                  />
                </Stack>
              </Grid>
            </ThemeProvider>
          </Grid>
          <Grid container mb={4} justifyContent="flex-start">
            <ThemeProvider theme={theme}>
              <Grid item mx={1} sx={{ width: 300 }}>
                <Stack>
                  <TextField
                    // 編集不可
                    disabled
                    label="契約開始日"
                    variant="standard"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={
                      rowInfo.fund.contractDate
                        ? dayjs(rowInfo.fund.contractDate).format("YYYY-MM-DD")
                        : ""
                    }
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} sx={{ width: 300 }}>
                <Stack>
                  <TextField
                    // 編集不可
                    disabled
                    label="当初資産組入日"
                    variant="standard"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={
                      rowInfo.fund.startingDate
                        ? dayjs(rowInfo.fund.startingDate).format("YYYY-MM-DD")
                        : ""
                    }
                  />
                </Stack>
              </Grid>
              <Grid item mx={1} sx={{ width: 300 }}>
                <Stack>
                  <TextField
                    // 編集不可
                    disabled
                    label="契約終了日"
                    variant="standard"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    defaultValue={
                      rowInfo.fund.closingDate
                        ? dayjs(rowInfo.fund.closingDate).format("YYYY-MM-DD")
                        : ""
                    }
                  />
                </Stack>
              </Grid>
            </ThemeProvider>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="currentAddress"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="住所"
                      variant="standard"
                      defaultValue={rowInfo.currentAddress}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setCurrentAddress(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.currentAddress)}
                      helperText={errors.currentAddress?.message}
                      multiline
                      rows={3}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={6}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="historicalAddressMemo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="standard-multiline-static"
                      label="住所 (履歴)"
                      variant="standard"
                      defaultValue={rowInfo.historicalAddressMemo}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setHistoricalAddress(e.target.value);
                        setChecked(false);
                      }}
                      error={Boolean(errors.historicalAddressMemo)}
                      helperText={errors.historicalAddressMemo?.message}
                      multiline
                      rows={3}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名1"
                      variant="standard"
                      error={Boolean(errors.addressee1)}
                      helperText={errors.addressee1?.message}
                      defaultValue={rowInfo.addressee1}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee1(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス1"
                      variant="standard"
                      error={Boolean(errors.mail1)}
                      helperText={errors.mail1?.message}
                      defaultValue={rowInfo.mail1}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail1(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名2"
                      variant="standard"
                      error={Boolean(errors.addressee2)}
                      helperText={errors.addressee2?.message}
                      defaultValue={rowInfo.addressee2}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee2(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス2"
                      variant="standard"
                      error={Boolean(errors.mail2)}
                      helperText={errors.mail2?.message}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail2(e.target.value);
                        setChecked(false);
                      }}
                      defaultValue={rowInfo.mail2}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} justifyContent="flex-start">
            <Grid item mx={1} xs={2}>
              <Stack>
                <Controller
                  name="addressee3"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="宛名3"
                      variant="standard"
                      error={Boolean(errors.addressee3)}
                      helperText={errors.addressee3?.message}
                      defaultValue={rowInfo.addressee3}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setAddressee3(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item mx={1} xs={6}>
              <Stack>
                <Controller
                  name="mail3"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="メールアドレス3"
                      variant="standard"
                      error={Boolean(errors.mail3)}
                      helperText={errors.mail3?.message}
                      defaultValue={rowInfo.mail3}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setMail3(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container my={4} direction="row" justifyContent="flex-start">
            <Grid item mx={1} xs={12}>
              <Box sx={{ "& .MuiTextField-root": { width: "100%" } }}>
                <Controller
                  name="remarks"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      id="standard-multiline-static"
                      label="備考"
                      multiline
                      rows={4}
                      variant="standard"
                      defaultValue={rowInfo.remarks}
                      onChange={(e) => {
                        field.onChange(e.target.value);
                        setRemarks(e.target.value);
                        setChecked(false);
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
          {isChanged ? (
            <>
              <Grid container my={2} justifyContent="center">
                <Paper sx={{ minWidth: 500, maxWidth: 700 }}>
                  <Table sx={{ width: "90%", mx: "auto" }} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>項目</TableCell>
                        <TableCell>変更前</TableCell>
                        <TableCell>変更後</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isChangedFund ? (
                        <TableRow>
                          <TableCell>Code</TableCell>
                          <TableCell>{rowInfo.fund.code}</TableCell>
                          <TableCell>{fund?.code}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedCurrentAddress ? (
                        <TableRow>
                          <TableCell>住所</TableCell>
                          <TableCell>{rowInfo.currentAddress}</TableCell>
                          <TableCell>{currentAddress}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedHistoricalAddress ? (
                        <TableRow>
                          <TableCell>住所 (履歴)</TableCell>
                          <TableCell>{rowInfo.historicalAddressMemo}</TableCell>
                          <TableCell>{historicalAddressMemo}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee1 ? (
                        <TableRow>
                          <TableCell>宛名1</TableCell>
                          <TableCell>{rowInfo.addressee1}</TableCell>
                          <TableCell>{addressee1}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail1 ? (
                        <TableRow>
                          <TableCell>メールアドレス1</TableCell>
                          <TableCell>{rowInfo.mail1}</TableCell>
                          <TableCell>{mail1}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee2 ? (
                        <TableRow>
                          <TableCell>宛名2</TableCell>
                          <TableCell>{rowInfo.addressee2}</TableCell>
                          <TableCell>{addressee2}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail2 ? (
                        <TableRow>
                          <TableCell>メールアドレス2</TableCell>
                          <TableCell>{rowInfo.mail2}</TableCell>
                          <TableCell>{mail2}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedAddressee3 ? (
                        <TableRow>
                          <TableCell>宛名3</TableCell>
                          <TableCell>{rowInfo.addressee3}</TableCell>
                          <TableCell>{addressee3}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedMail3 ? (
                        <TableRow>
                          <TableCell>メールアドレス3</TableCell>
                          <TableCell>{rowInfo.mail3}</TableCell>
                          <TableCell>{mail3}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                      {isChangedRemarks ? (
                        <TableRow>
                          <TableCell>備考</TableCell>
                          <TableCell>{rowInfo.remarks}</TableCell>
                          <TableCell>{remarks}</TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
              <Grid container my={2} justifyContent="center">
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
                <Typography sx={{ m: 1 }} variant="body1">
                  変更内容を確認
                </Typography>
              </Grid>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <Grid container justifyContent="space-around" my={1}>
          <DialogActions>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button type="submit" disabled={!checked || !isChanged}>
              更新
            </Button>
          </DialogActions>
        </Grid>
      </Stack>
    </Dialog>
  );
}
