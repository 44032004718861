import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { DialogActions, Grid, Stack } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import Buffer from "exceljs/index";
import { useGet } from "../../../hooks/useGet";
import { SnackBarContext } from "../../../Context/SnackBarContext";
import { FundData } from "../../templates/Fund";
import { TransactionData } from "../../templates/Transaction";
import { InvestorData } from "../../templates/Investor";

interface Investor {
  id: number;
  code: string;
}

interface Input {
  fund: FundData;
  targetDate: string;
  fromYmd: string;
  toYmd: string;
  investor: Investor;
}

interface TransactionExportData {
  investorId: number;
  tradeDate: string;
  fundCode: string;
  accountNumber: number;
  classSeries: string;
  currency: string;
  quantity: string;
  navShare: string;
  nav: string;
  action: string;
  typeName: string;
  countryName: string;
  investorName: string;
  discretionaryCode: string;
  discretionaryContract: number;
  discretionalContractDate: string;
  fundRedSubApplicationDate: string;
}

const columnsMapKeys = [
  { header: "Investor ID", key: "investorId" },
  { header: "Trade Date", key: "tradeDate" },
  { header: "Fund Code", key: "fundCode" },
  { header: "Account ID", key: "accountNumber" },
  { header: "Class/Series", key: "classSeries" },
  { header: "Currency", key: "currency" },
  { header: "Quantity", key: "quantity" },
  { header: "NAV/Share", key: "navShare" },
  { header: "NAV", key: "nav" },
  { header: "Action", key: "action" },
  { header: "Type", key: "typeName" },
  { header: "Country", key: "countryName" },
  { header: "Investor Name", key: "investorName" },
  { header: "Discretionary Code", key: "discretionaryCode" },
  { header: "Discretional Contract", key: "discretionaryContract" },
  { header: "Discretional Contract Date", key: "discretionalContractDate" },
  { header: "Fund Red/Sub Application Date", key: "fundRedSubApplicationDate" },
];

interface Props {
  transactions: TransactionData[];
  exportType: string;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  funds: FundData[];
  investors: InvestorData[];
}

export default function ExportTransactionExcelAndCsvButton(props: Props) {
  const {
    transactions,
    exportType,
    setIsSuccess,
    setResMsg,
    funds,
    investors,
  } = props;
  const [open, setOpen] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);

  if (exportType !== "csv" && exportType !== "xlsx") {
    throw new Error("exportType is incorrect!");
  }

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const { handleSubmit, reset, control } = useForm<Input>();

  const targetDateArray: string[] = [];

  transactions.forEach((transaction: TransactionData) =>
    targetDateArray.push(transaction.tradeDate)
  );

  const { getRequest } = useGet<TransactionExportData[]>(
    `/transactions/export`,
    setIsSuccess,
    setResMsg
  );

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      // ワークシートを作成する
      const worksheet = workbook.addWorksheet("TransactionList", {});

      // カラムとJSONキーのマッピング
      worksheet.columns = columnsMapKeys;

      let param = "";
      if (condition.investor) {
        param += `investorId=${condition.investor.id}`;
      }
      if (condition.fund && condition.fund.id !== 0) {
        param += `&fundId=${condition.fund.id}`;
      }
      if (condition.fromYmd) {
        param += `&fromYmd=${dayjs(condition.fromYmd).format("YYYY-MM-DD")}`;
      }
      if (condition.toYmd) {
        param += `&toYmd=${dayjs(condition.toYmd).format("YYYY-MM-DD")}`;
      }

      const transactionDataList = await getRequest(param);

      if (!transactionDataList) {
        return;
      }

      transactionDataList.forEach((row: TransactionExportData) =>
        worksheet.addRow(row)
      );

      // CSVファイルまたはExcelファイルを生成
      let uint8Array: Buffer;

      if (exportType === "csv") {
        uint8Array = await workbook.csv.writeBuffer();
      } else {
        // ヘッダ部分の編集 (exceljsではセルの範囲指定での編集ができない)
        // セルを青色に変更
        const fillStylesBlue: ExcelJS.Fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };

        worksheet.getCell("A1").fill = fillStylesBlue;
        worksheet.getCell("B1").fill = fillStylesBlue;
        worksheet.getCell("C1").fill = fillStylesBlue;
        worksheet.getCell("D1").fill = fillStylesBlue;
        worksheet.getCell("E1").fill = fillStylesBlue;
        worksheet.getCell("F1").fill = fillStylesBlue;
        worksheet.getCell("G1").fill = fillStylesBlue;
        worksheet.getCell("H1").fill = fillStylesBlue;
        worksheet.getCell("I1").fill = fillStylesBlue;
        worksheet.getCell("J1").fill = fillStylesBlue;
        worksheet.getCell("K1").fill = fillStylesBlue;
        worksheet.getCell("L1").fill = fillStylesBlue;
        worksheet.getCell("M1").fill = fillStylesBlue;
        worksheet.getCell("N1").fill = fillStylesBlue;
        worksheet.getCell("O1").fill = fillStylesBlue;
        worksheet.getCell("P1").fill = fillStylesBlue;
        worksheet.getCell("Q1").fill = fillStylesBlue;
        worksheet.getCell("R1").fill = fillStylesBlue;
        worksheet.getCell("S1").fill = fillStylesBlue;
        worksheet.getCell("T1").fill = fillStylesBlue;

        // フォントを白色に変更
        worksheet.getRow(1).font = {
          color: { argb: "FFFFFF" },
        };

        // 幅調整
        worksheet.getColumn(1).width = 10; // A列
        worksheet.getColumn(2).width = 15; // B列
        worksheet.getColumn(3).width = 10; // C列
        worksheet.getColumn(4).width = 15; // D列
        worksheet.getColumn(5).width = 15; // E列
        worksheet.getColumn(6).width = 10; // F列
        worksheet.getColumn(7).width = 10; // G列
        worksheet.getColumn(8).width = 15; // H列
        worksheet.getColumn(9).width = 15; // I列
        worksheet.getColumn(10).width = 10; // J列
        worksheet.getColumn(11).width = 10; // K列
        worksheet.getColumn(12).width = 15; // L列
        worksheet.getColumn(13).width = 15; // M列
        worksheet.getColumn(14).width = 10; // N列
        worksheet.getColumn(15).width = 10; // O列
        worksheet.getColumn(16).width = 15; // P列
        worksheet.getColumn(17).width = 15; // Q列
        worksheet.getColumn(18).width = 15; // R列
        worksheet.getColumn(19).width = 15; // S列
        worksheet.getColumn(20).width = 15; // T列

        uint8Array = await workbook.xlsx.writeBuffer();
      }

      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      // ダウンロード
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `Transaction_${now}.${exportType}`;
      link.click();

      setOpen(false);
    } catch (e) {
      // 意図しないエラーが発生した場合
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  return (
    <div>
      <Button
        variant="text"
        color="primary"
        startIcon={<DownloadIcon />}
        sx={{ mr: 5 }}
        onClick={handleClickOpen}
      >
        {exportType === "csv" ? "CSV" : "Excel"}
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle sx={{ pb: 0 }}>絞り込み条件</DialogTitle>
          <DialogContent>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start"
            >
              <Grid item xs={6}>
                <Controller
                  name="investor"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={investors}
                      getOptionLabel={(investor) =>
                        `${investor.id}: ${investor.name}`
                      }
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Investor ID"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={funds}
                      getOptionLabel={(fund) => fund.code}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="flex-start"
              my={1}
            >
              <Grid item xs={6}>
                <Stack>
                  <Controller
                    name="fromYmd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="From"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack>
                  <Controller
                    name="toYmd"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="To"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: "center" }}>
            <Button onClick={handleClose}>キャンセル</Button>
            <Button
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              type="submit"
            >
              エクスポート
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
