import { Box, Button, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ExportRatesExcelButton from "./ExportRatesExcelButton";
import { usePost } from "../../../hooks/usePost";

interface Props {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export function RateBar(props: Props) {
  const { render, setRender, setResMsg, setIsSuccess } = props;

  const { postRequest } = usePost(
    "/rate",
    setResMsg,
    setRender,
    render,
    setIsSuccess
  );

  const addTargetMonth = async () => {
    await postRequest(null);
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
        m: 1,
        mb: 5,
      }}
    >
      <Typography sx={{ m: 1 }} variant="h5">
        FX Rate
      </Typography>
      <Box
        sx={{
          alignItems: "right",
          display: "flex",
        }}
      >
        <ExportRatesExcelButton
          setResMsg={setResMsg}
          setIsSuccess={setIsSuccess}
        />
        <Button
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={addTargetMonth}
          startIcon={<AddCircleOutlineOutlinedIcon />}
        >
          対象月を追加
        </Button>
      </Box>
    </Box>
  );
}
