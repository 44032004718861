import { createContext, useState } from "react";

type Props = {
  children: React.ReactNode;
};

export const FundContext = createContext(
  {} as {
    showFund: boolean;
    setShowFund: React.Dispatch<React.SetStateAction<boolean>>;
  }
);

// eslint-disable-next-line react/function-component-definition,react/prop-types
export const FundProvider: React.FC<Props> = ({ children }) => {
  const [showFund, setShowFund] = useState(false);
  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FundContext.Provider value={{ showFund, setShowFund }}>
      {children}
    </FundContext.Provider>
  );
};
