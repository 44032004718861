import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import ExcelJS from "exceljs";
import dayjs from "dayjs";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { useGet } from "../../hooks/useGet";
import { SnackBarContext } from "../../Context/SnackBarContext";
import { PerformancesData } from "../molecules/performance/ExportPerformancesExcelAndCsvButton";

const columnsMapKeysReport = [
  { header: "From", key: "fromYmd" },
  { header: "To", key: "toYmd" },
  { header: "Period", key: "period" },
  { header: "GAV", key: "gav" },
  { header: "NAV", key: "nav" },
  { header: "Monthly Rtn GAV", key: "monthlyRtnGav" },
  { header: "Monthly Rtn NAV", key: "monthlyRtnNav" },
];

const columnsMapKeysRawData = [
  { header: "Balance Date", key: "ymd" },
  { header: "Class/Series", key: "classSeries" },
  { header: "Currency", key: "currency" },
  { header: "GAV", key: "gav" },
  { header: "NAV", key: "nav" },
  { header: "Monthly Rtn GAV", key: "monthlyRtnGav" },
  { header: "Monthly Rtn NAV", key: "monthlyRtnNav" },
];

// バリデーションルール
const schema = yup.object({
  fundCodeAndClassSeries: yup
    .string()
    .typeError("入力は必須です")
    .required("入力は必須です"),
  ym: yup.string().typeError("入力は必須です").required("入力は必須です"),
});

interface OptionLabels {
  fundCodeAndClassSeries: string[];
  ym: string[];
}

interface Input {
  fundCodeAndClassSeries: string;
  ym: string;
}

interface PerformancesReportDataRow {
  period: string;
  fromYmd: string;
  toYmd: string;
  gav: string;
  nav: string;
  monthlyRtnGav: string;
  monthlyRtnNav: string;
}

interface PerformancesReportData {
  targetMonth: PerformancesReportDataRow;
  threeMonths1: PerformancesReportDataRow;
  threeMonths2: PerformancesReportDataRow;
  threeMonths3: PerformancesReportDataRow;
  threeMonths4: PerformancesReportDataRow;
  threeYears: PerformancesReportDataRow;
  fiveYears: PerformancesReportDataRow;
  fy1: PerformancesReportDataRow;
  fy2: PerformancesReportDataRow;
  fy3: PerformancesReportDataRow;
  fy4: PerformancesReportDataRow;
  fy5: PerformancesReportDataRow;
  cy1: PerformancesReportDataRow;
  cy2: PerformancesReportDataRow;
  cy3: PerformancesReportDataRow;
  cy4: PerformancesReportDataRow;
  cy5: PerformancesReportDataRow;
}

export default function ReportPerformanceTemplate() {
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const { showSnackBars, setShowSnackBars } = useContext(SnackBarContext);
  const [performanceReportData, setPerformanceReportData] =
    useState<PerformancesReportDataRow[]>();
  const [performanceData, setPerformanceData] = useState<PerformancesData[]>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [options, setOptions] = useState<OptionLabels>({
    fundCodeAndClassSeries: [],
    ym: [],
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Input>({
    resolver: yupResolver(schema),
  });

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 選択肢取得
  const { data: performances } = useFetch<PerformancesData[]>(
    `/performances/export`,
    null,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    const fundCodeAndClassSeriesArray: string[] = [];
    const ymArray: string[] = [];

    performances.forEach((row: PerformancesData) => {
      fundCodeAndClassSeriesArray.push(`${row.fundCode}, ${row.classSeries}`);
      ymArray.push(dayjs(row.ymd).format("YYYY/MM"));
    });

    // 重複、空文字を除外して配列を作成
    const suggestingFundCodeAndClassSeries: string[] =
      fundCodeAndClassSeriesArray.filter(
        (x: string, i: number, self: string[]) =>
          x !== "" ? self.indexOf(x) === i : ""
      );

    const suggestingYm: string[] = ymArray.filter(
      (x: string, i: number, self: string[]) =>
        x !== "" ? self.indexOf(x) === i : ""
    );

    const output: OptionLabels = {
      fundCodeAndClassSeries: suggestingFundCodeAndClassSeries,
      ym: suggestingYm,
    };

    setOptions(output);
  }, [performances]);

  const { getRequest: getPerformancesData } = useGet<PerformancesData[]>(
    `/performances/export`,
    setIsSuccess,
    setResMsg
  );

  const { getRequest: getPerformancesReportData } =
    useGet<PerformancesReportData>(
      `/performances/report_performance`,
      setIsSuccess,
      setResMsg
    );

  const getParam = (condition: Input): string[] => {
    const fundCodeAndClassSeriesArray =
      condition.fundCodeAndClassSeries?.split(", "); // フォームから渡される値は必ずカンマ+スペースで区切られている
    if (!fundCodeAndClassSeriesArray) {
      throw new Error(
        "Class & Seriesのデータに不整合が発生しています。システムの管理者に連絡してください。"
      );
    }

    const fundCode = fundCodeAndClassSeriesArray[0];
    const classSeries = fundCodeAndClassSeriesArray[1];

    let reportDataParam = "";
    if (fundCode !== "") {
      reportDataParam += `fundCode=${fundCode}`;
    }
    if (classSeries !== "") {
      reportDataParam += `&classSeries=${classSeries.replaceAll("/", "%2F")}`;
    }
    if (condition.ym) {
      reportDataParam += `&toYmd=${dayjs(condition.ym)
        .endOf("month")
        .format("YYYY-MM-DD")}`;
    }

    return [reportDataParam, fundCode, classSeries];
  };

  const onSubmit: SubmitHandler<Input> = async (condition) => {
    try {
      const [param] = getParam(condition);

      const tmpReportData = await getPerformancesReportData(param);
      if (!tmpReportData) {
        return;
      }

      const tmpPerformancesData = await getPerformancesData(param);
      if (!tmpPerformancesData) {
        return;
      }

      // addRowの仕様に寄せるため、順番を指定して一度配列化
      const tmpReportDataArray = [
        tmpReportData.targetMonth,
        tmpReportData.threeMonths1,
        tmpReportData.threeMonths2,
        tmpReportData.threeMonths3,
        tmpReportData.threeMonths4,
        tmpReportData.threeYears,
        tmpReportData.fiveYears,
        tmpReportData.fy1,
        tmpReportData.fy2,
        tmpReportData.fy3,
        tmpReportData.fy4,
        tmpReportData.fy5,
        tmpReportData.cy1,
        tmpReportData.cy2,
        tmpReportData.cy3,
        tmpReportData.cy4,
        tmpReportData.cy5,
      ];

      setPerformanceReportData(tmpReportDataArray);
      setPerformanceData(tmpPerformancesData);
      setPage(0);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const exportExcel: SubmitHandler<Input> = async (condition) => {
    try {
      // ワークブックを作成する
      const workbook = new ExcelJS.Workbook();

      //
      // 1つ目のワークシートを作成する
      //

      const worksheetReport = workbook.addWorksheet("ReportPerformance", {});

      // カラムとJSONキーのマッピング
      worksheetReport.columns = columnsMapKeysReport;

      const fundCodeAndClassSeriesArray =
        condition.fundCodeAndClassSeries?.split(", "); // フォームから渡される値は必ずカンマ+スペースで区切られている
      if (!fundCodeAndClassSeriesArray) {
        throw new Error(
          "Class & Seriesのデータに不整合が発生しています。システムの管理者に連絡してください。"
        );
      }

      const [param, fundCode, classSeries] = getParam(condition);

      const tmpReportData = await getPerformancesReportData(param);
      if (!tmpReportData) {
        return;
      }

      // addRowの仕様に寄せるため、順番を指定して一度配列化
      const tmpReportDataArray = [
        tmpReportData.targetMonth,
        tmpReportData.threeMonths1,
        tmpReportData.threeMonths2,
        tmpReportData.threeMonths3,
        tmpReportData.threeMonths4,
        tmpReportData.threeYears,
        tmpReportData.fiveYears,
        tmpReportData.fy1,
        tmpReportData.fy2,
        tmpReportData.fy3,
        tmpReportData.fy4,
        tmpReportData.fy5,
        tmpReportData.cy1,
        tmpReportData.cy2,
        tmpReportData.cy3,
        tmpReportData.cy4,
        tmpReportData.cy5,
      ];

      tmpReportDataArray.forEach((row: PerformancesReportDataRow) => {
        worksheetReport.addRow({
          fromYmd: dayjs(row.fromYmd).format("YYYY/MM/DD"),
          toYmd: dayjs(row.toYmd).format("YYYY/MM/DD"),
          period: row.period,
          gav: row.gav,
          nav: row.nav,
          monthlyRtnGav: row.monthlyRtnGav,
          monthlyRtnNav: row.monthlyRtnNav,
        });
      });

      // 表の上に7レコード追加
      Array.from({ length: 7 }).forEach(() => worksheetReport.insertRow(1, []));

      // 基本情報を入力
      worksheetReport.getCell("A2").value = "Fund";
      worksheetReport.getCell("A3").value = "Class / Series";
      worksheetReport.getCell("A4").value = "Target Month End";
      worksheetReport.getCell("A5").value = "FY Start Date";
      worksheetReport.getCell("A6").value = "CY Start Date";

      worksheetReport.getCell("B2").value = fundCode;
      worksheetReport.getCell("B3").value = classSeries;

      worksheetReport.getCell("B4").value = dayjs(
        tmpReportData.targetMonth.toYmd
      ).format("YYYY/MM/DD");

      worksheetReport.getCell("B5").value = dayjs(tmpReportData.fy1.fromYmd)
        .startOf("month")
        .format("YYYY/MM/DD");

      worksheetReport.getCell("B6").value = dayjs(tmpReportData.cy1.fromYmd)
        .startOf("month")
        .format("YYYY/MM/DD");

      // ヘッダを青色に変更
      Array.from({ length: 8 }).forEach((_, i) => {
        worksheetReport.getCell(8, i + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };
      });

      // 罫線
      const borderStyles: Partial<ExcelJS.Borders> = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      worksheetReport.getCell("A2").border = borderStyles;
      worksheetReport.getCell("A3").border = borderStyles;
      worksheetReport.getCell("A4").border = borderStyles;
      worksheetReport.getCell("A5").border = borderStyles;
      worksheetReport.getCell("A6").border = borderStyles;
      worksheetReport.getCell("B2").border = borderStyles;
      worksheetReport.getCell("B3").border = borderStyles;
      worksheetReport.getCell("B4").border = borderStyles;
      worksheetReport.getCell("B5").border = borderStyles;
      worksheetReport.getCell("B6").border = borderStyles;

      // フォントを白色に変更
      worksheetReport.getRow(8).font = {
        color: { argb: "FFFFFF" },
      };

      // 幅調整
      worksheetReport.getColumn(1).width = 15;
      worksheetReport.getColumn(2).width = 15;
      worksheetReport.getColumn(3).width = 15;
      worksheetReport.getColumn(4).width = 15;
      worksheetReport.getColumn(5).width = 15;
      worksheetReport.getColumn(6).width = 15;
      worksheetReport.getColumn(7).width = 15;

      //
      // 2つ目のワークシートを作成する
      //

      const worksheetRawData = workbook.addWorksheet("PerformanceRawData", {});

      worksheetRawData.columns = columnsMapKeysRawData;

      const tmpPerformancesData = await getPerformancesData(param);
      if (!tmpPerformancesData) {
        return;
      }

      tmpPerformancesData.forEach((row: PerformancesData) => {
        worksheetRawData.addRow({
          ymd: dayjs(row.ymd).format("YYYY/MM/DD"),
          classSeries: row.classSeries,
          currency: row.currency,
          gav: row.gav,
          nav: row.nav,
          monthlyRtnGav: row.monthlyRtnGav,
          monthlyRtnNav: row.monthlyRtnNav,
        });
      });

      // フォントを白色に変更
      worksheetRawData.getRow(1).font = {
        color: { argb: "FFFFFF" },
      };

      // ヘッダを青色に変更
      Array.from({ length: 7 }).forEach((_, i) => {
        worksheetRawData.getCell(1, i + 1).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "223a70" },
        };
      });

      // 幅調整
      worksheetRawData.getColumn(1).width = 15;
      worksheetRawData.getColumn(2).width = 15;
      worksheetRawData.getColumn(3).width = 15;
      worksheetRawData.getColumn(4).width = 15;
      worksheetRawData.getColumn(5).width = 15;
      worksheetRawData.getColumn(6).width = 15;
      worksheetRawData.getColumn(7).width = 15;

      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      const now = dayjs().format("YYYYMMDD");

      link.download = `ReportPerformance_${now}.xlsx`;

      link.click();

      setPerformanceReportData(tmpReportDataArray);
      setPerformanceData(tmpPerformancesData);
      setPage(0);
    } catch (e) {
      setIsSuccess(false);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setResMsg(`${e}`);
      setShowSnackBars(!showSnackBars);
    }
  };

  const handleClickCopyReport = async (
    row: PerformancesReportDataRow
  ): Promise<void> => {
    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${
      row.fromYmd ? dayjs(row.fromYmd).format("YYYY/MM/DD") : ""
    }\t${row.toYmd ? dayjs(row.toYmd).format("YYYY/MM/DD") : ""}\t${
      row.period
    }\t${row.gav}\t${row.nav}\t${row.monthlyRtnGav}\t${row.monthlyRtnNav}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Report: ${row.period} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  const handleClickCopyPerformanceData = async (
    row: PerformancesData
  ): Promise<void> => {
    const ymd = row.ymd ? dayjs(row.ymd).format("YYYY/MM/DD") : "";

    // タブで区切りつつ文字列連結することで、Excelファイルにペーストした際にうまく貼り付けられる
    const text = `${ymd}\t${row.classSeries}\t${row.currency}\t${row.gav}\t${row.nav}\t${row.monthlyRtnGav}\t${row.monthlyRtnNav}`;

    await navigator.clipboard.writeText(text);
    setResMsg(`Performance Data: ${ymd} をコピーしました`);
    setIsSuccess(true);
    setShowSnackBars(!showSnackBars);
  };

  return (
    <Box style={{ height: 1000, width: "90vw" }} sx={{ mx: "auto" }}>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Typography sx={{ m: 1 }} variant="h5">
        Report Performance
      </Typography>
      <form>
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item m={2} xs={3}>
            <Controller
              name="fundCodeAndClassSeries"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={options.fundCodeAndClassSeries}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Fund Code, Class & Series"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={Boolean(errors.fundCodeAndClassSeries)}
                      helperText={errors.fundCodeAndClassSeries?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item m={2} xs={2}>
            <Controller
              name="ym"
              control={control}
              render={({ field }) => (
                <Autocomplete
                  // 選択肢入力(C)
                  options={options.ym}
                  onChange={(_, data) => field.onChange(data)}
                  renderInput={(params) => (
                    <TextField
                      label="Balance Date"
                      variant="standard"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      error={Boolean(errors.ym)}
                      helperText={errors.ym?.message}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(onSubmit)}
            >
              <SearchIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid m={2}>
            <IconButton
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleSubmit(exportExcel)}
            >
              <DownloadIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        </Grid>
      </form>
      {performanceReportData && performanceData ? (
        <>
          <Grid my={3}>
            <Paper sx={{ width: "80%", m: "auto" }}>
              <Table sx={{ width: "95%", m: "auto" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>From</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>To</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Period</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>GAV</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>NAV</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Monthly Rtn GAV
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Monthly Rtn NAV
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performanceReportData.map((row) => (
                    <TableRow
                      hover
                      // eslint-disable-next-line @typescript-eslint/no-misused-promises
                      onClick={() => handleClickCopyReport(row)}
                      key={row.period}
                    >
                      <TableCell align="right">
                        {dayjs(row.fromYmd).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell align="right">
                        {dayjs(row.toYmd).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell align="right">{row.period}</TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          minimumFractionDigits: 6,
                          maximumFractionDigits: 6,
                        }).format(Number(row.gav))}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          minimumFractionDigits: 6,
                          maximumFractionDigits: 6,
                        }).format(Number(row.nav))}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          style: "percent",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Number(row.monthlyRtnGav))}
                      </TableCell>
                      <TableCell align="right">
                        {Intl.NumberFormat("ja", {
                          style: "percent",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(Number(row.monthlyRtnNav))}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid my={3}>
            <Paper sx={{ width: "80%", m: "auto" }}>
              <TablePagination
                rowsPerPageOptions={[20, 50, 100]}
                component="div"
                count={performanceData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <Table sx={{ width: "95%", m: "auto" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Balance Date
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Class/Series
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Currency
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>GAV</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>NAV</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Monthly Rtn GAV
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Monthly Rtn NAV
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {performanceData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => (
                      <TableRow
                        hover
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() => handleClickCopyPerformanceData(row)}
                        key={row.ymd}
                      >
                        <TableCell align="right">
                          {dayjs(row.ymd).format("YYYY/MM/DD")}
                        </TableCell>
                        <TableCell align="right">{row.classSeries}</TableCell>
                        <TableCell align="right">{row.currency}</TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6,
                          }).format(Number(row.gav))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            minimumFractionDigits: 6,
                            maximumFractionDigits: 6,
                          }).format(Number(row.nav))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            style: "percent",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.monthlyRtnGav))}
                        </TableCell>
                        <TableCell align="right">
                          {Intl.NumberFormat("ja", {
                            style: "percent",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }).format(Number(row.monthlyRtnNav))}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </>
      ) : (
        ""
      )}
    </Box>
  );
}
