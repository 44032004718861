import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { useFetch } from "../../hooks/useFetch";
import { FundData } from "./Fund";
import AddNetAssetValueDialog from "../organisms/netAssetValue/AddNetAssetValueDialog";
import EditNetAssetValueInfoDialog from "../organisms/netAssetValue/EditNetAssetValueInfoDialog";
import ExportNetAssetValueExcelAndCsvButton from "../organisms/netAssetValue/ExportNetAssetValueExcelAndCsvButton";
import SearchNetAssetValueRows from "../organisms/netAssetValue/SearchNetAssetValueRows";

export interface NavData {
  id: number;
  fund: FundData;
  targetDate: string;
  currency: string;
  nav: string;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "tradeDate",
    label: "Balance Date",
  },
  {
    id: "fund",
    label: "Fund Code",
  },
  {
    id: "currency",
    label: "Currency",
  },
  {
    id: "nav",
    label: "NAV",
  },
];

export default function NetAssetValueTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState<NavData>({} as NavData);
  const [rows, setRows] = useState<NavData[]>([]);

  // NAVデータ一覧を取得
  const { data: navDataList } = useFetch<NavData[]>(
    `/net_asset_values`,
    render,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    setRows(navDataList);
  }, [navDataList]);

  // fund一覧を取得
  const { data: funds } = useFetch<FundData[]>(
    `/funds`,
    null,
    setIsSuccess,
    setResMsg
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const showEditInfoDialog = (row: NavData) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          m: 1,
          mb: 5,
        }}
      >
        <Typography sx={{ m: 1 }} variant="h5">
          NAV管理
        </Typography>
        <Box
          sx={{
            alignItems: "right",
            display: "flex",
          }}
        >
          <ExportNetAssetValueExcelAndCsvButton
            rows={rows}
            exportType="csv"
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
            funds={funds}
          />
          <ExportNetAssetValueExcelAndCsvButton
            rows={rows}
            exportType="xlsx"
            setResMsg={setResMsg}
            setIsSuccess={setIsSuccess}
            funds={funds}
          />
          <AddNetAssetValueDialog
            render={render}
            setRender={setRender}
            setResMsg={setResMsg}
            funds={funds}
            setIsSuccess={setIsSuccess}
          />
        </Box>
      </Box>
      <Grid mb={4} mx={2}>
        <SearchNetAssetValueRows
          setRows={setRows}
          setResMsg={setResMsg}
          funds={funds}
          setIsSuccess={setIsSuccess}
        />
      </Grid>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={headCell.id}
                    align="center"
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={() => showEditInfoDialog(row)}
                    key={row.id}
                  >
                    <TableCell align="center">
                      {row.targetDate
                        ? dayjs(row.targetDate).format("YYYY/MM")
                        : "-"}
                    </TableCell>
                    <TableCell align="center">{row.fund.code} </TableCell>
                    <TableCell align="center">{row.currency} </TableCell>
                    <TableCell align="center">
                      {row.nav === ""
                        ? "-"
                        : Number(row.nav).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <EditNetAssetValueInfoDialog
        open={open}
        setOpen={setOpen}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        funds={funds}
        rowInfo={rowInfo}
        setIsSuccess={setIsSuccess}
      />
    </Box>
  );
}
