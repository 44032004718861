import { Route, Routes } from "react-router-dom";
import BalanceTable from "./components/templates/Balance";
import CountryTable from "./components/templates/Country";
import CustodianTable from "./components/templates/Custodian";
import FundTable from "./components/templates/Fund";
import { FundProvider } from "./Context/FundContext";
import RateTable from "./components/templates/Rate";
import PerformanceTable from "./components/templates/Performance";
import { SnackBarProvider } from "./Context/SnackBarContext";
import InvestorTable from "./components/templates/Investor";
import TransactionTable from "./components/templates/Transaction";
import DomesticDiscretionaryClientTable from "./components/templates/DomesticDiscretionaryClient";
import ForeignDiscretionaryClientTable from "./components/templates/ForeignDiscretionaryClient";
import InvestorTypeTable from "./components/templates/InvestorType";
import TrusteeBankTable from "./components/templates/TrusteeBank";
import ReTrusteeBankTable from "./components/templates/ReTrusteeBank";
import NetAssetValueTable from "./components/templates/NetAssetValue";
import TopTemplate from "./components/templates/Top";
import ReportInvestorTemplate from "./components/templates/ReportInvestor";
import ReportPerformanceTemplate from "./components/templates/ReportPerformance";
import ReportSegmentOneTemplate from "./components/templates/ReportSegmentOne";
import ReportSegmentAllTemplate from "./components/templates/ReportSegmentAll";

function MainRoutes() {
  return (
    <FundProvider>
      <SnackBarProvider>
        <Routes>
          <Route path="/" element={<TopTemplate />} />
          <Route path="/report_investor" element={<ReportInvestorTemplate />} />
          <Route
            path="/report_performance"
            element={<ReportPerformanceTemplate />}
          />
          <Route
            path="/report_segment_one"
            element={<ReportSegmentOneTemplate />}
          />
          <Route
            path="/report_segment_all"
            element={<ReportSegmentAllTemplate />}
          />
          <Route path="/balance" element={<BalanceTable />} />
          <Route path="/country" element={<CountryTable />} />
          <Route path="/investor_type" element={<InvestorTypeTable />} />
          <Route path="/custodian" element={<CustodianTable />} />
          <Route path="/fund" element={<FundTable />} />
          <Route path="/rate" element={<RateTable />} />
          <Route path="/performance" element={<PerformanceTable />} />
          <Route path="/investor" element={<InvestorTable />} />
          <Route path="/transaction" element={<TransactionTable />} />
          <Route path="/trustee_bank" element={<TrusteeBankTable />} />
          <Route path="/re_trustee_bank" element={<ReTrusteeBankTable />} />
          <Route path="/net_asset_values" element={<NetAssetValueTable />} />
          <Route
            path="/domestic_discretionary_client"
            element={<DomesticDiscretionaryClientTable />}
          />
          <Route
            path="/foreign_discretionary_client"
            element={<ForeignDiscretionaryClientTable />}
          />
        </Routes>
      </SnackBarProvider>
    </FundProvider>
  );
}

export default MainRoutes;
