import { Dispatch, SetStateAction, useState } from "react";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TransactionDataForApiRequest } from "../../molecules/transaction/EditTransactionDialog";
import { InvestorData } from "../../templates/Investor";
import { usePost } from "../../../hooks/usePost";
import { FundData } from "../../templates/Fund";

const schema = yup.object({
  investor: yup.object().typeError("入力は必須です").required("入力は必須です"),
  accountNumber: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    ).max(100, "100文字以内で入力してください"),
  classSeries: yup
    .string()
    .matches(
      /^[a-zA-Z0-9 -/:-@[-~]*$/,
      "半角英数字または半角記号で入力してください"
    ).max(100, "100文字以内で入力してください"),
  quantity: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
  navShare: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
  nav: yup
    .string()
    .matches(
      /(^[-]*[0-9]{1,10}(\.[0-9]{1,12})?$|^$)/,
      "半角数字 (整数10桁、小数12桁以内)で入力してください"
    ),
});

export interface TransactionProps {
  render: boolean;
  setRender: Dispatch<SetStateAction<boolean>>;
  setResMsg: Dispatch<SetStateAction<string>>;
  investors: InvestorData[];
  funds: FundData[];
  setIsSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function AddTransactionDialog(props: TransactionProps) {
  const { render, setRender, setResMsg, investors, funds, setIsSuccess } =
    props;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<TransactionDataForApiRequest>({
    resolver: yupResolver(schema),
  });

  const { postRequest } = usePost(
    "/transaction",
    setResMsg,
    setRender,
    render,
    setIsSuccess,
    setOpen
  );

  const onSubmit: SubmitHandler<TransactionDataForApiRequest> = async (
    data: TransactionDataForApiRequest
  ) => {
    const body = JSON.stringify({
      investorId: data.investor?.id,
      tradeDate: data.tradeDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      fundId: data.fund?.id || 0,
      accountNumber: data.accountNumber || "",
      classSeries: data.classSeries || "",
      currency: data.currency || "",
      quantity: data.quantity || null, // 空文字やundefinedの場合NullとしてJSONに乗せたい
      navShare: data.navShare || null, // 空文字やundefinedの場合NullとしてJSONに乗せたい
      nav: data.nav || null, // 空文字やundefinedの場合NullとしてJSONに乗せたい
      action: data.action || "",
      discretionalContractDate: data.discretionalContractDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
      fundRedSubApplicationDate: data.fundRedSubApplicationDate || null, // 一度フィールドに入力して取り消した状態でsubmitされると、ここで空文字になってしまうのでnullに戻してリクエストしたい
    });

    await postRequest(body);
  };

  return (
    <div>
      <Button
        variant="text"
        onClick={handleClickOpen}
        startIcon={<AddCircleOutlineOutlinedIcon />}
      >
        新規登録
      </Button>
      <Dialog maxWidth="xl" fullWidth open={open} onClose={handleClose}>
        <Stack
          component="form"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle>新規登録</DialogTitle>
          <DialogContent>
            <Grid container my={4} justifyContent="space-between">
              <Grid item xs={3}>
                <Controller
                  name="investor"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={investors}
                      getOptionLabel={(investor: InvestorData) =>
                        `id: ${investor.id}, name: ${investor.name}, code: ${investor.discretionaryCode}`
                      }
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Investor ID, Name, Discretionary Code"
                          variant="standard"
                          error={Boolean(errors.investor)}
                          helperText={errors.investor?.message}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item mx={1} sx={{ width: 300 }}>
                <Stack>
                  <Controller
                    name="tradeDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        // サジェストなし自由入力(A)
                        label="Trade Date"
                        variant="standard"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => field.onChange(e.target.value)}
                      />
                    )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="fund"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(C)
                      options={funds}
                      getOptionLabel={(fund) => fund.code}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Fund Code"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3}>
                <Controller
                  name="accountNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="Account ID"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"accountNumber" in errors}
                      helperText={errors.accountNumber?.message}
                      autoComplete="off"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="space-between">
              <Grid item xs={3}>
                <Controller
                  name="classSeries"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="Class/Series"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"classSeries" in errors}
                      helperText={errors.classSeries?.message}
                      autoComplete="off"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="currency"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={["JPY", "USD"]}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Currency"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="quantity"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="Quantity"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"quantity" in errors}
                      helperText={errors.quantity?.message}
                      autoComplete="off"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="navShare"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="NAV/Share"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"navShare" in errors}
                      helperText={errors.navShare?.message}
                      autoComplete="off"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container my={4} justifyContent="space-between">
              <Grid item xs={2}>
                <Controller
                  name="nav"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{ mb: 1 }}
                      label="NAV"
                      type="text"
                      fullWidth
                      variant="standard"
                      error={"nav" in errors}
                      helperText={errors.nav?.message}
                      autoComplete="off"
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="action"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      // 選択肢入力(D)
                      options={["B", "S", "T+", "T-", "D"]}
                      onChange={(_, data) => field.onChange(data)}
                      renderInput={(params) => (
                        <TextField
                          label="Action"
                          variant="standard"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Stack>
                  <Controller
                      name="discretionalContractDate"
                      control={control}
                      render={({field}) => (
                          <TextField
                              label="Discretionary Contract Date"
                              variant="standard"
                              type="date"
                              error={Boolean(errors.discretionalContractDate)}
                              helperText={errors.discretionalContractDate?.message}
                              InputLabelProps={{shrink: true}}
                              onChange={(e) => field.onChange(e.target.value)}
                          />
                      )}
                  />
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="fundRedSubApplicationDate"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Fund Red/Sub Application Date"
                      variant="standard"
                      type="date"
                      error={Boolean(errors.fundRedSubApplicationDate)}
                      helperText={errors.fundRedSubApplicationDate?.message}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <Grid container justifyContent="space-around" my={1}>
            <DialogActions>
              <Button onClick={handleClose}>キャンセル</Button>
              <Button type="submit">登録</Button>
            </DialogActions>
          </Grid>
        </Stack>
      </Dialog>
    </div>
  );
}
