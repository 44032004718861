import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { DomesticDiscretionaryClientData } from "../../templates/DomesticDiscretionaryClient";

interface Props {
  searched: string;
  initRows: DomesticDiscretionaryClientData[];
  setRows: Dispatch<SetStateAction<DomesticDiscretionaryClientData[]>>;
  setSearched: Dispatch<SetStateAction<string>>;
}

export default function SearchDomesticDiscretionaryClientRows(props: Props) {
  const { searched, initRows, setRows, setSearched } = props;

  const requestSearch = (searchedVal: string) => {
    const filteredRows = initRows.filter(
      (row: DomesticDiscretionaryClientData) =>
        // kanaキーまたはnameキー部分一致で検索
        row.kana.toLowerCase().includes(searchedVal.toLowerCase()) ||
        row.name.toLowerCase().includes(searchedVal.toLowerCase())
    );
    setRows(filteredRows);
  };

  const changeSearchedHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setSearched(event.target.value);
    requestSearch(event.target.value);
  };

  return (
    <div>
      <TextField
        variant="standard"
        label="顧客名"
        value={searched}
        onChange={(event) => changeSearchedHandler(event)}
        autoComplete="off"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}
