import { ChangeEvent, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import { PerformanceBar } from "../molecules/performance/PerformanceBar";
import { useFetch } from "../../hooks/useFetch";
import AlertSnackbars from "../molecules/common/AlertSnackBar";

export interface ImportedPerformanceHistoryData {
  fundCode: string;
  ymd: string;
  updatedAt: string;
  message: string;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "fundCode",
    label: "Fund Code",
  },
  {
    id: "ymd",
    label: "Balance Date",
  },
  {
    id: "updatedAt",
    label: "Updated At",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            style={{ fontWeight: "bold" }}
            key={headCell.id}
            align="center"
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function PerformanceTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [render, setRender] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // performance履歴一覧を取得
  const { data: performanceHistories } = useFetch<
    ImportedPerformanceHistoryData[]
  >(`/performances/imported_history`, render, setIsSuccess, setResMsg);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty performances .
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - performanceHistories.length)
      : 0;

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <PerformanceBar
        performanceHistories={performanceHistories}
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        setIsSuccess={setIsSuccess}
      />
      <Box textAlign="right" mt={1} mb={5}>
        <h6>
          ※ファンドと年月毎にインポートしてください。やり直す場合は、ファンド年月の全件をインポートし直してください。
        </h6>
      </Box>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Typography sx={{ m: 1 }} variant="h6">
          インポート履歴一覧
        </Typography>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <EnhancedTableHead />
            <TableBody>
              {performanceHistories
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((performanceHistory) => (
                  <TableRow
                    tabIndex={-1}
                    key={performanceHistory.fundCode + performanceHistory.ymd}
                  >
                    <TableCell align="center" sx={{ width: "30%" }}>
                      {performanceHistory.fundCode}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "30%" }}>
                      {performanceHistory.ymd}
                    </TableCell>
                    <TableCell align="center" sx={{ width: "40%" }}>
                      {performanceHistory.updatedAt}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={performanceHistories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
