import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import AlertSnackbars from "../molecules/common/AlertSnackBar";
import { DomesticDiscretionaryClientBar } from "../molecules/domesticDiscretionaryClient/DomesticDiscretionaryClientBar";
import SearchDomesticDiscretionaryClientRows from "../molecules/domesticDiscretionaryClient/SearchDomesticDiscretionaryClientRows";
import { InvestorData } from "./Investor";
import DomesticDiscretionaryClientInfoDialog from "../organisms/domesticDiscretionaryClient/DomesticDiscretionaryClientInfoDialog";
import { useFetch } from "../../hooks/useFetch";
import { NameTableData } from "./TrusteeBank";

export interface DomesticDiscretionaryClientData {
  id: number;
  code: string;
  mainFund: Label;
  investor: InvestorData;
  name: string;
  kana: string;
  contractDate: string;
  startingDate: string;
  closingDate: string;
  zipCode: string;
  currentAddress: string;
  historicalAddressMemo: string;
  phoneNumber: string;
  addressee1: string;
  mail1: string;
  addressee2: string;
  mail2: string;
  addressee3: string;
  mail3: string;
  addressee4: string;
  mail4: string;
  subscriptionRedemptionMemo: string;
  trusteeBank: NameTableData;
  reTrusteeBank: NameTableData;
  accountNumber: string;
  remarks: string;
  isActive: boolean;
}

interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "code",
    label: "Code",
  },
  {
    id: "name",
    label: "顧客名",
  },
  {
    id: "investorName",
    label: "Investor Name",
  },
  {
    id: "contractDate",
    label: "契約終了日",
  },
];

export interface Label {
  id: string;
  label: string;
  message: string;
}

export default function DomesticDiscretionaryClientTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [rows, setRows] = useState<DomesticDiscretionaryClientData[]>([]);
  const [render, setRender] = useState(false);
  const [open, setOpen] = useState(false);
  const [resMsg, setResMsg] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  // rowsは動的検索で更新されるが、対してinitRowsは検索では更新されないため、検索バーが空になったときに使用される
  const [initRows, setInitRows] = useState<DomesticDiscretionaryClientData[]>(
    []
  );
  const [searched, setSearched] = useState("");

  const emptyRow: DomesticDiscretionaryClientData = {
    id: 0,
    mainFund: {} as Label,
    code: "",
    investor: {} as InvestorData,
    name: "",
    kana: "",
    contractDate: "",
    startingDate: "",
    closingDate: "",
    zipCode: "",
    currentAddress: "",
    historicalAddressMemo: "",
    phoneNumber: "",
    addressee1: "",
    mail1: "",
    addressee2: "",
    mail2: "",
    addressee3: "",
    mail3: "",
    addressee4: "",
    mail4: "",
    subscriptionRedemptionMemo: "",
    trusteeBank: {} as NameTableData,
    reTrusteeBank: {} as NameTableData,
    accountNumber: "",
    remarks: "",
    isActive: false,
  };

  const [rowInfo, setRowInfo] =
    useState<DomesticDiscretionaryClientData>(emptyRow);

  // 投一(国内)一覧を取得
  const { data: ddcList } = useFetch<DomesticDiscretionaryClientData[]>(
    `/domestic_discretionary_clients`,
    render,
    setIsSuccess,
    setResMsg
  );

  useEffect(() => {
    setInitRows(ddcList);
    setRows(ddcList);
  }, [ddcList]);

  // 選択肢に使用するものを取得
  const { data: investors } = useFetch<InvestorData[]>(
    `/investors`,
    null,
    setIsSuccess,
    setResMsg
  );

  const { data: trusteeBanks } = useFetch<NameTableData[]>(
    `/trustee_banks?doesIncludeUnselected=true`,
    null,
    setIsSuccess,
    setResMsg
  );

  const { data: reTrusteeBanks } = useFetch<NameTableData[]>(
    `/re_trustee_banks?doesIncludeUnselected=true`,
    null,
    setIsSuccess,
    setResMsg
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const showDomesticDiscretionaryClientInfoDialog = (
    row: DomesticDiscretionaryClientData
  ) => {
    setOpen((prevState) => !prevState);
    setRowInfo(row);
  };

  return (
    <Box sx={{ width: "90%", mx: "auto" }}>
      <DomesticDiscretionaryClientBar
        render={render}
        setRender={setRender}
        setResMsg={setResMsg}
        investors={investors}
        trusteeBanks={trusteeBanks}
        reTrusteeBanks={reTrusteeBanks}
        setIsSuccess={setIsSuccess}
      />
      <Grid mb={4} mx={2}>
        <SearchDomesticDiscretionaryClientRows
          initRows={initRows}
          searched={searched}
          setRows={setRows}
          setSearched={setSearched}
        />
      </Grid>
      <AlertSnackbars resMsg={resMsg} isSuccess={isSuccess} />
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 500 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <TableCell
                    style={{ fontWeight: "bold" }}
                    key={headCell.id}
                    align="center"
                  >
                    {headCell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    tabIndex={-1}
                    onClick={() =>
                      showDomesticDiscretionaryClientInfoDialog(row)
                    }
                    key={row.id}
                  >
                    <TableCell align="center">{row.code} </TableCell>
                    <TableCell align="center">{row.name} </TableCell>
                    <TableCell align="center">{row.investor.name} </TableCell>
                    <TableCell align="center">
                      {row.closingDate
                        ? dayjs(row.closingDate).format("YYYY/MM/DD")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[50, 100, 200]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <DomesticDiscretionaryClientInfoDialog
          open={open}
          setOpen={setOpen}
          render={render}
          setRender={setRender}
          setResMsg={setResMsg}
          rowInfo={rowInfo}
          investors={investors}
          trusteeBanks={trusteeBanks}
          reTrusteeBanks={reTrusteeBanks}
          setIsSuccess={setIsSuccess}
        />
      </Paper>
    </Box>
  );
}
